import React, { useEffect, useState, useCallback } from "react";

import { TextField, Chip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Controller } from "react-hook-form";

import { useFetch } from "hooks";

const SelectField = ({
  label,
  url,
  multiple,
  getOptionLabel,
  renderOption,
  getQuery,
  options: defaultOptions,
  control,
  name,
  rules,
  handleData,
  onChange: onFieldChange,
  readOnly,
  inputProps,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(defaultOptions || []);
  const [selected, setSelected] = useState([]);

  const { get, loading } = useFetch(url);

  const fetchData = useCallback(
    (inputValue, selectedQuery) => {
      if (loading) {
        return;
      }

      get({ query: getQuery ? getQuery(inputValue, selectedQuery) : {} })
        .then(({ data }) => {
          if (data?.success) {
            const _data = [
              ...new Set(
                [...selected, ...data.data.map(handleData)].map((item) =>
                  JSON.stringify(item)
                )
              ),
            ].map((item) => JSON.parse(item));
            setOptions(_data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [loading, selected]
  );

  useEffect(() => {
    if (url) {
      fetchData(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (url && control._formValues[name] && options.length === 0) {
      fetchData(null, control._formValues[name]);
    }
    if (!multiple) {
      const selected = options.find(
        (item) => item.value === control._formValues[name]
      );
      if (selected) {
        setInputValue(selected.label);
      }
    }
  }, []);

  useEffect(() => {
    setOptions(defaultOptions || []);
  }, [defaultOptions]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, value = multiple ? [] : "", ref },
        fieldState: { error },
      }) => {
        if (multiple) {
          value = value
            .map((v) =>
              typeof v === "string" ? options.find((opt) => opt.value === v) : v
            )
            .filter((v) => v);
        } else {
          if (typeof value === "string") {
            value = options.find((opt) => opt.value === value) || "";
          }
        }
        return (
          <Autocomplete
            id={name}
            multiple={!!multiple}
            getOptionLabel={(opt) => opt.label || ""}
            loading={loading}
            disableClearable={readOnly}
            onOpen={() => {
              if (url && options.length === 0) {
                fetchData();
              }
            }}
            options={options.filter((item) => {
              if (multiple) {
                return !(value || []).some(
                  (v) => JSON.stringify(v) == JSON.stringify(item)
                );
              } else {
                return true;
              }
            })}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={
                    getOptionLabel ? getOptionLabel(option) : option.label || ""
                  }
                  {...getTagProps({ index })}
                />
              ))
            }
            value={value === "" ? null : value}
            inputValue={inputValue}
            onChange={(event, newValue) => {
              if (readOnly) {
                return;
              }
              if (multiple) {
                onChange((newValue || []).map((item) => item.value));
                setSelected(newValue);
              } else {
                onChange(newValue?.value || newValue);
              }
              onFieldChange && onFieldChange(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              if (event) {
                setInputValue(newInputValue);
              }
            }}
            getOptionSelected={(option, value) =>
              [value?.value, value].includes(option.value)
            }
            renderInput={(params) => {
              if (!multiple && value?.label && !params?.inputProps?.value) {
                params.inputProps.value = value.label;
              }
              return (
                <TextField
                  {...params}
                  inputRef={ref}
                  label={label}
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                  {...inputProps}
                />
              );
            }}
            renderOption={
              renderOption ||
              ((option, props) => {
                return (
                  <div>
                    <span>{option.label}</span>
                  </div>
                );
              })
            }
            {...rest}
          />
        );
      }}
    />
  );
};

export default SelectField;
