import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { TextField, SelectField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const schema = yup.object({
  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  address: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    ),
  description: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  chips: {
    padding: "1rem",
  },
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, authUser, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(schema) });
  const {
    post: addDispensary,
    put: updateDispensary,
    loading,
  } = useFetch(endpoints.dispensaries + `/${edit?.id || ""}`);

  useEffect(() => {
    reset({
      ...edit,
      hospital_id:
        authUser.user_type === "hospital"
          ? authUser.id
          : edit?.hospital?.id || "",
    });
  }, [edit]);
  return (
    <Dialog open={open}>
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage
            id="editDispensary"
            defaultMessage="Edit Dispensary"
          />
        ) : (
          <FormattedMessage
            id="addDispensary"
            defaultMessage="Add Dispensary"
          />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            (edit?.id ? updateDispensary : addDispensary)({
              ...values,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            {authUser.user_type === "admin" && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage id="hospital" defaultMessage="Hospital" />
                  }
                  name="hospital_id"
                  control={control}
                  url={endpoints.hospitals}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("name")}
                label={<FormattedMessage id="name" defaultMessage="Name" />}
                error={errors.name}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("address")}
                label={
                  <FormattedMessage id="address" defaultMessage="Address" />
                }
                error={errors.address}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("description")}
                label={
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                }
                error={errors.description}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
