import React from "react";

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Controller } from "react-hook-form";

const SelectField = ({
  label,
  multiple,
  options,
  control,
  name,
  rules,
  onChange: onFieldChange,
  readOnly,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, value = multiple ? [] : "", ref },
        fieldState: { error },
      }) => {
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
              aria-label={name}
              name={name}
              value={value}
              onChange={onChange}
            >
              {options.map((item) => (
                <FormControlLabel
                  key={item.value}
                  disabled={item.disabled}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      }}
    />
  );
};

export default SelectField;
