import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { TextField, SelectField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const schema = yup.object({
  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  doctor_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  user_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  chips: {
    padding: "1rem",
  },
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, authUser, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: useYup(schema),
  });
  const {
    post: addPrescription,
    put: updatePrescription,
    loading,
  } = useFetch(endpoints.casefiles + `/${edit?.id || ""}`);

  const hospital_id = watch("hospital_id");

  useEffect(() => {
    reset({
      ...edit,
      user_id: edit?.user?.id || "",
      doctor_id: edit?.doctor?.id || "",
      hospital_id:
        authUser.user_type === "hospital"
          ? authUser.id
          : edit?.hospital?.id || "",
      title: edit?.title || "",
      description: edit?.description || "",
      medical_history: edit?.medical_history || "",
      status: edit?.status || "open",
    });
  }, [edit]);

  useEffect(() => {
    setValue("user_id", "");
    setValue("doctor_id", "");
  }, [hospital_id]);
  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage
            id="editPrescription"
            defaultMessage="Edit Prescription"
          />
        ) : (
          <FormattedMessage
            id="addPrescription"
            defaultMessage="Add Prescription"
          />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            (edit?.id ? updatePrescription : addPrescription)({
              ...values,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            {authUser.user_type === "admin" && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage id="hospital" defaultMessage="Hospital" />
                  }
                  name="hospital_id"
                  control={control}
                  url={endpoints.hospitals}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            {hospital_id && (
              <>
                <Grid item sm={6}>
                  <SelectField
                    label={
                      <FormattedMessage id="patient" defaultMessage="Patient" />
                    }
                    name="user_id"
                    control={control}
                    url={endpoints.users}
                    getQuery={(inputValue, selected) => ({
                      ...(inputValue && { name: inputValue }),
                      ...(selected && { id: selected }),
                      hospital_id,
                    })}
                    handleData={(data) => ({
                      label: data.name,
                      value: data.id,
                    })}
                  />
                </Grid>

                <Grid item sm={6}>
                  <SelectField
                    label={
                      <FormattedMessage id="doctor" defaultMessage="Doctor" />
                    }
                    name="doctor_id"
                    control={control}
                    url={endpoints.doctors}
                    getQuery={(inputValue, selected) => ({
                      ...(inputValue && { name: inputValue }),
                      ...(selected && { id: selected }),
                      hospital_id,
                    })}
                    handleData={(data) => ({
                      label: data.name,
                      value: data.id,
                    })}
                  />
                </Grid>
              </>
            )}

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("title")}
                label={<FormattedMessage id="title" defaultMessage="Title" />}
                error={errors.title}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("description")}
                label={
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                }
                error={errors.description}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("medical_history")}
                label={
                  <FormattedMessage
                    id="medicalHistory"
                    defaultMessage="Medical History"
                  />
                }
                error={errors.medical_history}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                name="status"
                control={control}
                options={[
                  { label: "Open", value: "open" },
                  { label: "Closed", value: "closed" },
                ]}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
