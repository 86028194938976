const paths = {
  signIn: "/signin",
  // adminSignIn: "/signin",
  // hospitalSignIn: "/hospital/signin",
  signin: "/signin",
  forgotPassword: "/forgot-password",
  deleteAccount: "/:accountType/delete-account",
  activateAccount: "/activate/:accountType/:id/:otp",
  clients: {
    basePath: "/clients",
    hospitals: "/hospitals",
    refHospitals: "/referral-hospitals",
    users: "/users",
    doctors: "/doctors",
    pharmacists: "/pharmacists",
    nurses: "/nurses",
    stakeholders: "/stakeholders",
  },
  settings: {
    basePath: "/settings",
    emailTemplates: "/email-templates",
    departments: "/departments",
    general: "/general",
    apiKeys: "/api-keys",
    roles: "/roles",
    profile: "/profile",
    serviceProviders: "/service-providers",
  },
  salaries: "/salaries",
  payouts: "/payouts",
  subscriptionPackages: "/subscription-packages",
  subscriptions: "/subscriptions",
  healthGroups: "/health-groups",
  healthGroupSubs: "/health-group-subscriptions",
  healthGroupSessions: "/health-group-sessions",
  appointments: "/appointments",
  drugs: "/drugs",
  dispensary: {
    basePath: "/dispensary",
    dispensaries: "/dispensaries",
    suppliers: "/suppliers",
    drugs: "/drugs",
    purchases: "/purchases",
    sales: "/sales",
  },
  prescriptions: "/prescriptions",
  casefiles: "/casefiles",
  hospitalRefs: "/hospital-referrals",
  reports: "/reports",
  transactions: "/transactions",
  bankTransfers: "/bank-transfers",
  fundWallets: "/fund-wallets",
};

export default paths;
