import { useState } from "react";
import { Wrapper, Datagrid, DeleteActionIcon, Permission } from "components";

import {
  Typography,
  IconButton,
  Button,
  Card,
  CardContent,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { NotificationManager } from "react-notifications";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import { useFetch } from "hooks";
import format from "date-fns/format";
import Form from "./Form";
import Filters from "./Filters";
import { useAppState } from "components/AppProvider/AppProvider";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
}));

const Users = () => {
  const { authUser } = useAppState();
  const classes = useStyles();
  const [edit, setEdit] = useState(null);
  const [filters, setFilters] = useState({});
  const { remove: deleteItem, loading: deleting } = useFetch(
    endpoints.departments
  );
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="departments"
                    defaultMessage="Departments"
                  />
                </Typography>
                <Permission permission="add_department">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setEdit(true)}
                  >
                    <FormattedMessage
                      id="addDepartment"
                      defaultMessage="Add Department"
                    />
                  </Button>
                </Permission>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters
                      filters={filters}
                      setFilters={setFilters}
                      authUser={authUser}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Datagrid
                url={endpoints.departments}
                columns={[
                  {
                    headerName: (
                      <FormattedMessage
                        id="createdAt"
                        defaultMessage="Created At"
                      />
                    ),
                    field: "date_time",
                    renderCell: ({ row }) =>
                      format(new Date(row.createdAt), "dd MMM yyyy hh:mma"),
                  },
                  {
                    headerName: (
                      <FormattedMessage id="name" defaultMessage="Name" />
                    ),
                    field: "name",
                  },
                  ...(authUser.user_type === "admin"
                    ? [
                        {
                          headerName: (
                            <FormattedMessage
                              id="hospital"
                              defaultMessage="Hospital"
                            />
                          ),
                          field: "hospital.name",
                          renderCell: ({ row }) => row.hospital?.name,
                        },
                      ]
                    : []),
                  {
                    headerName: (
                      <FormattedMessage id="action" defaultMessage="Action" />
                    ),
                    field: "action",
                    renderCell: ({ row }) => (
                      <>
                        <Permission permission="update_departments">
                          <IconButton
                            aria-label={
                              <FormattedMessage
                                id="editDepartment"
                                defaultMessage="Edit Department"
                              />
                            }
                            onClick={() => setEdit(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Permission>
                        <Permission permission="remove_departments">
                          <DeleteActionIcon
                            aria-label={
                              <FormattedMessage
                                id="deleteDepartment"
                                defaultMessage="Delete Departments"
                              />
                            }
                            title={
                              <FormattedMessage
                                id="deleteDepartment"
                                defaultMessage="Delete Department"
                              />
                            }
                            message={
                              <FormattedMessage
                                id="deleteConfirmation_department"
                                defaultMessage="Are you sure want to remove this department?"
                              />
                            }
                            disabled={deleting}
                            deleteRecord={() => {
                              deleteItem({ ids: [row.id] })
                                .then(({ data }) => {
                                  if (data?.success) {
                                    setFilters((prev) => ({ ...prev }));
                                    return NotificationManager.success(
                                      data.message
                                    );
                                  }
                                  NotificationManager.error(data.message);
                                })
                                .catch((err) =>
                                  NotificationManager.error(err.message)
                                );
                            }}
                          />
                        </Permission>
                      </>
                    ),
                  },
                ]}
                filters={filters}
              />
            </Grid>
          </Grid>
        </Grid>
        <Form
          authUser={authUser}
          open={!!edit}
          edit={edit}
          setEdit={setEdit}
          onSuccess={(newItem) => {
            setFilters((prev) => ({ ...prev }));
            setEdit(null);
          }}
        />
      </Wrapper>
    </>
  );
};

export default Users;
