import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { StylesProvider, jssPreset } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { endpoints, paths } from "appConfigs";
import { NotificationManager } from "react-notifications";

import { ThemeProvider } from "@material-ui/styles";
import { create } from "jss";
import { createMuiTheme } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import red from "@material-ui/core/colors/red";
import rtl from "jss-rtl";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const Context = createContext();
const { Provider } = Context;

const AppProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery("@media (prefers-color-scheme: dark)");
  const [config, setConfig] = useState(null);
  const [state, setState] = useState({
    type: prefersDarkMode ? "dark" : "light",
    direction: "ltr",
    locale: "en-US",
  });
  const history = useHistory();

  const [authUser, setAuthUser] = useState(null);
  const logoutUser = useCallback(() => {
    let logoutUrl = null;
    switch (localStorage.getItem("user_type")) {
      case "admin":
        logoutUrl = endpoints.admin.logout;
        break;
      case "ref_hospital":
        logoutUrl = endpoints.refHospital.logout;
        break;
      case "stakeholder":
        logoutUrl = endpoints.stakeholder.logout;
        break;
      default:
        logoutUrl = endpoints.hospital.logout;
        break;
    }
    fetch(logoutUrl, {
      method: "POST",
      headers: { "x-access-token": localStorage.getItem("access_token") },
    })
      .then(async (res) => {
        const data = await res.json();
        if ([200, 401].includes(res.status)) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("user_type");
          setAuthUser(null);
          history.push(paths.signIn);
        } else {
          NotificationManager.error(data.message);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err.message);
      });
  }, [history, authUser]);

  const theme = createMuiTheme({
    direction: state.direction,
    palette: {
      type: state.type,
      primary: indigo,
      secondary: red,
      error: red,
    },
    typography: {
      headline: {
        fontSize: "1rem",
      },
      subtitle1: {
        fontSize: "0.8125rem",
      },
      button: {
        fontWeight: 400,
        textTransform: "initial",
      },
      body1: {
        fontSize: "0.875rem",
      },
    },
    shape: {
      borderRadius: 4,
    },
  });

  useEffect(() => {
    document.body.dir = state.direction;
  }, [state.direction]);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <Provider
          value={{
            config,
            setConfig,
            state,
            setState,
            authUser,
            setAuthUser,
            logoutUser,
          }}
        >
          {children}
        </Provider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default AppProvider;
export const useAppState = () => useContext(Context);
