import React, { useState } from "react";
import { Wrapper } from "../../components";

import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { useAppState } from "components/AppProvider/AppProvider";
import Form from "./Form";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { NotificationManager } from "react-notifications";
import { paths } from "appConfigs";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
}));

const DeleteAccount = ({ history, match }) => {
  const classes = useStyles();
  if (!["users", "nurses", "doctors"].includes(match?.params.accountType)) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={4}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="deleteAccount"
                    defaultMessage="Delete Account"
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Form
                      userType={match?.params?.accountType}
                      onSuccess={(user) => {
                        NotificationManager.success(
                          "Account has been deleted succesfully."
                        );
                        history.push(paths.signIn);
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default DeleteAccount;
