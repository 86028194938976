import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  InputAdornment,
  FormHelperText,
  Box,
} from "@material-ui/core";
import { TextField, SelectField, Datagrid } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import format from "date-fns/format";
import DeleteIcon from "@material-ui/icons/Delete";

const schema = yup.object({
  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  dispensary_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  supplier_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  reference: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  date: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  description: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    ),
  shipping_charge: yup
    .number()
    .min(
      0,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 0 }}
      />
    )
    .nullable()
    .typeError(
      <FormattedMessage
        id="validationNumber"
        defaultMessage="Enter a valid number"
      />
    ),
  tax: yup
    .number()
    .min(
      0,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 0 }}
      />
    )
    .nullable()
    .typeError(
      <FormattedMessage
        id="validationNumber"
        defaultMessage="Enter a valid number"
      />
    ),
  products: yup
    .array()
    .of(
      yup.object({
        drug_id: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        name: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        qty: yup
          .number()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        unit: yup
          .string()
          .oneOf(["piece", "pack"])
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        items_per_pack: yup
          .number()
          .min(
            1,
            <FormattedMessage
              id="validationMinNum"
              defaultMessage="Can't be less than {num}"
              values={{ num: 1 }}
            />
          )
          .when("unit", (unit, field) =>
            unit === "pack"
              ? field.required(
                  <FormattedMessage
                    id="fieldIsRequired"
                    defaultMessage="Field is required"
                  />
                )
              : field.nullable()
          ),
        cost: yup
          .number()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        discount: yup
          .number()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        // profit_margin: yup
        //   .number()
        //   .required(
        //     <FormattedMessage
        //       id="fieldIsRequired"
        //       defaultMessage="Field is required"
        //     />
        //   ),
        batch_number: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        mfg_date: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        exp_date: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
      })
    )
    .min(
      1,
      <FormattedMessage
        id="validationSelectFieldMin"
        values={{
          num: 1,
          field: <FormattedMessage id="product" defaultMessage="Product" />,
        }}
        defaultMessage="Please add at least one product"
      />
    )
    .required(
      <FormattedMessage
        id="validationSelectFieldMin"
        values={{
          num: 1,
          field: <FormattedMessage id="product" defaultMessage="Product" />,
        }}
        defaultMessage="Please add at least one product"
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  chips: {
    padding: "1rem",
  },
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
  inputCellSelect: {
    marginTop: ".75rem",
  },
}));

const Form = ({ open, edit, setEdit, authUser, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({ resolver: useYup(schema) });
  const {
    post: addPurchase,
    put: updatePurchase,
    loading,
  } = useFetch(endpoints.dispensaryPurchases + `/${edit?.id || ""}`);

  const products = watch("products");
  const hospital_id = watch("hospital_id");

  useEffect(() => {
    reset({
      ...edit,
      products:
        edit?.products?.map((item, index) => ({
          ...item,
          index,
          // id: `${item.id}_${index}`,
          name: item.drug?.brand_name || "",
        })) || [],
      hospital_id:
        authUser.user_type === "hospital"
          ? authUser.id
          : edit?.hospital?.id || "",
      dispensary_id: edit?.dispensary_id || "",
      date: edit?.date ? format(new Date(edit.date), "yyyy-MM-dd") : "",
    });
  }, [edit]);
  return (
    <Dialog open={open} fullScreen>
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage id="editPurchase" defaultMessage="Edit Purchase" />
        ) : (
          <FormattedMessage id="addPurchase" defaultMessage="Add Purchase" />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            const payload = {
              ...values,
              products: values.products.map((item) => ({
                drug_id: item.drug_id,
                hospital_id: item.hospital_id,
                batch_number: item.batch_number,
                unit: item.unit,
                qty: item.qty,
                items_per_pack:
                  item.unit === "pack" ? item.items_per_pack : null,
                mfg_date: item.mfg_date,
                exp_date: item.exp_date,
                cost: item.cost,
                discount: item.discount,
              })),
              latitude: position?.latitude,
              longitude: position?.longitude,
            };
            (edit?.id ? updatePurchase : addPurchase)(payload)
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            {authUser.user_type === "admin" && (
              <Grid item sm={3}>
                <SelectField
                  label={
                    <FormattedMessage id="hospital" defaultMessage="Hospital" />
                  }
                  name="hospital_id"
                  control={control}
                  url={endpoints.hospitals}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                  onChange={(v) => {
                    setValue("dispensary_id", "");
                    setValue("supplier_id", "");
                  }}
                />
              </Grid>
            )}

            <Grid item sm={3}>
              <SelectField
                label={
                  <FormattedMessage
                    id="dispensary"
                    defaultMessage="Dispensary"
                  />
                }
                name="dispensary_id"
                control={control}
                url={endpoints.dispensaries}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                  hospital_id: hospital_id || null,
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
                disabled={!hospital_id}
              />
            </Grid>

            <Grid item sm={3}>
              <SelectField
                label={
                  <FormattedMessage id="supplier" defaultMessage="Supplier" />
                }
                name="supplier_id"
                control={control}
                url={endpoints.dispensarySuppliers}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                  hospital_id: hospital_id || null,
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
                disabled={!hospital_id}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                {...register("reference")}
                label={
                  <FormattedMessage id="reference" defaultMessage="Reference" />
                }
                error={errors.reference}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                type="date"
                {...register("date")}
                label={<FormattedMessage id="date" defaultMessage="Date" />}
                error={errors.date}
              />
            </Grid>

            <Grid item sm={3}>
              <SelectField
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                name="status"
                control={control}
                disabled={edit?.status === "received"}
                options={[
                  { label: "Pending", value: "pending" },
                  { label: "Ordered", value: "ordered" },
                  { label: "Recieved", value: "received" },
                ]}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                multiline
                {...register("description")}
                label={
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                }
                error={errors.description}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                type="number"
                {...register("shipping_charge")}
                label={
                  <FormattedMessage
                    id="shipping_charge"
                    defaultMessage="Shipping Charge"
                  />
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₦</InputAdornment>
                  ),
                }}
                error={errors.shipping_charge}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                type="number"
                {...register("tax")}
                label={<FormattedMessage id="tax" defaultMessage="Tax" />}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                error={errors.tax}
              />
            </Grid>

            <Grid item sm={12}>
              <Products
                edit={edit}
                hospital_id={hospital_id}
                products={products}
                setProducts={(data) => setValue("products", data)}
                errors={errors}
                clearError={() => clearErrors("products")}
              />
              {errors.products && (
                <FormHelperText error>{errors.products.message}</FormHelperText>
              )}
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Products = ({
  edit,
  hospital_id,
  products = [],
  setProducts,
  errors,
  clearError,
}) => {
  const { control, setValue } = useForm();

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h5">
          <FormattedMessage id="products" defaultMessage="Products" />
        </Typography>
      </Grid>
      {edit?.status !== "received" && (
        <Grid item sm={6}>
          <SelectField
            label={<FormattedMessage id="drug" defaultMessage="Drug" />}
            name="drug"
            control={control}
            url={endpoints.drugs}
            getQuery={(inputValue, selected) => ({
              ...(inputValue && { name: inputValue }),
              ...(selected && { id: selected }),
              hospital_id: hospital_id || null,
            })}
            handleData={(data) => ({
              label: data.brand_name,
              value: data.id,
              cost: data.price,
            })}
            onChange={(v) => {
              setValue("drug", null);
              clearError();
              setProducts(
                [
                  ...products,
                  {
                    id: `${v.value}_${products.length}`,
                    drug_id: v.value,
                    name: v.label,
                    qty: 1,
                    unit: "piece",
                    cost: v.cost,
                    discount: 0,
                    profit_margin: 0,
                  },
                ].map((item, index) => ({ ...item, index }))
              );
            }}
            disabled={!hospital_id}
          />
        </Grid>
      )}
      <Grid item sm={12}>
        <Datagrid
          columns={[
            {
              headerName: "#",
              field: "index",
              width: 20,
              renderCell: ({ row }) => row.index + 1,
            },
            {
              headerName: <FormattedMessage id="name" defaultMessage="Name" />,
              field: "name",
            },
            {
              headerName: (
                <FormattedMessage id="quantity" defaultMessage="Quantity" />
              ),
              field: "qty",
              width: 110,
              renderCell: ({ row }) => (
                <InputCell
                  row={row}
                  field="qty"
                  products={products}
                  setProducts={setProducts}
                  disabled={edit?.status === "received"}
                />
              ),
            },
            {
              headerName: <FormattedMessage id="unit" defaultMessage="Unit" />,
              field: "unit",
              width: 210,
              renderCell: ({ row }) => (
                <InputCell
                  type="select"
                  options={[
                    { label: "Piece", value: "piece" },
                    { label: "Pack", value: "pack" },
                  ]}
                  row={row}
                  field="unit"
                  products={products}
                  setProducts={setProducts}
                  disabled={edit?.status === "received"}
                  itemsPerPackError={
                    !!errors[`products[${row.index}].items_per_pack`]
                  }
                />
              ),
            },
            {
              headerName: <FormattedMessage id="cost" defaultMessage="Cost" />,
              field: "cost",
              width: 130,
              renderCell: ({ row }) => (
                <InputCell
                  row={row}
                  field="cost"
                  products={products}
                  setProducts={setProducts}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₦</InputAdornment>
                    ),
                  }}
                  disabled={edit?.status === "received"}
                />
              ),
            },
            {
              headerName: (
                <FormattedMessage id="discount" defaultMessage="Discount" />
              ),
              field: "discount",
              width: 100,
              renderCell: ({ row }) => (
                <InputCell
                  row={row}
                  field="discount"
                  products={products}
                  setProducts={setProducts}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              ),
            },
            {
              headerName: (
                <FormattedMessage id="total" defaultMessage="Total" />
              ),
              field: "total",
              renderCell: ({ row }) =>
                "₦" +
                (
                  row.qty * row.cost -
                  (row.qty * row.cost).percent(+row.discount || 0)
                ).toLocaleString(),
            },
            {
              headerName: (
                <FormattedMessage
                  id="batchNumber"
                  defaultMessage="Batch Number"
                />
              ),
              field: "batch_number",
              renderCell: ({ row }) => (
                <InputCell
                  row={row}
                  field="batch_number"
                  products={products}
                  setProducts={setProducts}
                  type="text"
                  error={!!errors[`products[${row.index}].batch_number`]}
                />
              ),
            },
            {
              headerName: (
                <FormattedMessage id="mfgDate" defaultMessage="MFG. Date" />
              ),
              field: "mfg_date",
              renderCell: ({ row }) => (
                <InputCell
                  row={row}
                  field="mfg_date"
                  products={products}
                  setProducts={setProducts}
                  type="date"
                  error={!!errors[`products[${row.index}].mfg_date`]}
                />
              ),
            },
            {
              headerName: (
                <FormattedMessage id="expDate" defaultMessage="EXP. Date" />
              ),
              field: "exp_date",
              renderCell: ({ row }) => (
                <InputCell
                  row={row}
                  field="exp_date"
                  products={products}
                  setProducts={setProducts}
                  type="date"
                  error={!!errors[`products[${row.index}].exp_date`]}
                />
              ),
            },
            {
              headerName: (
                <FormattedMessage id="action" defaultMessage="Action" />
              ),
              field: "action",
              renderCell: ({ row }) => (
                <>
                  {edit?.status !== "received" && (
                    <IconButton
                      aria-label={
                        <FormattedMessage id="remove" defaultMessage="Remove" />
                      }
                      onClick={() =>
                        setProducts(
                          products
                            .filter((item) => item.id !== row.id)
                            .map((item, index) => ({ ...item, index }))
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </>
              ),
            },
          ]}
          hideFooterPagination={true}
          rows={products}
          autoPageSize
        />
        <Box>
          <Typography variant="h4" align="right">
            Total:{" "}
            {products
              .reduce(
                (p, c) =>
                  p +
                  (c.cost - (c.discount ? (c.cost / 100) * c.discount : 0)) *
                    c.qty,
                0
              )
              .toLocaleString()}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const InputCell = ({
  row,
  field,
  min,
  setProducts,
  products = [],
  type = "number",
  itemsPerPackError,
  ...rest
}) => {
  const s = useStyles();
  const { control, watch } = useForm({
    defaultValues: {
      [field]: row[field],
    },
  });
  const unit = watch("unit");

  if (type === "select") {
    return (
      <Grid container spacing={1} className={s.inputCellSelect}>
        <Grid item sm={unit === "pack" ? 6 : 12}>
          <SelectField
            disableClearable
            fullWidth
            inputProps={{
              variant: "outlined",
            }}
            name={field}
            control={control}
            onChange={(v) =>
              setProducts(
                products.map((item) =>
                  item.id === row.id ? { ...item, [field]: v.value } : item
                )
              )
            }
            {...rest}
          />
        </Grid>
        {unit === "pack" && (
          <Grid item sm={6}>
            <TextField
              placeholder="Items per pack"
              value={(+row.items_per_pack || "").toString() || ""}
              type={type}
              min={1}
              variant="outlined"
              onChange={(e) => {
                if (+e.target.value >= (min || 0)) {
                  setProducts(
                    products.map((item) =>
                      item.id === row.id
                        ? { ...item, items_per_pack: +e.target.value || 0 }
                        : item
                    )
                  );
                }
              }}
              error={itemsPerPackError}
            />
          </Grid>
        )}
      </Grid>
    );
  }
  return (
    <TextField
      value={
        type === "number" ? (+row[field] || 0).toString() : row[field] || ""
      }
      type={type}
      {...(type === "number" && { min: 1 })}
      variant="outlined"
      onChange={(e) => {
        if (type === "number" && +e.target.value >= (min || 0)) {
          setProducts(
            products.map((item) =>
              item.id === row.id
                ? { ...item, [field]: +e.target.value || 0 }
                : item
            )
          );
        } else {
          setProducts(
            products.map((item) =>
              item.id === row.id ? { ...item, [field]: e.target.value } : item
            )
          );
        }
      }}
      {...rest}
    />
  );
};

export default Form;
