import React, { useState } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  IconButton,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const DeleteActionIcon = ({
  title,
  deleteRecord,
  message = "Are you sure you want to delete this item",
  children,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {children ? (
        <span onClick={() => setOpen(true)}>{children}</span>
      ) : (
        <IconButton
          type="button"
          className="rct-link-btn"
          onClick={() => setOpen(true)}
        >
          <DeleteIcon />
        </IconButton>
      )}
      <Dialog open={open}>
        <DialogTitle>
          {title || (
            <FormattedMessage id="deleteItem" defaultMessage="Delete Item" />
          )}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpen(false)}>
            <FormattedMessage id="no" defaultMessage="No" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              deleteRecord();
              setOpen(false);
            }}
          >
            <FormattedMessage id="yes" defaultMessage="Yes" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteActionIcon.propTypes = {
  deleteRecord: PropTypes.func,
};

export default DeleteActionIcon;
