import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import { NotificationManager } from "react-notifications";
import { useAppState } from "components/AppProvider/AppProvider";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";
import { Combobox } from "components";

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  session: {
    position: "relative",
    zIndex: 50,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center",
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
  },
  fullWidth: {
    width: "100%",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
  },
  logoType: {
    fontSize: "3.5rem",
    margin: "0",
    lineHeight: "1em",
    wordBreak: "break-all",
    "@media(max-width: 480px)": {
      fontSize: "2.5rem",
    },
  },
  link: {
    marginTop: "1rem",
    textAlign: "left",
    opacity: 0.8,
    textDecoration: "underline",
  },
}));

const schema = yup.object({
  user_type: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  username: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  password: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});
const otpSchema = yup.object({
  otp: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});

const Signin = ({ history }) => {
  const classes = useStyles();
  const [otpForm, setOtpForm] = useState(false);
  const { authUser, setAuthUser, config, setConfig } = useAppState();
  const { handleSubmit, control, register, reset, watch, setValue } = useForm({
    resolver: useYup(otpForm ? otpSchema : schema),
  });

  const { get: getSiteConfig } = useFetch(endpoints.siteConfig);

  const { post: adminSignIn } = useFetch(endpoints.adminLogin);
  const { get: getAdminProfile } = useFetch(endpoints.admin.profile);

  const { post: hospitalSignIn } = useFetch(endpoints.hospitalLogin);
  const { get: getHospitalProfile } = useFetch(endpoints.hospital.profile);

  const { post: refHospitalSignIn } = useFetch(endpoints.refHospitalLogin);
  const { get: getRefHospitalProfile } = useFetch(
    endpoints.refHospital.profile
  );

  const { post: stakeholderSignIn } = useFetch(endpoints.stakeholderLogin);
  const { get: getStakeholderProfile } = useFetch(
    endpoints.stakeholder.profile
  );

  const userType = watch("user_type");

  useEffect(() => {
    if (authUser) {
      history.push(history.location?.state?.from || "/");
    } else if (localStorage.getItem("access_token")) {
      const userType = localStorage.getItem("user_type") || "admin";
      let action = null;
      if (userType === "admin") {
        action = getAdminProfile;
      } else if (userType === "hospital") {
        action = getHospitalProfile;
      } else if (userType === "ref_hospital") {
        action = getRefHospitalProfile;
      } else if (userType === "stakeholder") {
        action = getStakeholderProfile;
      }
      action()
        .then(({ data }) => {
          if (data.success) {
            setAuthUser(data.data);
            history.push(history.location?.state?.from || "/");
          }
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    getSiteConfig()
      .then(({ data }) => {
        if (data.success) {
          setValue("user_type", "hospital");
          setConfig(data.data);
        }
      })
      .catch((err) => NotificationManager.error(err.message));
  }, []);

  useEffect(() => {
    reset({
      user_type: localStorage.getItem("user_type") || "admin",
    });
  }, []);
  return (
    <div className={classNames(classes.session, classes.background)}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <Card>
            <CardContent>
              <form
                onSubmit={handleSubmit(async (values) => {
                  const { position, error } = await getUserLocation();
                  if (error) {
                    return NotificationManager.error(error.message);
                  }
                  let action = null;
                  if (userType === "admin") {
                    action = adminSignIn;
                  } else if (userType === "hospital") {
                    action = hospitalSignIn;
                  } else if (userType === "ref_hospital") {
                    action = refHospitalSignIn;
                  } else if (userType === "stakeholder") {
                    action = stakeholderSignIn;
                  }

                  action({
                    username: values.username,
                    password: values.password,
                    ...(values.otp && { otp: values.otp }),
                    latitude: position?.latitude,
                    longitude: position?.longitude,
                  })
                    .then(({ data }) => {
                      if (data?.success && data.data?.accessToken) {
                        localStorage.setItem(
                          "access_token",
                          data.data.accessToken
                        );
                        localStorage.setItem("user_type", userType);
                        let getProfile = null;
                        if (userType === "admin") {
                          getProfile = getAdminProfile;
                        } else if (userType === "hospital") {
                          getProfile = getHospitalProfile;
                        } else if (userType === "ref_hospital") {
                          getProfile = getRefHospitalProfile;
                        } else if (userType === "stakeholder") {
                          getProfile = getStakeholderProfile;
                        }
                        getProfile()
                          .then(({ data }) => {
                            if (data.success) {
                              setAuthUser(data.data);
                              history.push(
                                history.location?.state?.from || "/"
                              );
                            }
                          })
                          .catch((err) => {
                            NotificationManager.error(err.message);
                          });
                      } else if (data?.success) {
                        NotificationManager.success(data.message);
                        setOtpForm(true);
                      } else {
                        NotificationManager.error(data.message);
                      }
                    })
                    .catch((err) => {
                      NotificationManager.error(err.message);
                    });
                })}
              >
                <div
                  className={classNames(classes.logo, `text-xs-center pb-xs`)}
                >
                  <h1 className={classes.logoType}>
                    {config?.siteTitle || "VHMS247"}
                  </h1>
                  {!config && (
                    <Typography variant="caption">
                      Virtual Hospital Management Systemt.
                    </Typography>
                  )}
                </div>

                {otpForm ? (
                  <>
                    <Typography
                      align="left"
                      style={{ marginTop: "2rem" }}
                      variant="body2"
                    >
                      An otp has been sent to your email. Please enter the otp
                      to log in.
                    </Typography>
                    <TextField
                      label={<FormattedMessage id="otp" defaultMessage="OTP" />}
                      fullWidth
                      {...register("otp")}
                      margin="normal"
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        marginTop: "1rem",
                      }}
                    >
                      <button
                        style={{
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => setOtpForm(false)}
                      >
                        Back
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <Combobox
                      label="Login as"
                      disableClearable
                      name="user_type"
                      control={control}
                      options={
                        config
                          ? [{ label: "Hospital", value: "hospital" }]
                          : [
                              { label: "Admin", value: "admin" },
                              { label: "Hospital", value: "hospital" },
                              {
                                label: "Referral Hospital",
                                value: "ref_hospital",
                              },
                              { label: "Stakeholder", value: "stakeholder" },
                            ]
                      }
                    />

                    <TextField
                      label={
                        <FormattedMessage
                          id="emailOrPhoneNumber"
                          defaultMessage="Email or Phone Number"
                        />
                      }
                      fullWidth
                      {...register("username")}
                      margin="normal"
                    />
                    <TextField
                      label={
                        <FormattedMessage
                          id="password"
                          defaultMessage="Password"
                        />
                      }
                      type="password"
                      fullWidth
                      {...register("password")}
                      margin="normal"
                    />
                  </>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  className="mt-1"
                >
                  <FormattedMessage id="login" defaultMessage="Login" />
                </Button>
                {
                  // <div className="pt-1 text-md-center">
                  //   <Link to="/forgot-password">
                  //     <Button>Forgot password?</Button>
                  //   </Link>
                  // </div>
                }
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Signin;
