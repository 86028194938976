import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Typography,
  FormHelperText,
  Box,
} from "@material-ui/core";
import { Datagrid } from "components";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import format from "date-fns/format";

const schema = yup.object({
  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  dispensary_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  supplier_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  reference: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  date: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  description: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    ),
  shipping_charge: yup
    .number()
    .min(
      0,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 0 }}
      />
    )
    .nullable()
    .typeError(
      <FormattedMessage
        id="validationNumber"
        defaultMessage="Enter a valid number"
      />
    ),
  tax: yup
    .number()
    .min(
      0,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 0 }}
      />
    )
    .nullable()
    .typeError(
      <FormattedMessage
        id="validationNumber"
        defaultMessage="Enter a valid number"
      />
    ),
  products: yup
    .array()
    .of(
      yup.object({
        drug_id: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        name: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        qty: yup
          .number()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        unit: yup
          .string()
          .oneOf(["piece", "pack"])
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        items_per_pack: yup
          .number()
          .min(
            1,
            <FormattedMessage
              id="validationMinNum"
              defaultMessage="Can't be less than {num}"
              values={{ num: 1 }}
            />
          )
          .when("unit", (unit, field) =>
            unit === "pack"
              ? field.required(
                  <FormattedMessage
                    id="fieldIsRequired"
                    defaultMessage="Field is required"
                  />
                )
              : field.nullable()
          ),
        cost: yup
          .number()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        discount: yup
          .number()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        // profit_margin: yup
        //   .number()
        //   .required(
        //     <FormattedMessage
        //       id="fieldIsRequired"
        //       defaultMessage="Field is required"
        //     />
        //   ),
        batch_number: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        mfg_date: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        exp_date: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
      })
    )
    .min(
      1,
      <FormattedMessage
        id="validationSelectFieldMin"
        values={{
          num: 1,
          field: <FormattedMessage id="product" defaultMessage="Product" />,
        }}
        defaultMessage="Please add at least one product"
      />
    )
    .required(
      <FormattedMessage
        id="validationSelectFieldMin"
        values={{
          num: 1,
          field: <FormattedMessage id="product" defaultMessage="Product" />,
        }}
        defaultMessage="Please add at least one product"
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  chips: {
    padding: "1rem",
  },
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
  inputCellSelect: {
    marginTop: ".75rem",
  },
}));

const Form = ({ view, edit, setView, authUser, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({ resolver: useYup(schema) });
  const {
    post: addPurchase,
    put: updatePurchase,
    loading,
  } = useFetch(endpoints.dispensaryPurchases + `/${edit?.id || ""}`);

  if (!view) return null;

  return (
    <Dialog open={!!view} fullScreen>
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage id="editPurchase" defaultMessage="Edit Purchase" />
        ) : (
          <FormattedMessage id="addPurchase" defaultMessage="Add Purchase" />
        )}
      </DialogTitle>
      <DialogContent>
        <form autoComplete={"off"}>
          <Grid container spacing={3}>
            {authUser.user_type === "admin" && (
              <Detail label="Hospital" value={view.hospital?.name} />
            )}

            <Detail label="Dispensary" value={view.dispensary?.name} />

            <Detail label="Reference" value={view.reference} />

            <Detail
              label="Date"
              value={format(new Date(view.date), "yyyy-MM-dd")}
            />

            <Detail label="Status" value={view.status} />

            <Detail label="Description" value={view.description} />

            <Detail
              label="Shipping Charge"
              value={"₦" + view.shipping_charge}
            />

            <Detail label="Tax" value={view.tax + "%"} />

            <Grid item sm={12}>
              <Products
                edit={edit}
                products={view.products}
                setProducts={(data) => setValue("products", data)}
                errors={errors}
                clearError={() => clearErrors("products")}
              />
              {errors.products && (
                <FormHelperText error>{errors.products.message}</FormHelperText>
              )}
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setView(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Detail = ({ label, value }) => {
  return (
    <Grid item sm={3}>
      <Grid item>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </Grid>
  );
};

const Products = ({ products = [] }) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h5">
          <FormattedMessage id="products" defaultMessage="Products" />
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <Datagrid
          columns={[
            {
              headerName: <FormattedMessage id="name" defaultMessage="Name" />,
              field: "name",
              renderCell: ({ row }) => row.drug?.generic_name,
            },
            {
              headerName: (
                <FormattedMessage id="quantity" defaultMessage="Quantity" />
              ),
              field: "qty",
              width: 110,
              renderCell: ({ row }) => row.qty,
            },
            {
              headerName: <FormattedMessage id="unit" defaultMessage="Unit" />,
              field: "unit",
              width: 210,
              renderCell: ({ row }) => row.unit,
            },
            {
              headerName: <FormattedMessage id="cost" defaultMessage="Cost" />,
              field: "cost",
              width: 130,
              renderCell: ({ row }) => row.cost,
            },
            {
              headerName: (
                <FormattedMessage id="discount" defaultMessage="Discount" />
              ),
              field: "discount",
              width: 100,
              renderCell: ({ row }) => row.discount,
            },
            {
              headerName: (
                <FormattedMessage id="total" defaultMessage="Total" />
              ),
              field: "total",
              renderCell: ({ row }) =>
                "₦" +
                (
                  row.qty * row.cost -
                  (row.qty * row.cost).percent(+row.discount || 0)
                ).toLocaleString(),
            },
            {
              headerName: (
                <FormattedMessage
                  id="batchNumber"
                  defaultMessage="Batch Number"
                />
              ),
              field: "batch_number",
              renderCell: ({ row }) => row.batch_number,
            },
            {
              headerName: (
                <FormattedMessage id="mfgDate" defaultMessage="MFG. Date" />
              ),
              field: "mfg_date",
              renderCell: ({ row }) =>
                format(new Date(row.mfg_date), "dd MMM yyyy"),
            },
            {
              headerName: (
                <FormattedMessage id="expDate" defaultMessage="EXP. Date" />
              ),
              field: "exp_date",
              renderCell: ({ row }) =>
                format(new Date(row.exp_date), "dd MMM yyyy"),
            },
          ]}
          hideFooterPagination={true}
          rows={products}
          autoPageSize
        />
        <Box>
          <Typography variant="h4" align="right">
            Total:{" "}
            {products
              .reduce(
                (p, c) =>
                  p +
                  (c.cost - (c.discount ? (c.cost / 100) * c.discount : 0)) *
                    c.qty,
                0
              )
              .toLocaleString()}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Form;
