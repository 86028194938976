import React from "react";
import { useAppState } from "components/AppProvider/AppProvider";

const Permission = ({ permission, children }) => {
  const { authUser } = useAppState();
  if (authUser?.permissions?.includes(permission)) {
    return children;
  }
  return null;
};

export default Permission;
