import { Button, Grid, makeStyles } from "@material-ui/core";
import { TextField, SelectField } from "components";
import { useForm } from "react-hook-form";
import { useYup } from "hooks";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const schema = yup.object({
  name: yup.string(),
  phone: yup.string(),
  email: yup
    .string()
    .email(
      <FormattedMessage
        id="validationEmail"
        defaultMessage="Enter a valid email"
        values={{ num: 1 }}
      />
    ),
  status: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    // margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-start",
  },
}));

const Form = ({ filters, setFilters }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(schema) });
  return (
    <Grid item className="mb-1">
      <form
        autoComplete={"off"}
        onSubmit={handleSubmit(async (values) => {
          const query = {};
          Object.entries(values).forEach(([key, value]) => {
            if (value) {
              query[key] = value;
            }
          });
          setFilters(query);
        })}
      >
        <Grid container spacing={3}>
          <Grid item sm={5}>
            <TextField
              fullWidth
              type="datetime-local"
              {...register("from_date")}
              label={
                <FormattedMessage id="from_date" defaultMessage="From Date" />
              }
              error={errors.from_date}
            />
          </Grid>

          <Grid item sm={5}>
            <TextField
              fullWidth
              type="datetime-local"
              {...register("to_date")}
              label={<FormattedMessage id="to_date" defaultMessage="To Date" />}
              error={errors.to_date}
            />
          </Grid>

          <Grid item sm={2} className={s.btns}>
            <Button type="submit" variant="contained" color="primary">
              <FormattedMessage id="search" defaultMessage="Search" />
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                setFilters({});
                reset();
              }}
            >
              <FormattedMessage id="clear" defaultMessage="Clear" />
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default Form;
