import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { TextField, SelectField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import format from "date-fns/format";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const addSchema = yup.object({
  health_group_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  date_time: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  duration: yup
    .number()
    .min(
      1,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 1 }}
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="validationNumber"
        defaultMessage="Enter a valid number"
      />
    ),
  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(addSchema) });
  const {
    post: addSession,
    put: updateSession,
    loading,
  } = useFetch(endpoints.healthGroupSessions);

  useEffect(() => {
    reset({
      ...edit,
      date_time: edit?.date_time
        ? format(new Date(edit.date_time), "yyyy-MM-dd hh:mm")
        : "",
    });
  }, [edit]);
  return (
    <Dialog open={open}>
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage
            id="editHealthGroupSession"
            defaultMessage="Edit Health Group Session"
          />
        ) : (
          <FormattedMessage
            id="addHealthGroupSession"
            defaultMessage="Add Health Group Session"
          />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            (edit?.id ? updateSession : addSession)({
              ...values,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage
                    id="healthGroup"
                    defaultMessage="Health Group"
                  />
                }
                name="health_group_id"
                control={control}
                url={endpoints.healthGroups}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                fullWidth
                type="datetime-local"
                {...register("date_time")}
                label={
                  <FormattedMessage
                    id="dateTime"
                    defaultMessage="Date & Time"
                  />
                }
                error={errors.date_time}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                fullWidth
                {...register("duration")}
                label={
                  <FormattedMessage id="duration" defaultMessage="Duration" />
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <FormattedMessage id="minutes" defaultMessage="Minutes" />
                    </InputAdornment>
                  ),
                }}
                error={errors.duration}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                name="status"
                control={control}
                options={[
                  { label: "Upcoming", value: "upcoming" },
                  { label: "On Going", value: "ongoing" },
                  { label: "Completed", value: "completed" },
                  { label: "Expired", value: "expired" },
                  { label: "Canceled by Doctor", value: "canceled_by_doctor" },
                  { label: "Canceled by Admin", value: "canceled_by_admin" },
                ]}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                disabled={loading}
                variant="contained"
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
