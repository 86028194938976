import { useState } from "react";
import { Wrapper, Datagrid, Permission } from "components";

import { Typography, IconButton, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import format from "date-fns/format";
import Form from "./Form";
import Filters from "./Filters";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
}));

const Users = () => {
  const classes = useStyles();
  const [edit, setEdit] = useState(null);
  const [filters, setFilters] = useState({});
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="healthGroupSessions"
                    defaultMessage="Health Group Sessions"
                  />
                </Typography>
                <Permission permission="add_health_group_session">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setEdit(true)}
                  >
                    <FormattedMessage
                      id="addHealthGroupSession"
                      defaultMessage="Add Health Group Session"
                    />
                  </Button>
                </Permission>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters filters={filters} setFilters={setFilters} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Datagrid
                  url={endpoints.healthGroupSessions}
                  filters={filters}
                  columns={[
                    {
                      headerName: (
                        <FormattedMessage
                          id="createdAt"
                          defaultMessage="Created At"
                        />
                      ),
                      field: "createdAt",
                      renderCell: ({ row }) =>
                        format(new Date(row.createdAt), "dd MMM yyyy hh:mma"),
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="healthGroup"
                          defaultMessage="Health Group"
                        />
                      ),
                      field: "health_group.name",
                      renderCell: ({ row }) => row.health_group?.name,
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="dateTime"
                          defaultMessage="Date Time"
                        />
                      ),
                      field: "date_time",
                      renderCell: ({ row }) =>
                        format(new Date(row.date_time), "dd MMM yyyy hh:mma"),
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="duration"
                          defaultMessage="Duration"
                        />
                      ),
                      field: "duration",
                      renderCell: ({ row }) => `${row.duration || 0} Minutes`,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="status" defaultMessage="Status" />
                      ),
                      field: "status",
                    },
                    {
                      headerName: (
                        <FormattedMessage id="action" defaultMessage="Action" />
                      ),
                      field: "action",
                      renderCell: ({ row }) => (
                        <>
                          <Permission permission="update_health_group_sessions">
                            <IconButton
                              aria-label={
                                <FormattedMessage
                                  id="editHealthGroup"
                                  defaultMessage="Edit Health Group"
                                />
                              }
                              onClick={() => setEdit(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Permission>
                          {/* <DeleteActionIcon to={`${props.pageUrl}/add`} /> */}
                        </>
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Form
          open={!!edit}
          edit={edit}
          setEdit={setEdit}
          onSuccess={(newSession) => {
            setFilters((prev) => ({ ...prev }));
            setEdit(null);
          }}
        />
      </Wrapper>
    </>
  );
};

export default Users;
