import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { TextField, SelectField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const packageSchema = yup.object({
  name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  description: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  price: yup
    .number()
    .min(
      1,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 1 }}
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="validationNumber"
        defaultMessage="Enter a valid number"
      />
    ),
  duration: yup
    .number()
    .min(
      1,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 1 }}
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="validationNumber"
        defaultMessage="Enter a valid number"
      />
    ),
  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  departments: yup
    .array()
    .of(
      yup
        .string()
        .required(
          <FormattedMessage
            id="fieldIsRequired"
            defaultMessage="Field is required"
          />
        )
    )
    .min(
      1,
      <FormattedMessage
        id="validationSelectFieldMin"
        defaultMessage="Select at least {num} {field}"
        values={{
          num: 1,
          field: (
            <FormattedMessage id="department" defaultMessage="Department" />
          ),
        }}
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="validationSelectFieldMin"
        defaultMessage="Select at least {num} {field}"
        values={{
          num: 1,
          field: (
            <FormattedMessage id="department" defaultMessage="Department" />
          ),
        }}
      />
    ),
  max_member: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  max_appointment: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, authUser, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(packageSchema) });
  const {
    post: addDoc,
    put: updateDoc,
    loading,
  } = useFetch(endpoints.subscriptionPackages + `/${edit?.id || ""}`);

  const hospital_id = watch("hospital_id");

  useEffect(() => {
    if (edit?.id) {
      if (edit.hospital.id === hospital_id) {
        return setValue(
          "departments",
          edit?.departments?.map((item) => item.id) || []
        );
      }
    }
    setValue("departments", []);
  }, [hospital_id]);

  useEffect(() => {
    reset({
      ...edit,
      departments: edit?.departments?.map((item) => item.id) || [],
      hospital_id:
        authUser.user_type === "hospital"
          ? authUser.id
          : edit?.hospital?.id || "",
    });
  }, [edit]);
  return (
    <Dialog open={open}>
      <DialogTitle>
        {edit?.id ? "Update" : "Add"} Subscription Package
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            (edit?.id ? updateDoc : addDoc)({
              ...values,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("name")}
                label={<FormattedMessage id="name" defaultMessage="Name" />}
                error={errors.name}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("description")}
                label={
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                }
                error={errors.description}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                fullWidth
                type="number"
                {...register("price")}
                label={<FormattedMessage id="price" defaultMessage="Price" />}
                error={errors.price}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                fullWidth
                type="number"
                {...register("duration")}
                label={
                  <>
                    <FormattedMessage id="duration" defaultMessage="Price" /> (
                    <FormattedMessage id="days" defaultMessage="Days" />)
                  </>
                }
                error={errors.duration}
              />
            </Grid>

            {authUser.user_type === "admin" && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage id="hospital" defaultMessage="Hospital" />
                  }
                  name="hospital_id"
                  control={control}
                  url={endpoints.hospitals}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            {hospital_id && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage
                      id="departments"
                      defaultMessage="Departments"
                    />
                  }
                  name="departments"
                  control={control}
                  multiple
                  url={endpoints.departments}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                    hospital_id,
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            <Grid item sm={12}>
              <TextField
                fullWidth
                type="number"
                {...register("max_member")}
                label={
                  <FormattedMessage
                    id="maxMember"
                    defaultMessage="Max Member"
                  />
                }
                error={errors.max_member}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                type="number"
                {...register("max_appointment")}
                label={
                  <FormattedMessage
                    id="maxAppointment"
                    defaultMessage="Max Appointment"
                  />
                }
                error={errors.max_appointment}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                name="status"
                control={control}
                options={[
                  { label: "Active", value: "active" },
                  { label: "Inactive", value: "inactive" },
                ]}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
