import { Bar, Bubble } from "react-chartjs-2";
import { StatCard, Wrapper } from "../../components";
import React, { useState, useEffect } from "react";
import { mockDashboard } from "../../utils/mock";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

import RecentActorsIcon from "@material-ui/icons/RecentActors";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import MoreIcon from "@material-ui/icons/More";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccessibleIcon from "@material-ui/icons/Accessible";
import SettingsIcon from "@material-ui/icons/Settings";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useFetch } from "hooks";
import { endpoints } from "appConfigs";
import format from "date-fns/format";
import { useAppState } from "components/AppProvider/AppProvider";
import { FormattedMessage } from "react-intl";

const Home = () => {
  const { authUser } = useAppState();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [data, setData] = useState({});
  const { get: getData } = useFetch(endpoints.dashboardData);
  useEffect(() => {
    getData()
      .then(({ data }) => {
        if (data?.success) {
          setData(data.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const chartMenu = (
    <Menu
      id="chart-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <MoreIcon />
        </ListItemIcon>
        <ListItemText primary="View more" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <NotificationsOffIcon />
        </ListItemIcon>
        <ListItemText primary="Disable notifications" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Remove panel" />
      </MenuItem>
    </Menu>
  );

  return (
    <Wrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title={<FormattedMessage id="doctors" defaultMessage="Doctors" />}
            value={data.totalDoctors || 0}
            icon={<GroupAddIcon />}
            color="#3f51b5"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title={<FormattedMessage id="users" defaultMessage="Users" />}
            value={data.totalUsers || 0}
            icon={<AccessibleIcon />}
            color="#9c27b0"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title={
              <FormattedMessage
                id="appointments"
                defaultMessage="Appointments"
              />
            }
            value={data.totalAppointments || 0}
            icon={<PeopleAltIcon />}
            color="#f44336"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title={
              <FormattedMessage
                id="subscriptions"
                defaultMessage="Subscriptions"
              />
            }
            value={data.totalSubs || 0}
            icon={<RecentActorsIcon />}
            color="#ffd740"
          />
        </Grid>
        {chartMenu}
        {mockDashboard.map((chart, index) => (
          <Grid item xs={12} sm={12} md={4} key={index}>
            <Card>
              <CardHeader
                subheader={chart.title}
                action={
                  <IconButton id={`${index}-menu-button`} onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                }
              />
              <CardContent>
                {chart.type === "bar" && (
                  <Bar
                    data={chart.data}
                    height={chart.height}
                    options={chart.options}
                  />
                )}
                {chart.type === "bubble" && (
                  <Bubble
                    data={chart.data}
                    height={chart.height}
                    options={chart.options}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} sm={12} md={12}>
          <Paper className="table-responsive">
            <CardHeader
              title={
                <FormattedMessage
                  id="upcomingAppointments"
                  defaultMessage="Upcoming Appointments"
                />
              }
            />
            <Divider />
            <Table>
              <TableHead>
                <TableRow>
                  {authUser.user_type === "admin" && (
                    <TableCell>
                      <FormattedMessage
                        id="hospital"
                        defaultMessage="Hospital"
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <FormattedMessage id="doctor" defaultMessage="Doctor" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="user" defaultMessage="User" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="dateTime"
                      defaultMessage="Date & Time"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.upcomingAppointments?.length > 0 ? (
                  data.upcomingAppointments.map((n) => (
                    <TableRow key={n.id}>
                      {authUser.user_type === "admin" && (
                        <TableCell component="th" scope="row">
                          {n.hospital?.name}
                        </TableCell>
                      )}
                      <TableCell component="th" scope="row">
                        {n.doctor?.name}
                      </TableCell>
                      <TableCell>{n.user?.name}</TableCell>
                      <TableCell>
                        {format(new Date(n.date_time), "dd MMM yyyy hh:mma")}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography align="center" variant="subtitle1">
                        <FormattedMessage
                          id="noUpcomingAppointments"
                          defaultMessage="No upcoming appointments"
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        {
          //   <Grid item xs={12} sm={12} md={4}>
          //   <NewsCard subtitle="Last updated 24 mins ago" feed={mockFeed} />
          // </Grid>
        }
      </Grid>
    </Wrapper>
  );
};

export default Home;
