import { useState } from "react";
import {
  Wrapper,
  Datagrid,
  DeleteActionIcon,
  Permission,
  ActionMenu,
} from "components";

import { Typography, IconButton, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import RequestForm from "./Form";
import CreateForm from "./CreateForm";
import Filters from "./Filters";
import { useAppState } from "components/AppProvider/AppProvider";
import { useFetch } from "hooks";
import { FormattedMessage } from "react-intl";
import { format } from "date-fns";
import { NotificationManager } from "react-notifications";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
  status: {
    textTransform: "capitalize",
  },
}));

const Payouts = () => {
  const { authUser } = useAppState();
  const classes = useStyles();
  const [addForm, setAddForm] = useState(null);
  const [requestForm, setRequestForm] = useState(null);
  const [filters, setFilters] = useState({});
  const { remove, loading } = useFetch(endpoints.payouts);
  const { put: approvePayout, loading: approving } = useFetch(
    endpoints.payouts + "/{id}"
  );
  const { post: completePayout, loading: completeting } = useFetch(
    endpoints.completePayout
  );
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage id="payouts" defaultMessage="Payouts" />
                </Typography>
                {authUser.user_type !== "admin" && (
                  <Permission permission="request_payouts">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => setRequestForm(true)}
                    >
                      <FormattedMessage
                        id="requestpayout"
                        defaultMessage="Request Payout"
                      />
                    </Button>
                  </Permission>
                )}
                <Permission permission="create_payouts">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setAddForm(true)}
                  >
                    <FormattedMessage
                      id="createPayout"
                      defaultMessage="Create Payout"
                    />
                  </Button>
                </Permission>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters
                      filters={filters}
                      setFilters={setFilters}
                      authUser={authUser}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Datagrid
                  url={endpoints.payouts}
                  filters={filters}
                  columns={[
                    {
                      headerName: (
                        <FormattedMessage
                          id="createdAt"
                          defaultMessage="Created At"
                        />
                      ),
                      field: "createdAt",
                      renderCell: ({ row }) =>
                        format(new Date(row.createdAt), "dd MMM yyyy hh:mma"),
                    },
                    {
                      headerName: (
                        <FormattedMessage id="user" defaultMessage="User" />
                      ),
                      field: "user",
                      renderCell: ({ row }) => row.user?.name,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="amount" defaultMessage="Amount" />
                      ),
                      field: "amount",
                      renderCell: ({ row }) =>
                        `₦${(row.amount || 0).toLocaleString()}`,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="status" defaultMessage="Status" />
                      ),
                      field: "status",
                      renderCell: ({ row }) => row.status,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="note" defaultMessage="Note" />
                      ),
                      field: "note",
                    },
                    {
                      headerName: (
                        <FormattedMessage id="action" label="Action" />
                      ),
                      field: "action",
                      renderCell: ({ row }) => (
                        <ActionMenu
                          menuItems={[
                            ...(row.status === "pending"
                              ? [
                                  {
                                    permission: "approve_payouts",
                                    icon: <DoneIcon />,
                                    label: "Approve",
                                    confirmationTitle: (
                                      <FormattedMessage
                                        id="approvePayout"
                                        defaultMessage="Approve Payout"
                                      />
                                    ),
                                    confirmation: (
                                      <FormattedMessage
                                        id="confirmationMessage_approvePayout"
                                        defaultMessage="Are you sure want to approve this payout?"
                                      />
                                    ),
                                    onClick: () => {
                                      approvePayout(
                                        { status: "verified" },
                                        { params: { "{id}": row.id } }
                                      )
                                        .then(({ data }) => {
                                          if (data.success) {
                                            setFilters((prev) => ({ ...prev }));
                                            return;
                                          }
                                          NotificationManager.error(
                                            data.message
                                          );
                                        })
                                        .catch((err) => {
                                          NotificationManager.error(
                                            err.message
                                          );
                                        });
                                    },
                                  },
                                ]
                              : []),
                            ...(row.status === "verified"
                              ? [
                                  {
                                    permission: "complete_payouts",
                                    icon: <AttachMoneyIcon />,
                                    label: "Complete Payout",
                                    confirmationTitle: (
                                      <FormattedMessage
                                        id="completePayout"
                                        defaultMessage="Complete Payout"
                                      />
                                    ),
                                    confirmation: (
                                      <FormattedMessage
                                        id="confirmationMessage_completePayout"
                                        defaultMessage="Are you sure want to complete this payout?"
                                      />
                                    ),
                                    onClick: () => {
                                      completePayout(
                                        {},
                                        {
                                          params: { "{id}": row.id },
                                        }
                                      )
                                        .then(({ data }) => {
                                          if (data.success) {
                                            setFilters((prev) => ({ ...prev }));
                                            return;
                                          }
                                          NotificationManager.error(
                                            data.message
                                          );
                                        })
                                        .catch((err) => {
                                          NotificationManager.error(
                                            err.message
                                          );
                                        });
                                    },
                                  },
                                ]
                              : []),
                            {
                              permission: "update_payouts",
                              icon: <EditIcon />,
                              label: "Edit",
                              onClick: () =>
                                authUser.user_type === "admin"
                                  ? setAddForm(row)
                                  : setRequestForm(row),
                            },
                            {
                              permission: "remove_payouts",
                              icon: <DeleteIcon />,
                              label: "Delete",
                              confirmationTitle: (
                                <FormattedMessage
                                  id="deletePayout"
                                  defaultMessage="Delete Payout"
                                />
                              ),
                              confirmation: (
                                <FormattedMessage
                                  id="deleteConfirmation_payout"
                                  defaultMessage="Are you sure want to remove this payout?"
                                />
                              ),
                              onClick: () =>
                                remove({ ids: row.id })
                                  .then(({ data }) => {
                                    if (data.success) {
                                      setFilters((prev) => ({ ...prev }));
                                    }
                                  })
                                  .catch((err) => {
                                    NotificationManager.error(err.message);
                                  }),
                            },
                          ]}
                        />
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <RequestForm
          open={!!requestForm}
          edit={requestForm}
          setEdit={setRequestForm}
          onSuccess={(newSubPackage) => {
            setFilters((prev) => ({ ...prev }));
            setRequestForm(null);
          }}
        />
        <CreateForm
          authUser={authUser}
          open={!!addForm}
          edit={addForm}
          setEdit={setAddForm}
          onSuccess={(newSubPackage) => {
            setFilters((prev) => ({ ...prev }));
            setAddForm(null);
          }}
        />
      </Wrapper>
    </>
  );
};

export default Payouts;
