import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  InputAdornment,
  Box,
} from "@material-ui/core";
import { TextField, SelectField, Datagrid } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import format from "date-fns/format";
import DeleteIcon from "@material-ui/icons/Delete";

const schema = yup.object({
  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  dispensary_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  user_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  reference: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  date: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  description: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    ),
  products: yup
    .array()
    .of(
      yup.object({
        id: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        name: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        qty: yup
          .number()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        price: yup
          .number()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        discount: yup
          .number()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
      })
    )
    .min(
      1,
      <FormattedMessage
        id="validationSelectFieldMin"
        value={{
          num: 1,
          field: <FormattedMessage id="product" defaultMessage="Product" />,
        }}
        defaultMessage="Please add at least one product"
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  chips: {
    padding: "1rem",
  },
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, authUser, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: useYup(schema) });
  const {
    post: addSale,
    put: updateSale,
    loading,
  } = useFetch(endpoints.dispensarySales + `/${edit?.id || ""}`);
  const { get: getDrugs, loading: loadingDrugs } = useFetch(endpoints.drugs);

  const hospital_id = watch("hospital_id");
  const products = watch("products");

  useEffect(async () => {
    if (edit?.generate_sale) {
      const drugs = await getDrugs({
        query: { ids: edit.products.map((item) => item.id) },
      }).then(({ data }) => data?.data || []);

      reset({
        ...edit,
        products: edit.products.map((item, i) => ({
          ...item,
          id: i,
          price: drugs.find((i) => i.id === item.id)?.price || 0,
        })),
        hospital_id:
          authUser.user_type === "hospital"
            ? authUser.id
            : edit?.hospital?.id || "",
        date: edit?.date ? format(new Date(edit.date), "yyyy-MM-dd") : "",
      });
    } else {
      reset({
        ...edit,
        hospital_id:
          authUser.user_type === "hospital"
            ? authUser.id
            : edit?.hospital?.id || "",
        date: edit?.date ? format(new Date(edit.date), "yyyy-MM-dd") : "",
      });
    }
  }, [edit]);
  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage id="editSale" defaultMessage="Edit Sale" />
        ) : (
          <FormattedMessage id="addSale" defaultMessage="Add Sale" />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            const payload = {
              ...values,
              latitude: position?.latitude,
              longitude: position?.longitude,
            };
            (edit?.id ? updateSale : addSale)(payload)
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            {authUser.user_type === "admin" && (
              <Grid item sm={3}>
                <SelectField
                  label={
                    <FormattedMessage id="hospital" defaultMessage="Hospital" />
                  }
                  name="hospital_id"
                  control={control}
                  url={endpoints.hospitals}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            <Grid item sm={3}>
              <SelectField
                label={
                  <FormattedMessage
                    id="dispensary"
                    defaultMessage="Dispensary"
                  />
                }
                name="dispensary_id"
                control={control}
                url={endpoints.dispensaries}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                  hospital_id: hospital_id || null,
                })}
                disabled={!hospital_id}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
              />
            </Grid>

            <Grid item sm={3}>
              <SelectField
                label={<FormattedMessage id="user" defaultMessage="User" />}
                name="user_id"
                control={control}
                url={endpoints.users}
                disabled={!hospital_id}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                  hospital_id: hospital_id || null,
                  status: "active",
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                {...register("reference")}
                label={
                  <FormattedMessage id="reference" defaultMessage="Reference" />
                }
                error={errors.reference}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                type="date"
                {...register("date")}
                label={<FormattedMessage id="date" defaultMessage="Date" />}
                error={errors.date}
              />
            </Grid>

            <Grid item sm={3}>
              <SelectField
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                name="status"
                control={control}
                options={[
                  { label: "Pending", value: "pending" },
                  { label: "Ordered", value: "ordered" },
                  { label: "Delivered", value: "delivered" },
                ]}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                multiline
                {...register("description")}
                label={
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                }
                error={errors.description}
              />
            </Grid>

            <Grid item sm={12}>
              <Products
                hospital_id={hospital_id}
                products={products}
                setProducts={(data) => setValue("products", data)}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Products = ({ hospital_id, products = [], setProducts }) => {
  const { control, setValue } = useForm();
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h5">Products</Typography>
      </Grid>
      <Grid item sm={6}>
        <SelectField
          label={<FormattedMessage id="drug" defaultMessage="Drug" />}
          name="drug"
          control={control}
          url={endpoints.drugs}
          getQuery={(inputValue, selected) => ({
            ...(inputValue && { name: inputValue }),
            ...(selected && { id: selected }),
            hospital_id: hospital_id || null,
          })}
          handleData={(data) => ({
            label: `${data.brand_name} - ${data.stock}`,
            value: data.id,
            price: data.price,
          })}
          disabled={!hospital_id}
          onChange={(v) => {
            setValue("drug", null);
            setProducts([
              ...products,
              {
                id: `${v.value}_${products.length}`,
                name: v.label,
                qty: 1,
                price: v.price,
                discount: 0,
              },
            ]);
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <Datagrid
          columns={[
            {
              headerName: <FormattedMessage id="name" defaultMessage="Name" />,
              field: "name",
            },
            {
              headerName: (
                <FormattedMessage id="quantity" defaultMessage="Quantity" />
              ),
              field: "qty",
              renderCell: ({ row }) => (
                <InputCell
                  row={row}
                  field="qty"
                  products={products}
                  setProducts={setProducts}
                />
              ),
            },
            {
              headerName: (
                <FormattedMessage id="price" defaultMessage="Price" />
              ),
              field: "price",
              renderCell: ({ row }) => (
                <InputCell
                  row={row}
                  field="price"
                  products={products}
                  setProducts={setProducts}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₦</InputAdornment>
                    ),
                  }}
                />
              ),
            },
            {
              headerName: (
                <FormattedMessage id="discount" defaultMessage="Discount" />
              ),
              field: "discount",
              renderCell: ({ row }) => (
                <InputCell
                  row={row}
                  field="discount"
                  products={products}
                  setProducts={setProducts}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              ),
            },
            {
              headerName: (
                <FormattedMessage id="total" defaultMessage="Total" />
              ),
              field: "total",
              renderCell: ({ row }) =>
                "₦" +
                (
                  row.qty * row.price -
                  (row.qty * row.price).percent(+row.discount || 0)
                ).toLocaleString(),
            },
            {
              headerName: (
                <FormattedMessage id="action" defaultMessage="Action" />
              ),
              field: "action",
              renderCell: ({ row }) => (
                <IconButton
                  aria-label={
                    <FormattedMessage id="remove" defaultMessage="Remove" />
                  }
                  onClick={() =>
                    setProducts(products.filter((item) => item.id !== row.id))
                  }
                >
                  <DeleteIcon />
                </IconButton>
              ),
            },
          ]}
          hideFooterPagination={true}
          rows={products}
        />
        <Box>
          <Typography variant="h4" align="right">
            Total:{" "}
            {products
              .reduce(
                (p, c) =>
                  p +
                  (c.price - (c.discount ? (c.price / 100) * c.discount : 0)) *
                    c.qty,
                0
              )
              .toLocaleString()}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const InputCell = ({
  row,
  field,
  min,
  setProducts,
  products = [],
  ...rest
}) => {
  return (
    <TextField
      value={(+row[field] || 0).toString()}
      type="number"
      min={1}
      variant="outlined"
      onChange={(e) => {
        if (+e.target.value >= (min || 0)) {
          setProducts(
            products.map((item) =>
              item.id === row.id
                ? { ...item, [field]: +e.target.value || 0 }
                : item
            )
          );
        }
      }}
      {...rest}
    />
  );
};

export default Form;
