import { Button, Grid, makeStyles } from "@material-ui/core";
import { SelectField } from "components";
import { useForm } from "react-hook-form";
import { useYup } from "hooks";
import { endpoints } from "appConfigs";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const schema = yup.object({
  departments: yup
    .array()
    .of(yup.string().required("Field is required"))
    .min(0, "Please select at least one department"),
  status: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginRight: "0",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ filters, setFilters }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(schema) });
  return (
    <Grid item className="mb-1">
      <form
        autoComplete={"off"}
        onSubmit={handleSubmit(async (values) => {
          const query = {};
          Object.entries(values).forEach(([key, value]) => {
            if (value) {
              query[key] = value;
            }
          });
          setFilters(query);
        })}
      >
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <SelectField
              label={
                <FormattedMessage
                  id="billingAccount"
                  defaultMessage="Billing Account"
                />
              }
              name="billing_account_id"
              control={control}
              multiple
              url={endpoints.users}
              getQuery={(inputValue, selected) => ({
                ...(inputValue && { name: inputValue }),
                ...(selected && { id: selected }),
              })}
              handleData={(data) => ({
                label: data.name,
                value: data.id,
              })}
            />
          </Grid>

          <Grid item sm={3}>
            <SelectField
              label={<FormattedMessage id="members" defaultMessage="Members" />}
              name="users"
              control={control}
              multiple
              url={endpoints.users}
              getQuery={(inputValue, selected) => ({
                ...(inputValue && { name: inputValue }),
                ...(selected && { id: selected }),
              })}
              handleData={(data) => ({
                label: data.name,
                value: data.id,
              })}
            />
          </Grid>

          <Grid item sm={3}>
            <SelectField
              label={
                <FormattedMessage
                  id="paymentStatus"
                  defaultMessage="Payment Status"
                />
              }
              name="payment_status"
              control={control}
              multiple
              options={[
                { label: "Pending", value: "pending" },
                { label: "Paid", value: "paid" },
                { label: "Expired", value: "expired" },
              ]}
            />
          </Grid>

          <Grid item sm={3}>
            <SelectField
              label={<FormattedMessage id="status" defaultMessage="Status" />}
              name="status"
              control={control}
              options={[
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
              ]}
            />
          </Grid>

          <Grid item sm={3} className={s.btns}>
            <Button type="submit" variant="contained" color="primary">
              <FormattedMessage id="search" defaultMessage="Search" />
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                setFilters({});
                reset();
              }}
            >
              <FormattedMessage id="clear" defaultMessage="Clear" />
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default Form;
