import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const ActionIcon = ({
  icon,
  to = "",
  onClick = () => {},
  tpTitle = "",
  className,
  confirmation,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const { push } = useHistory();
  const clickHandler = () => {
    if (to) {
      return push(to);
    }
    return onClick();
  };

  const content = (
    <>
      <IconButton
        disabled={disabled}
        role="button"
        className={`rct-link-btn ${className || ""}`}
        onClick={confirmation ? () => setOpen(true) : clickHandler}
      >
        {icon}
      </IconButton>
      {confirmation && (
        <Dialog open={open}>
          <DialogTitle>
            {confirmation.title || (
              <FormattedMessage
                id="confirmation"
                defaultMessage="Confirmation"
              />
            )}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2">{confirmation.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setOpen(false)}>
              <FormattedMessage id="no" defaultMessage="No" />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                confirmation.action();
                setOpen(false);
              }}
            >
              <FormattedMessage id="yes" defaultMessage="Yes" />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
  if (tpTitle) {
    return <Tooltip title={tpTitle}>{content}</Tooltip>;
  }

  return content;
};

ActionIcon.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
};

export default ActionIcon;
