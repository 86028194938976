import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { TextField, SelectField, FileInput } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const healthGroupSchema = yup.object({
  name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  description: yup.string(),
  start_date: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  end_date: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  departments: yup
    .array()
    .of(yup.string())
    .min(1)
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  price: yup.number().required().min(0),
  status: yup.string(),
  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  max_member: yup
    .number()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .min(
      1,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 1 }}
      />
    ),
  number_of_sessions: yup
    .number()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .min(
      1,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 1 }}
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, authUser, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(healthGroupSchema) });
  const {
    post: addDoc,
    put: updateDoc,
    loading,
  } = useFetch(endpoints.healthGroups + `/${edit?.id || ""}`);

  const hospital_id = watch("hospital_id");

  useEffect(() => {
    if (edit?.hospital?.id !== hospital_id) {
      setValue("departments", []);
      setValue("doctors", []);
      setValue("nurses", []);
      setValue("pharmacists", []);
    }
  }, [hospital_id]);

  useEffect(() => {
    reset({
      ...edit,
      hospital_id:
        authUser.user_type === "hospital"
          ? authUser.id
          : edit?.hospital?.id || "",
      departments: edit?.departments?.map((item) => item.id) || [],
      doctors: edit?.doctors?.map((item) => item.id) || [],
      nurses: edit?.nurses?.map((item) => item.id) || [],
      pharmacists: edit?.pharmacists?.map((item) => item.id) || [],
      photo: edit?.photo ? [edit?.photo] : [],
    });
  }, [edit]);
  return (
    <Dialog open={open}>
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage
            id="editHealthGroup"
            defaultMessage="Edit Health Group"
          />
        ) : (
          <FormattedMessage
            id="addHealthGroup"
            defaultMessage="Add Health Group"
          />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            const formData = new FormData();
            Object.entries({
              ...values,
              latitude: position?.latitude,
              longitude: position?.longitude,
            }).forEach(([key, value]) => {
              if (key === "photo") {
                if (value.length) {
                  formData.append(key, value[0]);
                } else {
                  formData.append(key, "");
                }
              } else if (Array.isArray(value)) {
                formData.append(key, JSON.stringify(value));
              } else {
                formData.append(key, value);
              }
            });
            (edit?.id ? updateDoc : addDoc)(formData)
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <FileInput
                thumbnail
                control={control}
                label={<FormattedMessage id="photo" defaultMessage="Photo" />}
                name="photo"
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("name")}
                label={<FormattedMessage id="name" defaultMessage="Name" />}
                error={errors.name}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("description")}
                label={
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                }
                error={errors.description}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                fullWidth
                type="date"
                {...register("start_date")}
                label={
                  <FormattedMessage
                    id="start_date"
                    defaultMessage="Start Date"
                  />
                }
                error={errors.start_date}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                fullWidth
                type="date"
                {...register("end_date")}
                label={
                  <FormattedMessage id="endDate" defaultMessage="End Date" />
                }
                error={errors.end_date}
              />
            </Grid>

            <Grid item sm={4}>
              <TextField
                fullWidth
                type="number"
                {...register("price")}
                label={<FormattedMessage id="price" defaultMessage="Price" />}
                error={errors.price}
              />
            </Grid>

            <Grid item sm={4}>
              <TextField
                fullWidth
                type="number"
                {...register("max_member")}
                label={
                  <FormattedMessage
                    id="maxMember"
                    defaultMessage="Max Member"
                  />
                }
                error={errors.max_member}
              />
            </Grid>

            <Grid item sm={4}>
              <TextField
                fullWidth
                type="number"
                {...register("number_of_sessions")}
                label={
                  <FormattedMessage
                    id="numberOfSessions"
                    defaultMessage="Number of Sessions"
                  />
                }
                error={errors.number_of_sessions}
              />
            </Grid>

            {authUser.user_type === "admin" && (
              <Grid item sm={12}>
                <SelectField
                  readOnly={edit?.id}
                  disabled={edit?.id}
                  label={
                    <FormattedMessage id="hospital" defaultMessage="Hospital" />
                  }
                  name="hospital_id"
                  control={control}
                  url={endpoints.hospitals}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            {hospital_id && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage
                      id="departments"
                      defaultMessage="Departments"
                    />
                  }
                  name="departments"
                  control={control}
                  multiple
                  url={endpoints.departments}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                    hospital_id,
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            {hospital_id && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage id="doctors" defaultMessage="Doctors" />
                  }
                  name="doctors"
                  control={control}
                  multiple
                  url={endpoints.doctors}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected?.length && { id: selected }),
                    hospital_id,
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            {hospital_id && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage id="nurses" defaultMessage="Nurses" />
                  }
                  name="nurses"
                  control={control}
                  multiple
                  url={endpoints.nurses}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected?.length && { id: selected }),
                    hospital_id,
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            {hospital_id && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage
                      id="pharmacists"
                      defaultMessage="Pharmacists"
                    />
                  }
                  name="pharmacists"
                  control={control}
                  multiple
                  url={endpoints.pharmacists}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected?.length && { id: selected }),
                    hospital_id,
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            <Grid item sm={12}>
              <SelectField
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                name="status"
                control={control}
                getOptionSelected={(option, value) => option.value === value}
                options={[
                  { label: "Active", value: "active" },
                  { label: "Inactive", value: "inactive" },
                  { label: "Expired", value: "expired" },
                ]}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
