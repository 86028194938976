import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { TextField, PasswordField, SelectField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import { Datagrid } from "../../components";
import DeleteIcon from "@material-ui/icons/Delete";

const addSchema = yup.object({
  name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  phone: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  email: yup
    .string()
    .email(
      <FormattedMessage
        id="validationEmail"
        defaultMessage="Enter a valid email"
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),

  pin_number: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  confirm_pin_number: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .oneOf(
      [yup.ref("pin_number")],
      <FormattedMessage
        id="validationPinConfirm"
        defaultMessage="Pin number did not match"
      />
    ),

  password: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  confirm_password: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .oneOf(
      [yup.ref("password")],
      <FormattedMessage
        id="validationConfirmPassword"
        defaultMessage="Password did not match"
      />
    ),

  hospitals: yup
    .array()
    .of(yup.string())
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});
const updateSchema = yup.object({
  name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  phone: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  email: yup
    .string()
    .email(
      <FormattedMessage
        id="validationEmail"
        defaultMessage="Enter a valid email"
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),

  pin_number: yup.string(),
  confirm_pin_number: yup
    .string()
    .oneOf(
      [yup.ref("pin_number")],
      <FormattedMessage
        id="validationPinConfirm"
        defaultMessage="Pin number did not match"
      />
    ),

  password: yup.string(),
  confirm_password: yup
    .string()
    .oneOf(
      [yup.ref("password")],
      <FormattedMessage
        id="validationConfirmPassword"
        defaultMessage="Password did not match"
      />
    ),

  // hospitals: yup
  //   .array()
  //   .of(yup.string())
  //   .required(
  //     <FormattedMessage
  //       id="fieldIsRequired"
  //       defaultMessage="Field is required"
  //     />
  //   ),
  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, authUser, onSuccess }) => {
  const s = useStyles();
  const [hospitals, setHospitals] = useState([]);
  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({ resolver: useYup(edit?.id ? updateSchema : addSchema) });
  const {
    post: addUser,
    put: updateUser,
    loading,
  } = useFetch(endpoints.stakeholders + `/${edit?.id || ""}`);

  useEffect(() => {
    const newPass = Math.random().toString(36).substr(2, 8);
    const newPin = Math.random().toString().substr(2, 4);
    reset({
      ...edit,
      ...(!edit?.id && {
        password: newPass,
        confirm_password: newPass,
        pin_number: newPin,
        confirm_pin_number: newPin,
      }),
      hospital: "",
    });
    setHospitals(
      edit?.hospitals?.map((item) => ({
        id: item.id,
        name: item.name,
        commission: item.commission || 0,
      })) || []
    );
  }, [edit]);
  return (
    <Dialog open={open}>
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage
            id="editStakeholder"
            defaultMessage="Edit Stakeholder"
          />
        ) : (
          <FormattedMessage
            id="addStakeholder"
            defaultMessage="Add Stakeholder"
          />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            if (hospitals.some((item) => item.commission < 1)) {
              return NotificationManager.error(
                "Commission can't be less than 1"
              );
            }
            if (hospitals.some((item) => item.commission > 100)) {
              return NotificationManager.error(
                "Commission can't be more than 100"
              );
            }
            if (hospitals.reduce((p, c) => p + c.commission, 0) > 100) {
              return NotificationManager.error(
                "Total commission can't be more than 100"
              );
            }
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            (edit?.id ? updateUser : addUser)({
              ...values,
              hospitals,
              password: values.password || undefined,
              pin_number: values.pin_number || undefined,
              age: undefined,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("name")}
                label={<FormattedMessage id="name" defaultMessage="Name" />}
                error={errors.name}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("phone")}
                label={<FormattedMessage id="phone" defaultMessage="Phone" />}
                error={errors.phone}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("email")}
                label={<FormattedMessage id="email" defaultMessage="Email" />}
                error={errors.email}
              />
            </Grid>

            <Grid item sm={6}>
              <PasswordField
                fullWidth
                {...register("password")}
                label={
                  <FormattedMessage id="password" defaultMessage="Password" />
                }
                error={errors.password}
              />
            </Grid>

            <Grid item sm={6}>
              <PasswordField
                fullWidth
                {...register("confirm_password")}
                label={
                  <FormattedMessage
                    id="confirmPassword"
                    defaultMessage="Confirm Password"
                  />
                }
                error={errors.confirm_password}
              />
            </Grid>

            <Grid item sm={6}>
              <PasswordField
                fullWidth
                {...register("pin_number")}
                label={
                  <FormattedMessage
                    id="pinNumber"
                    defaultMessage="Pin Number"
                  />
                }
                error={errors.pin_number}
              />
            </Grid>

            <Grid item sm={6}>
              <PasswordField
                fullWidth
                {...register("confirm_pin_number")}
                label={
                  <FormattedMessage
                    id="confirmPinNumber"
                    defaultMessage="Confirm Pin Number"
                  />
                }
                error={errors.confirm_pin_number}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                type="date"
                fullWidth
                {...register("dob")}
                label={
                  <FormattedMessage
                    id="dateOfBirth"
                    defaultMessage="Date of Birth"
                  />
                }
                error={errors.dob}
              />
            </Grid>

            {authUser.user_type === "admin" && (
              <>
                <Grid item sm={12}>
                  <SelectField
                    label={
                      <FormattedMessage
                        id="hospital"
                        defaultMessage="Hospital"
                      />
                    }
                    name="hospital"
                    control={control}
                    url={endpoints.hospitals}
                    getQuery={(inputValue, selected) => ({
                      ...(inputValue && { name: inputValue }),
                      ...(selected && { id: selected }),
                    })}
                    handleData={(data) => ({
                      label: data.name,
                      value: data.id,
                    })}
                    onChange={(e) => {
                      if (!hospitals.some((item) => item.id === e.value)) {
                        setHospitals((prev) => [
                          ...prev,
                          { id: e.value, name: e.label, commission: 0 },
                        ]);
                      }
                      setValue("hospital", "");
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Datagrid
                    columns={[
                      {
                        field: "name",
                        headerName: "Hospital",
                      },
                      {
                        field: "commission",
                        headerName: "Commission",
                        renderCell: ({ row }) => (
                          <TextField
                            value={(+row.commission || 0).toString()}
                            type="number"
                            variant="outlined"
                            onChange={(e) => {
                              if (+e.target.value >= 0) {
                                setHospitals((prev) =>
                                  prev.map((item) =>
                                    item.id === row.id
                                      ? {
                                          ...item,
                                          commission: +e.target.value || 0,
                                        }
                                      : item
                                  )
                                );
                              }
                            }}
                            InputProps={{
                              min: 1,
                              max: 100,
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        ),
                      },
                      {
                        headerName: (
                          <FormattedMessage
                            id="action"
                            defaultMessage="Action"
                          />
                        ),
                        field: "action",
                        renderCell: ({ row }) => (
                          <IconButton
                            aria-label={
                              <FormattedMessage
                                id="remove"
                                defaultMessage="Remove"
                              />
                            }
                            onClick={() =>
                              setHospitals((prev) =>
                                prev.filter((item) => item.id !== row.id)
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        ),
                      },
                    ]}
                    actions={false}
                    rows={hospitals}
                    hideFooterPagination={true}
                  />
                </Grid>
              </>
            )}

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("domain")}
                label={<FormattedMessage id="domain" defaultMessage="Domain" />}
                error={errors.domain}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                name="status"
                control={control}
                options={[
                  { label: "Active", value: "active" },
                  { label: "Inactive", value: "inactive" },
                ]}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
