import { useState } from "react";
import {
  Wrapper,
  Datagrid,
  DeleteActionIcon,
  Permission,
} from "../../components";

import { Typography, IconButton, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import format from "date-fns/format";
import Form from "./Form";
import Filters from "./Filters";

import { useFetch } from "hooks";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
  status: {
    textTransform: "capitalize",
  },
}));

const ApiKeys = () => {
  const classes = useStyles();
  const [edit, setEdit] = useState(null);
  const [filters, setFilters] = useState({});
  const { remove: deleteItem, loading: deleting } = useFetch(endpoints.apiKeys);
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage id="apiKeys" defaultMessage="API Keys" />
                </Typography>
                <Permission permission="add_api_key">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setEdit(true)}
                  >
                    <FormattedMessage
                      id="addApiKey"
                      defaultMessage="Add API Key"
                    />
                  </Button>
                </Permission>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters filters={filters} setFilters={setFilters} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Datagrid
                  url={endpoints.admin.apiKeys}
                  filters={filters}
                  columns={[
                    {
                      headerName: (
                        <FormattedMessage
                          id="createdAt"
                          defaultMessage="Created At"
                        />
                      ),
                      field: "createdAt",
                      renderCell: ({ row }) =>
                        format(new Date(row.createdAt), "dd MMM yyyy"),
                    },
                    {
                      headerName: (
                        <FormattedMessage id="name" defaultMessage="Name" />
                      ),
                      field: "name",
                    },
                    {
                      headerName: (
                        <FormattedMessage id="status" defaultMessage="Status" />
                      ),
                      field: "status",
                      cellClassName: classes.status,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="action" defaultMessage="Action" />
                      ),
                      field: "action",
                      renderCell: ({ row }) => (
                        <>
                          <Permission permission="edit_api_keys">
                            <IconButton
                              aria-label={
                                <FormattedMessage
                                  id="editApiKey"
                                  defaultMessage="Edit API Key"
                                />
                              }
                              onClick={() => setEdit(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Permission>
                          <Permission permission="remove_api_keys">
                            <DeleteActionIcon
                              aria-label={
                                <FormattedMessage
                                  id="deleteApiKey"
                                  defaultMessage="Delete API Key"
                                />
                              }
                              title={
                                <FormattedMessage
                                  id="deleteApiKey"
                                  defaultMessage="Delete API Key"
                                />
                              }
                              message={
                                <FormattedMessage
                                  id="deleteConfirmation_apiKey"
                                  defaultMessage="Are you sure want to remove this API key?"
                                />
                              }
                              disabled={deleting}
                              deleteRecord={() => {
                                deleteItem({ ids: [row.id] })
                                  .then(({ data }) => {
                                    if (data?.success) {
                                      setFilters((prev) => ({ ...prev }));
                                      return NotificationManager.success(
                                        data.message
                                      );
                                    }
                                    NotificationManager.error(data.message);
                                  })
                                  .catch((err) =>
                                    NotificationManager.error(err.message)
                                  );
                              }}
                            />
                          </Permission>
                        </>
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Form
          open={!!edit}
          edit={edit}
          setEdit={setEdit}
          onSuccess={(newSubPackage) => {
            setFilters((prev) => ({ ...prev }));
            setEdit(null);
          }}
        />
      </Wrapper>
    </>
  );
};

export default ApiKeys;
