import { useEffect } from "react";
import { Button, Grid, InputAdornment, makeStyles } from "@material-ui/core";
import { TextField, SelectField, Permission } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { useAppState } from "components/AppProvider/AppProvider";
import { FormattedMessage } from "react-intl";

const settingSchema = yup.object({
  service_fee: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  service_fee_type: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ settings, rawSettings, onSuccess }) => {
  const { authUser } = useAppState();
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: useYup(settingSchema),
    defaultValues: {
      service_fee: "",
      appointment_hospital_fee: "",
    },
  });

  const { put: updateSettings, loading } = useFetch(endpoints.settings);

  const hospital_id = watch("hospital_id");

  useEffect(() => {
    reset({ ...settings, hospital_id: hospital_id || null });
  }, [settings, hospital_id]);

  useEffect(() => {
    onSuccess(hospital_id);
  }, [hospital_id]);
  return (
    <form
      autoComplete={"off"}
      onSubmit={handleSubmit(async (values) => {
        const { position, error } = await getUserLocation();
        if (error) {
          return NotificationManager.error(error);
        }
        const settings = [];
        Object.entries(values).forEach(([key, value]) => {
          if (key === "hospital_id") {
            return;
          }
          if (value) {
            const existingSetting = rawSettings.find((setting) => {
              if (authUser.user_type === "hospital") {
                return (
                  setting.key === key &&
                  setting.hospital_id === authUser.hospital_id
                );
              }
              if (values.hospital_id) {
                return (
                  setting.key === key &&
                  setting.hospital_id === values.hospital_id
                );
              }
              return setting.key === key;
            });
            settings.push({
              id: existingSetting?.id || undefined,
              key,
              value: value?.value || value,
              hospital_id:
                authUser.user_type === "hospital"
                  ? authUser.id
                  : values.hospital_id || null,
            });
          }
        });
        updateSettings({
          settings,
          latitude: position?.latitude,
          longitude: position?.longitude,
        })
          .then(({ data }) => {
            if (data.success) {
              NotificationManager.success(data.message);
              onSuccess(values.hospital_id);
            } else {
              NotificationManager.error(data.message);
            }
          })
          .catch((err) => {
            NotificationManager.error(err.message);
          });
      })}
    >
      <Grid container spacing={3}>
        {authUser.user_type === "admin" && (
          <Grid item sm={12}>
            <SelectField
              label={
                <FormattedMessage id="hospital" defaultMessage="Hospital" />
              }
              name="hospital_id"
              control={control}
              url={endpoints.hospitals}
              getQuery={(inputValue, selected) => ({
                ...(inputValue && { name: inputValue }),
                ...(selected && { id: selected }),
              })}
              handleData={(data) => ({
                label: data.name,
                value: data.id,
              })}
            />
          </Grid>
        )}

        <Grid item sm={8}>
          <TextField
            fullWidth
            {...register("service_fee")}
            label={
              <FormattedMessage
                id="serviceFeeHospitalFee"
                defaultMessage="Service Fee (Hospital Fee)"
              />
            }
            error={errors.service_fee}
          />
        </Grid>
        <Grid item sm={4}>
          <SelectField
            label={<FormattedMessage id="feeType" defaultMessage="Fee Type" />}
            name="service_fee_type"
            control={control}
            options={[
              { label: "Flat", value: "flat" },
              { label: "Percent", value: "percent" },
            ]}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            fullWidth
            {...register("appointment_hospital_fee")}
            label={
              <FormattedMessage
                id="apptHospitalFee"
                defaultMessage="Appointment Hospital Fee"
              />
            }
            error={errors.appointment_hospital_fee}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Percent</InputAdornment>
              ),
            }}
          />
        </Grid>

        {authUser.user_type === "admin" && (
          <>
            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage
                    id="audioVideoServiceProvider"
                    defaultMessage="Audio & video call service provider"
                  />
                }
                name="audio_video_call_service_provider"
                control={control}
                options={[
                  { label: "Agora", value: "agora" },
                  { label: "ZeGo Cloud", value: "zego" },
                ]}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage
                    id="apptBookingServiceProvider"
                    defaultMessage="Appointment Booking Service Provider"
                  />
                }
                name="appointment_booking_service_provider"
                control={control}
                url={endpoints.serviceProviders}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage
                    id="subPurchaseServiceProvider"
                    defaultMessage="Subscription Purchase Service Provider"
                  />
                }
                name="subscription_perchase_service_provider"
                control={control}
                url={endpoints.serviceProviders}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage
                    id="healthGroupSubPurchaseServiceProvider"
                    defaultMessage="Health Group Subscription Purchase Service Provider"
                  />
                }
                name="health_group_subscription_perchase_service_provider"
                control={control}
                url={endpoints.serviceProviders}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage
                    id="fundWalletServiceProvider"
                    defaultMessage="Fund Wallet Service Provider"
                  />
                }
                name="fund_wallet_service_provider"
                control={control}
                url={endpoints.serviceProviders}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage
                    id="bankTransferServiceProvider"
                    defaultMessage="Bank Transfer Service Provider"
                  />
                }
                name="bank_transfer_service_provider"
                control={control}
                url={endpoints.serviceProviders}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                type="number"
                {...register("free_appointments_for_new_users")}
                label={
                  <FormattedMessage
                    id="freeAppointmentsForNewUsers"
                    defaultMessage="Free Appointments for new Users"
                  />
                }
                error={errors.free_appointments_for_new_users}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                type="number"
                {...register("referral_reward")}
                label={
                  <FormattedMessage
                    id="referralReward"
                    defaultMessage="Referral Reward"
                  />
                }
                error={errors.referral_reward}
              />
            </Grid>
          </>
        )}

        <Grid item sm={12} className={s.btns}>
          <Permission permission="update_general_settings">
            <Button
              type="submit"
              disabled={loading}
              variant="contained"
              color="primary"
            >
              <FormattedMessage
                id="saveChanges"
                defaultMessage="Save Changes"
              />
            </Button>
          </Permission>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
