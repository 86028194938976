import { useState, useEffect } from "react";
import { Wrapper, Datagrid, Permission } from "components";

import { Typography, IconButton, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import Form from "./Form";
import Filters from "./Filters";
import { useAppState } from "components/AppProvider/AppProvider";
import { FormattedMessage } from "react-intl";
import { useFetch } from "hooks";
import { NotificationManager } from "react-notifications";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
  status: {
    textTransform: "capitalize",
  },
}));

const HealthGroups = () => {
  const { authUser } = useAppState();
  const classes = useStyles();
  const [edit, setEdit] = useState(null);
  const [filters, setFilters] = useState({});
  const [groups, setGroups] = useState([]);

  const { get: getPermissions, loading: loadingPermissions } = useFetch(
    endpoints.permissions
  );
  useEffect(() => {
    getPermissions()
      .then(({ data }) => {
        if (data.success) {
          setGroups(data.data);
        }
      })
      .catch((err) => NotificationManager.error(err.message));
  }, []);
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage id="roles" defaultMessage="Roles" />
                </Typography>
                <Permission permission="add_role">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setEdit(true)}
                  >
                    <FormattedMessage id="addRole" defaultMessage="Add Role" />
                  </Button>
                </Permission>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters
                      filters={filters}
                      setFilters={setFilters}
                      authUser={authUser}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Datagrid
                  url={endpoints.roles}
                  filters={filters}
                  columns={[
                    {
                      headerName: (
                        <FormattedMessage id="label" defaultMessage="Label" />
                      ),
                      field: "label",
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="hospital"
                          defaultMessage="Hospital"
                        />
                      ),
                      field: "hospital.name",
                      renderCell: ({ row }) => row.hospital?.name,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="action" defaultMessage="Action" />
                      ),
                      field: "action",
                      renderCell: ({ row }) => (
                        <>
                          <Permission permission="update_roles">
                            <IconButton
                              aria-label={
                                <FormattedMessage
                                  id="editRole"
                                  defaultMessage="Edit Role"
                                />
                              }
                              onClick={() => setEdit(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Permission>
                          {/* <DeleteActionIcon to={`${props.pageUrl}/add`} /> */}
                        </>
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Form
          authUser={authUser}
          open={!!edit}
          edit={edit}
          setEdit={setEdit}
          groups={groups}
          onSuccess={(newSubPackage) => {
            setFilters((prev) => ({ ...prev }));
            setEdit(null);
          }}
        />
      </Wrapper>
    </>
  );
};

export default HealthGroups;
