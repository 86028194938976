import { useRef } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
  DialogActions,
} from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import format from "date-fns/format";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "210mm",
    height: "297mm",
  },
  innerWrapper: {
    padding: "1rem",
    display: "grid",
    height: "100%",
    // gridAutoRows: "min-content",
    gridTemplateRows: "min-content min-content min-content 1fr min-content",
  },
  rxLogo: {
    height: "3rem",
  },
  sidebar: {
    borderRight: "1px dotted grey",
    minHeight: "50rem",
  },
  patient: {
    borderTop: "1px solid grey",
    borderBottom: "1px solid grey",
    // margin: "-1rem 0",
  },
  drugs: {
    width: "max-content",
    maxWidth: "100%",
    margin: "auto",
  },
  footer: {
    marginTop: "-12px",
    borderTop: "1px solid grey",
    paddingTop: "2rem",
  },
}));

const Prescription = ({ prescription, setPrescription }) => {
  const s = useStyles();

  const printRef = useRef();
  const handlePrint = useReactToPrint({ content: () => printRef.current });
  if (!prescription) {
    return null;
  }
  return (
    <Dialog open={!!prescription} maxWidth="xl">
      {/* <DialogTitle>Prescription</DialogTitle> */}
      <DialogContent>
        <Paper className={s.wrapper}>
          <Grid container spacing={3} className={s.innerWrapper} ref={printRef}>
            <Grid item sm={12}>
              <Grid container spacing={2} align="center">
                {/* <Grid item sm={1}>
                <Avatar src={prescription.hospital?.photo} sizes="large" />
              </Grid> */}
                <Grid item sm={12}>
                  <Typography variant="h4">
                    {prescription.hospital?.name}
                  </Typography>
                  <Typography variant="body1">
                    {prescription.hospital.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12}>
              <Grid container spacing={2} justify="center">
                <Grid item sm={12}>
                  <Typography variant="h6">
                    Dr. {prescription.doctor?.name}
                  </Typography>
                  <Typography variant="body1">
                    {prescription.doctor.certification}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12} className={s.patient}>
              <Grid container spacing={2} justify="center">
                <Grid item sm={5}>
                  <Typography variant="body2">
                    Name: {prescription.user?.name}
                  </Typography>
                </Grid>
                <Grid item sm={2}>
                  <Typography variant="body2">
                    Age: {prescription.user?.age}
                  </Typography>
                </Grid>
                <Grid item sm={2}>
                  <Typography variant="body2">
                    Gender: {prescription.user?.gender}
                  </Typography>
                </Grid>
                <Grid item sm={3}>
                  <Typography variant="body2">
                    Date: {format(new Date(prescription.date), "dd MMM yyyy")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container sm={12} spacing={3}>
              <Grid item sm={4} className={s.sidebar}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <img className={s.rxLogo} src="/static/images/rx.svg" />
                  </Grid>

                  {prescription.history && (
                    <Grid item sm={12}>
                      <Typography variant="h6">History</Typography>
                      <Typography variant="body1">
                        {prescription.history}
                      </Typography>
                    </Grid>
                  )}

                  {prescription.advice && (
                    <Grid item sm={12}>
                      <Typography variant="h6">Advice</Typography>
                      <Typography variant="body1">
                        {prescription.advice}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item sm={8}>
                <Grid container spacing={2} className={s.drugs}>
                  {prescription.drugs.map((drug) => (
                    <Grid item sm={12} key={drug.brand_name} className={s.drug}>
                      <Typography variant="h6">
                        <small>{drug.dosage_form}:</small> {drug.brand_name}{" "}
                        {drug.api_strength && (
                          <small>({drug.api_strength}mg)</small>
                        )}
                      </Typography>
                      <Typography variant="body1">
                        {drug.dose_interval} •{" "}
                        {drug.administration && `${drug.administration} • `}{" "}
                        {drug.dosage && `${drug.dosage} • `}
                        {drug.duration} days{drug.note && ` • ${drug.note}`}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12}>
              <Grid container justify="flex-end" className={s.footer}>
                <Grid item>
                  <Typography variant="body1" align="right">
                    Doctors Signature
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="contained"
          onClick={() => setPrescription(null)}
        >
          Cancel
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handlePrint}
        >
          Print
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Prescription;
