import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { TextField, SelectField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import format from "date-fns/format";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const addSchema = yup.object({
  user_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  doctor_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  user_note: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    ),
});
const updateSchema = yup.object({
  user_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  doctor_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  user_note: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, authUser, onSuccess }) => {
  const s = useStyles();
  const [subs, setSubs] = useState([]);
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(edit?.id ? updateSchema : addSchema) });
  const {
    post: createAppointment,
    put: updateAppointment,
    loading,
  } = useFetch(endpoints.appointments + `/${edit?.id || ""}`);

  useEffect(() => {
    reset({
      ...edit,
      doctor_id: edit?.doctor?.id || "",
      user_id: edit?.user?.id || "",
      hospital_id:
        authUser.user_type === "hospital"
          ? authUser.id
          : edit?.hospital?.id || "",
      date_time: edit
        ? format(new Date(edit.date_time), "yyyy-MM-dd hh:mm")
        : "",
      status: edit?.status || "",
    });
  }, [edit]);
  const user = watch("user_id");
  const paymentMethod = watch("payment_method");

  const { get: getSubs, loading: loadingSubs } = useFetch(
    endpoints.subscriptions
  );

  useEffect(() => {
    if (user && paymentMethod === "subscription") {
      getSubs({
        query: {
          status: "active",
          users: user,
        },
      }).then(({ data }) => {
        if (data.success) {
          setSubs(
            data.data.map((data) => ({
              label: `${data.sub_package?.name} (${data.remaining_appointments} remaining)`,
              value: data.id,
            }))
          );
        }
      });
    }
  }, [user, paymentMethod]);
  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedMessage
          id="editAppointment"
          defaultMessage="Edit Appointment"
        />
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            (edit?.id ? updateAppointment : createAppointment)({
              ...values,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage id="patient" defaultMessage="Patient" />
                }
                name="user_id"
                control={control}
                url={endpoints.users}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={<FormattedMessage id="doctor" defaultMessage="Doctor" />}
                name="doctor_id"
                control={control}
                url={endpoints.doctors}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
              />
            </Grid>

            {authUser.user_type === "admin" && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage id="hospital" defaultMessage="Hospital" />
                  }
                  name="hospital_id"
                  control={control}
                  url={endpoints.hospitals}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage
                    id="interaction"
                    defaultMessage="Interaction"
                  />
                }
                name="interaction"
                multiple
                control={control}
                options={[
                  { label: "Chat", value: "chat" },
                  { label: "Audio Call", value: "audio" },
                  { label: "Video Call", value: "video" },
                ]}
              />
            </Grid>

            {
              // <Grid item sm={12}>
              //   <TextField
              //     fullWidth
              //     {...register("email")}
              //     label="Email"
              //     error={errors.email}
              //   />
              // </Grid>
            }

            <Grid item sm={12}>
              <TextField
                type="datetime-local"
                fullWidth
                {...register("date_time")}
                label={
                  <FormattedMessage
                    id="dateTime"
                    defaultMessage="Date & Time"
                  />
                }
                error={errors.date_time}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage
                    id="paymentMethod"
                    defaultMessage="Payment Method"
                  />
                }
                name="payment_method"
                control={control}
                options={[
                  { label: "Pay Now", value: "payNow" },
                  { label: "Subscription", value: "subscription" },
                ]}
              />
            </Grid>

            {paymentMethod === "subscription" && user && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage
                      id="subscription"
                      defaultMessage="Subscription"
                    />
                  }
                  name="sub_id"
                  control={control}
                  options={subs}
                  loading={loadingSubs}
                />
              </Grid>
            )}

            {paymentMethod === "payNow" && (
              <Grid item sm={6}>
                <SelectField
                  label={
                    <FormattedMessage
                      id="paymentStatus"
                      defaultMessage="Payment Status"
                    />
                  }
                  name="payment_status"
                  control={control}
                  options={[
                    { label: "Pending", value: "pending" },
                    { label: "Paid", value: "paid" },
                    { label: "Expired", value: "expired" },
                  ]}
                />
              </Grid>
            )}

            <Grid item sm={6}>
              <SelectField
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                name="status"
                control={control}
                options={[
                  { label: "Pending", value: "pending" },
                  { label: "Upcoming", value: "upcoming" },
                  { label: "Expired", value: "expired" },
                  { label: "Complete", value: "complete" },
                  { label: "Canceled by Patient", value: "canceled_by_user" },
                  { label: "Canceled by Doctor", value: "canceled_by_doctor" },
                  { label: "Canceled by Admin", value: "canceled_by_admin" },
                ]}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                multiline
                fullWidth
                {...register("user_note")}
                label={
                  <FormattedMessage
                    id="usersNote"
                    defaultMessage="User's Note"
                  />
                }
                error={errors.user_note}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
