import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { TextField, PasswordField, SelectField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const addSchema = yup.object({
  name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  phone: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  email: yup
    .string()
    .email(
      <FormattedMessage
        id="validationEmail"
        defaultMessage="Enter a valid email"
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),

  pin_number: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  confirm_pin_number: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .oneOf(
      [yup.ref("pin_number")],
      <FormattedMessage
        id="validationPinConfirm"
        defaultMessage="Pin number did not match"
      />
    ),

  password: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  confirm_password: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .oneOf(
      [yup.ref("password")],
      <FormattedMessage
        id="validationConfirmPassword"
        defaultMessage="Password did not match"
      />
    ),

  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .nullable(),
  departments: yup
    .array()
    .of(
      yup
        .string()
        .required(
          <FormattedMessage
            id="fieldIsRequired"
            defaultMessage="Field is required"
          />
        )
    )
    .min(
      1,
      <FormattedMessage
        id="validationSelectFieldMin"
        defaultMessage="Please select at least {num} {field}"
        values={{
          num: 1,
          field: (
            <FormattedMessage id="department" defaultMessage="Department" />
          ),
        }}
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  session_charge: yup
    .number()
    .min(
      0,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 0 }}
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="validationNumber"
        defaultMessage="Enter a valid Number"
      />
    ),
  session_length: yup
    .number()
    .min(
      0,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 0 }}
      />
    )
    .required()
    .typeError(
      <FormattedMessage
        id="validationNumber"
        defaultMessage="Enter a valid Number"
      />
    ),
  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  about: yup
    .string()
    .max(
      500,
      <FormattedMessage
        id="valiationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 500 }}
      />
    )
    .nullable(),
  work_experience: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="valiationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    )
    .nullable(),
  certification: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="valiationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    )
    .nullable(),
  awards: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="valiationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    )
    .nullable(),
});
const updateSchema = yup.object({
  name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  phone: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  email: yup
    .string()
    .email(
      <FormattedMessage
        id="validationEmail"
        defaultMessage="Enter a valid email"
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),

  pin_number: yup.string(),
  confirm_pin_number: yup
    .string()
    .oneOf(
      [yup.ref("pin_number")],
      <FormattedMessage
        id="validationPinConfirm"
        defaultMessage="Pin number did not match"
      />
    ),

  password: yup.string(),
  confirm_password: yup
    .string()
    .oneOf(
      [yup.ref("password")],
      <FormattedMessage
        id="validationConfirmPassword"
        defaultMessage="Password did not match"
      />
    ),

  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .nullable(),
  departments: yup
    .array()
    .of(
      yup
        .string()
        .required(
          <FormattedMessage
            id="fieldIsRequired"
            defaultMessage="Field is required"
          />
        )
    )
    .min(
      1,
      <FormattedMessage
        id="validationSelectFieldMin"
        defaultMessage="Please select at least {num} {field}"
        values={{
          num: 1,
          field: (
            <FormattedMessage id="department" defaultMessage="Department" />
          ),
        }}
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  session_charge: yup
    .number()
    .min(
      0,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 0 }}
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="validationNumber"
        defaultMessage="Enter a valid Number"
      />
    ),
  session_length: yup
    .number()
    .min(
      0,
      <FormattedMessage
        id="validationMinNum"
        defaultMessage="Can't be less than {num}"
        values={{ num: 0 }}
      />
    )
    .required()
    .typeError(
      <FormattedMessage
        id="validationNumber"
        defaultMessage="Enter a valid Number"
      />
    ),
  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  about: yup
    .string()
    .max(
      500,
      <FormattedMessage
        id="valiationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 500 }}
      />
    )
    .nullable(),
  work_experience: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="valiationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    )
    .nullable(),
  certification: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="valiationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    )
    .nullable(),
  awards: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="valiationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    )
    .nullable(),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, authUser, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: useYup(edit?.id ? updateSchema : addSchema) });
  const {
    post: addDoc,
    put: updateDoc,
    loading,
  } = useFetch(endpoints.doctors + `/${edit?.id || ""}`);

  const hospital_id = watch("hospital_id");

  useEffect(() => {
    if (edit?.id) {
      if (edit.hospital?.id === hospital_id) {
        return setValue(
          "departments",
          edit?.departments?.map((item) => item.id) || []
        );
      }
    }
    setValue("departments", []);
  }, [hospital_id]);

  useEffect(() => {
    const newPass = Math.random().toString(36).substr(2, 8);
    const newPin = Math.random().toString().substr(2, 4);
    reset({
      ...edit,
      ...(!edit?.id && {
        password: newPass,
        confirm_password: newPass,
        pin_number: newPin,
        confirm_pin_number: newPin,
      }),
      departments: edit?.departments?.map((item) => item.id) || [],
      on_salary: edit?.on_salary || false,
      hospital_id:
        authUser.user_type === "hospital"
          ? authUser.id
          : edit?.hospital?.id || "",
    });
  }, [edit]);
  return (
    <Dialog open={open}>
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage id="editDoctor" defaultMessage="Edit Doctor" />
        ) : (
          <FormattedMessage id="addDoctor" defaultMessage="Add Doctor" />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            values.on_salary = values.on_salary?.value || false;
            (edit?.id ? updateDoc : addDoc)({
              ...values,
              password: values.password || undefined,
              pin_number: values.pin_number || undefined,
              age: undefined,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("name")}
                label={<FormattedMessage id="name" defaultMessage="Name" />}
                error={errors.name}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                fullWidth
                {...register("phone")}
                label={<FormattedMessage id="phone" defaultMessage="Phone" />}
                error={errors.phone}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                fullWidth
                {...register("email")}
                label={<FormattedMessage id="email" defaultMessage="Email" />}
                error={errors.email}
              />
            </Grid>

            {
              // !edit?.id && (
              <>
                <Grid item sm={6}>
                  <PasswordField
                    fullWidth
                    {...register("password")}
                    label={
                      <FormattedMessage
                        id="password"
                        defaultMessage="Password"
                      />
                    }
                    error={errors.password}
                  />
                </Grid>

                <Grid item sm={6}>
                  <PasswordField
                    fullWidth
                    {...register("confirm_password")}
                    label={
                      <FormattedMessage
                        id="confirmPassword"
                        defaultMessage="Confirm Password"
                      />
                    }
                    error={errors.confirm_password}
                  />
                </Grid>
              </>
              // )
            }

            <Grid item sm={6}>
              <PasswordField
                fullWidth
                {...register("pin_number")}
                label={
                  <FormattedMessage
                    id="pinNumber"
                    defaultMessage="Pin Number"
                  />
                }
                error={errors.pin_number}
              />
            </Grid>

            <Grid item sm={6}>
              <PasswordField
                fullWidth
                {...register("confirm_pin_number")}
                label={
                  <FormattedMessage
                    id="confirmPinNumber"
                    defaultMessage="Confirm Pin Number"
                  />
                }
                error={errors.confirm_pin_number}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                type="date"
                fullWidth
                {...register("dob")}
                label={
                  <FormattedMessage
                    id="dateOfBirth"
                    defaultMessage="Date of Birth"
                  />
                }
                error={errors.dob}
              />
            </Grid>

            {authUser.user_type === "admin" && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage id="hospital" defaultMessage="Hospital" />
                  }
                  name="hospital_id"
                  control={control}
                  url={endpoints.hospitals}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            {hospital_id && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage
                      id="departments"
                      defaultMessage="Departments"
                    />
                  }
                  name="departments"
                  control={control}
                  multiple
                  url={endpoints.departments}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                    hospital_id,
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            <Grid item sm={6}>
              <TextField
                fullWidth
                {...register("session_length")}
                label={
                  <FormattedMessage
                    id="sessionLength"
                    defaultMessage="Session Length"
                  />
                }
                error={errors.session_length}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                fullWidth
                {...register("session_charge")}
                label={
                  <FormattedMessage
                    id="sessionCharge"
                    defaultMessage="Session Charge"
                  />
                }
                error={errors.session_charge}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("about")}
                label={<FormattedMessage id="about" defaultMessage="About" />}
                error={errors.about}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("work_experience")}
                label={
                  <FormattedMessage
                    id="workExperience"
                    defaultMessage="Work Experience"
                  />
                }
                error={errors.work_experience}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("certification")}
                label={
                  <FormattedMessage
                    id="certification"
                    defaultMessage="Certification"
                  />
                }
                error={errors.certification}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("awards")}
                label={<FormattedMessage id="awards" defaultMessage="Awards" />}
                error={errors.awards}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                name="status"
                control={control}
                disableClearable
                options={[
                  { label: "Active", value: "active" },
                  { label: "Inactive", value: "inactive" },
                ]}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage id="onSalary" defaultMessage="On Salary" />
                }
                name="on_salary"
                control={control}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
