import { useState, useEffect, useCallback, useRef } from "react";
import { useAppState } from "components/AppProvider/AppProvider";

export const useFetch = (url, { headers: hookHeaders } = {}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { logoutUser } = useAppState();
  const controller = useRef();
  useEffect(() => {
    controller.current = new AbortController();

    return () => {
      controller.current.abort();
      setError(false);
      setLoading(false);
    };
  }, [url]);

  const onSubmit = useCallback(
    async (payload = {}, method, { headers, params, query } = {}) => {
      let _url = url;
      if (params) {
        Object.entries(params).forEach(([key, value]) => {
          _url = _url.replace(key, value);
        });
      }
      if (query && _url) {
        _url += `${_url.includes("?") ? "" : "?"}&${new URLSearchParams(
          query
        ).toString()}`;
      }
      setLoading(true);
      const response = await fetch(_url, {
        method: method,
        headers: {
          ...(!(typeof payload?.append === "function") && {
            "Content-Type": "application/json",
          }),
          "x-access-token": localStorage.getItem("access_token"),
          ...hookHeaders,
          ...headers,
        },
        ...(["POST", "PUT", "PATCH", "DELETE"].includes(method) && {
          body:
            typeof payload?.append === "function"
              ? payload
              : JSON.stringify(payload),
        }),
        signal: controller.current?.signal,
      })
        .then(async (res) => {
          if (res.status === 401) {
            logoutUser();
            throw "Please login again.";
          }
          try {
            const data = await res.json();
            return {
              res,
              data,
            };
          } catch (error) {
            if (["The user aborted a request."].includes(error?.message)) {
              return { res, data: {} };
            }
            setError(error);
            return { res, error };
          }
        })
        .catch((err) => {
          if (["The user aborted a request."].includes(err?.message)) {
            return { data: {} };
          }
          setError(err);
          return { error: err, data: {} };
        });

      if (response?.error) {
        // check for 401 and logout the user
      }
      setLoading(false);
      return response;
    },
    [url]
  );

  const post = (payload, options) => onSubmit(payload, "POST", options);

  const get = (options) => onSubmit(null, "GET", options);

  const remove = (payload, options) => onSubmit(payload, "DELETE", options);

  const put = (payload, options) => onSubmit(payload, "PUT", options);

  const patch = (payload, options) => onSubmit(payload, "PATCH", options);

  return { get, post, put, remove, patch, loading, error, onSubmit };
};

export default useFetch;
