import {
  BackendError,
  NotFound,
  PasswordReset,
  SignIn,
  AccountActivation,
  DeleteAccount,
} from "./pages";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AppProvider, { useAppState } from "./components/AppProvider/AppProvider";
import Dashboard from "./containers/Dashboard";
import React, { useState, useEffect } from "react";
import registerServiceWorker from "./registerServiceWorker";
import { render } from "react-dom";
import { paths } from "appConfigs";
import { NotificationContainer } from "react-notifications";
import { IntlProvider } from "react-intl";
import Lang from "./language";

import "react-notifications/lib/notifications.css";

function LangProvider({ children }) {
  const { state, config } = useAppState();
  const [messages, setMessages] = useState(Lang["en-US"]);
  useEffect(
    () => setMessages(Lang[state.locale] || Lang["en-US"]),
    [state.locale]
  );
  useEffect(() => {
    if (config) {
      document.title = config.siteTitle;
    } else {
      document.title = "VHMS247";
    }
  }, [config]);
  return (
    <>
      <IntlProvider locale={state.locale} messages={messages} onError={() => 1}>
        {children}
      </IntlProvider>
    </>
  );
}

render(
  <BrowserRouter>
    <AppProvider>
      <LangProvider>
        <Switch>
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/500" component={BackendError} />
          <Route exact path={paths.forgotPassword} component={PasswordReset} />
          <Route exact path={paths.signIn} component={SignIn} />
          <Route
            exact
            path={paths.activateAccount}
            component={AccountActivation}
          />
          <Route exact path={paths.deleteAccount} component={DeleteAccount} />
          <Route path="/" component={Dashboard} />
        </Switch>
        <NotificationContainer />
      </LangProvider>
    </AppProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

registerServiceWorker();
