import { useState } from "react";
import { Wrapper, Datagrid, ActionMenu } from "components";

import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import Filters from "./Filters";
import { useAppState } from "components/AppProvider/AppProvider";
import { FormattedMessage } from "react-intl";
import { format } from "date-fns";
import { Visibility } from "@material-ui/icons";
import Detail from "./Detail";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
  status: {
    textTransform: "capitalize",
  },
}));

const FundWallets = () => {
  const { authUser } = useAppState();
  const classes = useStyles();
  const [view, setView] = useState(null);
  const [filters, setFilters] = useState({});
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="fundWallets"
                    defaultMessage="Fund Wallets"
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters
                      filters={filters}
                      setFilters={setFilters}
                      authUser={authUser}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Datagrid
                  url={endpoints.fundWallets}
                  filters={filters}
                  columns={[
                    {
                      headerName: (
                        <FormattedMessage
                          id="createdAt"
                          defaultMessage="Created At"
                        />
                      ),
                      field: "createdAt",
                      renderCell: ({ row }) =>
                        format(new Date(row.createdAt), "dd MMM yyyy hh:mma"),
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="hospital"
                          defaultMessage="Hospital"
                        />
                      ),
                      field: "hospital",
                      renderCell: ({ row }) => row.hospital?.name,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="amount" defaultMessage="Amount" />
                      ),
                      field: "amount",
                      renderCell: ({ row }) =>
                        `₦${(row.amount || 0).toLocaleString()}`,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="status" defaultMessage="Status" />
                      ),
                      field: "status",
                      renderCell: ({ row }) => row.status,
                    },
                    // {
                    //   headerName: (
                    //     <FormattedMessage id="note" defaultMessage="Note" />
                    //   ),
                    //   field: "note",
                    // },
                    {
                      headerName: (
                        <FormattedMessage id="action" label="Action" />
                      ),
                      field: "action",
                      renderCell: ({ row }) => (
                        <ActionMenu
                          menuItems={[
                            {
                              icon: <Visibility />,
                              label: "View",
                              onClick: () => {
                                setView(row);
                              },
                            },
                          ]}
                        />
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
      <Detail data={view} setData={setView} />
    </>
  );
};

export default FundWallets;
