import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Paper,
  Chip,
  FormLabel,
  InputAdornment,
} from "@material-ui/core";
import { TextField, SelectField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import { useAppState } from "components/AppProvider/AppProvider";

const schema = yup.object({
  user_type: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  user_id: yup
    .string()
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  amount: yup
    .number()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  bank_code: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  account_number: yup
    .string(10)
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  note: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    ),
  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  chips: {
    padding: "1rem",
  },
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, onSuccess }) => {
  const s = useStyles();
  const [banks, setBanks] = useState([]);
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm({ resolver: useYup(schema) });
  const { post: requestPayout, loading: requestingPayout } = useFetch(
    endpoints.payouts
  );
  const { put: updatePayout, loading } = useFetch(
    endpoints.payouts + `/${edit?.id || ""}`
  );
  const { get: getBanks } = useFetch(endpoints.banks);

  const user_type = watch("user_type");

  useEffect(() => {
    reset({
      ...edit,
    });
  }, [edit]);

  useEffect(() => {
    getBanks().then(({ data }) => {
      if (data.success) {
        setBanks(data.data);
      }
    });
  }, []);
  return (
    <Dialog open={open}>
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage id="editPayout" defaultMessage="Edit Payout" />
        ) : (
          <FormattedMessage id="createPayout" defaultMessage="Create Payout" />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            (edit?.id ? updatePayout : requestPayout)({
              ...values,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage
                    id="clientType"
                    defaultMessage="Client Type"
                  />
                }
                name="user_type"
                control={control}
                options={[
                  { label: "Hospital", value: "hospital" },
                  { label: "Doctor", value: "doctor" },
                  { label: "Pharmacist", value: "pharmacist" },
                  { label: "Nurse", value: "nurse" },
                ]}
              />
            </Grid>

            {user_type && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage id="client" defaultMessage="Client" />
                  }
                  name="user_id"
                  control={control}
                  url={endpoints[user_type + "s"]}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            <Grid item sm={12}>
              <TextField
                fullWidth
                type="number"
                {...register("amount")}
                label={<FormattedMessage id="amount" defaultMessage="Amount" />}
                error={errors.amount}
                InputProps={{
                  startAdornment: <InputAdornment>₦</InputAdornment>,
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={<FormattedMessage id="bank" defaultMessage="Bank" />}
                name="bank_code"
                control={control}
                options={banks.map((bank) => ({
                  label: bank.name,
                  value: bank.code,
                }))}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("account_number")}
                label={
                  <FormattedMessage
                    id="accountNumber"
                    defaultMessage="Account Number"
                  />
                }
                error={errors.account_number}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                name="status"
                control={control}
                options={[
                  { label: "Pending", value: "pending" },
                  { label: "Verified", value: "verified" },
                  { label: "Complete", value: "complete" },
                ]}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("note")}
                label={<FormattedMessage id="note" defaultMessage="Remark" />}
                error={errors.note}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading || requestingPayout}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading || requestingPayout}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
