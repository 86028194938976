import { useState } from "react";
import { Wrapper, Datagrid, Permission } from "components";

import { Typography, IconButton, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import Form from "./Form";
import Filters from "./Filters";
import { useAppState } from "components/AppProvider/AppProvider";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
  status: {
    textTransform: "capitalize",
  },
}));

const SubscriptionPackages = () => {
  const { authUser } = useAppState();
  const classes = useStyles();
  const [edit, setEdit] = useState(null);
  const [filters, setFilters] = useState({});
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="subPackages"
                    defaultMessage="Subscription Messages"
                  />
                </Typography>
                <Permission permission="add_sub_package">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setEdit(true)}
                  >
                    <FormattedMessage
                      id="addSubPackage"
                      defaultMessage="Add Subscription Package"
                    />
                  </Button>
                </Permission>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters
                      filters={filters}
                      setFilters={setFilters}
                      authUser={authUser}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Datagrid
                  url={endpoints.subscriptionPackages}
                  filters={filters}
                  columns={[
                    {
                      headerName: (
                        <FormattedMessage id="name" defaultMessage="Name" />
                      ),
                      field: "name",
                    },
                    {
                      headerName: (
                        <FormattedMessage id="price" defaultMessage="Price" />
                      ),
                      field: "price",
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="maxMember"
                          defaultMessage="Max Member"
                        />
                      ),
                      field: "max_member",
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="maxAppointment"
                          defaultMessage="Max Appointment"
                        />
                      ),
                      field: "max_appointment",
                    },
                    ...(authUser.user_type === "admin"
                      ? [
                          {
                            headerName: (
                              <FormattedMessage
                                id="hospital"
                                defaultMessage="Hospital"
                              />
                            ),
                            field: "hospital.name",
                            renderCell: ({ row }) => row.hospital?.name,
                          },
                        ]
                      : []),
                    {
                      headerName: (
                        <FormattedMessage
                          id="departments"
                          defaultMessage="Departments"
                        />
                      ),
                      field: "departments",
                      renderCell: ({ row }) =>
                        row.departments?.map((dept) => dept.name).join(", ") ||
                        "NA",
                    },
                    {
                      headerName: (
                        <FormattedMessage id="status" defaultMessage="Status" />
                      ),
                      field: "status",
                      cellClassName: classes.status,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="action" defaultMessage="Action" />
                      ),
                      field: "action",
                      renderCell: ({ row }) => (
                        <>
                          <Permission permission="remove_sub_packages">
                            <IconButton
                              aria-label={
                                <FormattedMessage
                                  id="editSubPackage"
                                  defaultMessage="Edit Subscription Package"
                                />
                              }
                              onClick={() => setEdit(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Permission>
                          {/* <DeleteActionIcon to={`${props.pageUrl}/add`} /> */}
                        </>
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Form
          authUser={authUser}
          open={!!edit}
          edit={edit}
          setEdit={setEdit}
          onSuccess={(newSubPackage) => {
            setFilters((prev) => ({ ...prev }));
            setEdit(null);
          }}
        />
      </Wrapper>
    </>
  );
};

export default SubscriptionPackages;
