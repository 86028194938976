const baseUrl = process.env.REACT_APP_BASE_URL;

const endpoints = {
  adminLogin: `${baseUrl}/api/admins/login`,
  hospitalLogin: `${baseUrl}/api/hospitals/login`,
  refHospitalLogin: `${baseUrl}/api/referral-hospitals/login`,
  stakeholderLogin: `${baseUrl}/api/stakeholders/login`,

  admin: {
    profile: `${baseUrl}/api/admins/profile`,
    updateProfile: `${baseUrl}/api/admins/update-profile`,
    logout: `${baseUrl}/api/admins/logout`,
    apiKeys: `${baseUrl}/api/admins/api-keys`,
  },

  dashboardData: `${baseUrl}/api/admins/dashboard-data`,
  stakeholders: `${baseUrl}/api/admins/stakeholders`,
  nurses: `${baseUrl}/api/admins/nurses`,
  hospitals: `${baseUrl}/api/admins/hospitals`,
  refHospitals: `${baseUrl}/api/admins/referral-hospitals`,
  hospitalRefs: `${baseUrl}/api/admins/hospital-referrals`,
  users: `${baseUrl}/api/admins/users`,
  doctors: `${baseUrl}/api/admins/doctors`,
  pharmacists: `${baseUrl}/api/admins/pharmacists`,
  appointments: `${baseUrl}/api/admins/appointments`,
  emailTemplates: `${baseUrl}/api/admins/email-templates`,
  departments: `${baseUrl}/api/admins/departments`,
  salaries: `${baseUrl}/api/salaries`,
  payouts: `${baseUrl}/api/payouts`,
  completePayout: `${baseUrl}/api/payouts/{id}/complete`,
  healthGroupSubs: `${baseUrl}/api/admins/health-group-subscriptions`,
  healthGroupSessions: `${baseUrl}/api/admins/health-group-sessions`,
  subscriptions: `${baseUrl}/api/admins/subscriptions`,
  drugs: `${baseUrl}/api/drugs`,
  dispensaries: `${baseUrl}/api/dispensaries`,
  dispensarySuppliers: `${baseUrl}/api/dispensary-suppliers`,
  dispensaryPurchases: `${baseUrl}/api/dispensary-purchases`,
  dispensarySales: `${baseUrl}/api/dispensary-sales`,
  prescriptions: `${baseUrl}/api/prescriptions`,
  prescriptionToSale: `${baseUrl}/api/prescriptions/:id/generate-sale`,
  casefiles: `${baseUrl}/api/casefiles`,
  reports: `${baseUrl}/api/reports`,
  transactions: `${baseUrl}/api/transactions`,
  bankTransfers: `${baseUrl}/api/wallet/bank-transfer-requests`,
  initBankTransfer: `${baseUrl}/api/wallet/transfer-to-bank`,
  fundWallets: `${baseUrl}/api/wallet/fund-wallet-requests`,
  serviceProviders: `${baseUrl}/api/service-providers`,

  walletBalance: `${baseUrl}/api/wallet/balance`,
  fundWallet: `${baseUrl}/api/wallet/init-fund-wallet`,

  hospital: {
    profile: `${baseUrl}/api/hospitals/profile`,
    updateProfile: `${baseUrl}/api/hospitals/update-profile`,
    logout: `${baseUrl}/api/hospitals/logout`,
    dashboardData: `${baseUrl}/api/hospitals/dashboard-data`,
  },
  stakeholder: {
    profile: `${baseUrl}/api/stakeholders/profile`,
    updateProfile: `${baseUrl}/api/stakeholders/update-profile`,
    logout: `${baseUrl}/api/stakeholders/logout`,
    dashboardData: `${baseUrl}/api/stakeholders/dashboard-data`,
  },
  refHospital: {
    profile: `${baseUrl}/api/referral-hospitals/profile`,
    updateProfile: `${baseUrl}/api/referral-hospitals/update-profile`,
    logout: `${baseUrl}/api/referral-hospitals/logout`,
    dashboardData: `${baseUrl}/api/referral-hospitals/dashboard-data`,
    hospitalRefs: `${baseUrl}/api/referral-hospitals/hospital-referrals`,
    makeReferralPayment: `${baseUrl}/api/referral-hospitals/hospital-referrals/make-payment`,
  },

  activateUser: `${baseUrl}/api/users/activate-account`,
  activateDoctor: `${baseUrl}/api/doctors/activate-account`,
  deleteAccount: `${baseUrl}/api/:userType/delete-account`,

  subscriptionPackages: `${baseUrl}/api/subscription-packages`,
  healthGroups: `${baseUrl}/api/health-groups`,

  permissions: `${baseUrl}/api/permissions`,
  roles: `${baseUrl}/api/roles`,

  siteConfig: `${baseUrl}/api/settings/site-config`,
  allSettings: `${baseUrl}/api/settings/get-all`,
  settings: `${baseUrl}/api/settings`,
  banks: `${baseUrl}/api/settings/banks`,
};

export default endpoints;
