import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components";

import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import { useFetch } from "hooks";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
  status: {
    textTransform: "capitalize",
  },
  failed: {
    color: "rgb(255, 0, 69)",
    fontSize: "3rem",
    margin: "auto",
  },
  success: {
    color: "rgb(0, 255, 87)",
    fontSize: "3rem",
    margin: "auto",
  },
  loading: {
    color: "rgb(255, 153, 0)",
    fontSize: "3rem",
    margin: "auto",
  },
  message: {
    margin: "auto",
    marginLeft: "0",
  },
}));

const Subscriptions = ({ match }) => {
  const s = useStyles();
  const [status, setStatus] = useState({
    loading: true,
    success: false,
    message: "Account Activation",
  });
  const { post: activateUser, loading: activatingUser } = useFetch(
    endpoints.activateUser
  );
  const { post: activateDoctor, loading: activatingDoctor } = useFetch(
    endpoints.activateDoctor
  );

  useEffect(async () => {
    if (["user", "doctor"].includes(match.params.accountType)) {
      setStatus((prev) => ({
        ...prev,
        loading: true,
        message: "Activating Account...",
      }));

      let action = null;
      if (match.params.accountType === "user") {
        action = activateUser;
      } else if (match.params.accountType === "doctor") {
        action = activateDoctor;
      }

      action({
        user_id: match.params.id,
        otp: match.params.otp,
      })
        .then(({ data }) => {
          if (data?.success) {
            return setStatus((prev) => ({
              ...prev,
              success: true,
              loading: false,
              message: data.message,
            }));
          }
          if (data?.success === false) {
            return setStatus((prev) => ({
              ...prev,
              success: false,
              loading: false,
              message: data.message,
            }));
          }
        })
        .catch((err) => {
          setStatus({
            success: false,
            loading: false,
            message: err.message,
          });
        });
    }
  }, []);
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={6}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={s.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="activateAccount"
                    defaultMessage="Activate account"
                  />
                </Typography>
              </Grid>
              <Card>
                <CardContent>
                  <Grid container align="center" justifyContent="center">
                    <Grid item sm={1}>
                      {status.loading && (
                        <RotateRightIcon className={s.loading} />
                      )}
                      {!status.loading &&
                        (status.success ? (
                          <CheckCircleOutlineIcon className={s.success} />
                        ) : (
                          <HighlightOffIcon className={s.failed} />
                        ))}
                    </Grid>
                    <Grid item className={s.message} sm={11}>
                      <Typography variant="h5">{status.message}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default Subscriptions;
