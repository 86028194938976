import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { SelectField, TextField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const schema = yup.object({
  name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  service_mode: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  url: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  api_key: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  api_secret: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  contract_code: yup.string().when("name", (name, schema) => {
    if (name?.toLowerCase() === "monnify") {
      return schema.required(
        <FormattedMessage
          id="fieldIsRequired"
          defaultMessage="Field is required"
        />
      );
    }
    return schema.nullable();
  }),
  wallet_account_number: yup.string().when("name", (name, schema) => {
    if (name?.toLowerCase() === "monnify") {
      return schema.required(
        <FormattedMessage
          id="fieldIsRequired"
          defaultMessage="Field is required"
        />
      );
    }
    return schema.nullable();
  }),
  test_url: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  test_api_key: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  test_api_secret: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  test_contract_code: yup.string().when("name", (name, schema) => {
    if (name?.toLowerCase() === "monnify") {
      return schema.required(
        <FormattedMessage
          id="fieldIsRequired"
          defaultMessage="Field is required"
        />
      );
    }
    return schema.nullable();
  }),
  test_wallet_account_number: yup.string().when("name", (name, schema) => {
    if (name?.toLowerCase() === "monnify") {
      return schema.required(
        <FormattedMessage
          id="fieldIsRequired"
          defaultMessage="Field is required"
        />
      );
    }
    return schema.nullable();
  }),
  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm({ resolver: useYup(schema) });
  const {
    post: addDept,
    put: updateDept,
    loading,
  } = useFetch(endpoints.serviceProviders + `/${edit?.id || ""}`);

  const name = watch("name");

  useEffect(() => {
    reset({
      ...edit,
    });
  }, [edit]);
  return (
    <Dialog open={open}>
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage
            id="editServiceProvider"
            defaultMessage="editServiceProvider"
          />
        ) : (
          <FormattedMessage
            id="addServiceProvider"
            defaultMessage="Add Service Provider"
          />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            (edit?.id ? updateDept : addDept)({
              ...values,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <TextField
                fullWidth
                {...register("name")}
                label={<FormattedMessage id="name" defaultMessage="Name" />}
                error={errors.name}
              />
            </Grid>

            <Grid item sm={6}>
              <SelectField
                label={
                  <FormattedMessage
                    id="serviceMode"
                    defaultMessage="Service Mode"
                  />
                }
                name="service_mode"
                control={control}
                disableClearable
                options={[
                  { label: "Live", value: "live" },
                  { label: "Test", value: "test" },
                ]}
              />
            </Grid>

            <Grid item>
              <Typography variant="h5">Live Credentials</Typography>
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("url")}
                label={<FormattedMessage id="url" defaultMessage="URL" />}
                error={errors.url}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("api_key")}
                label={
                  <FormattedMessage id="apiKey" defaultMessage="API Key" />
                }
                error={errors.api_key}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("api_secret")}
                label={
                  <FormattedMessage
                    id="apiSecret"
                    defaultMessage="API Secret"
                  />
                }
                error={errors.api_secret}
              />
            </Grid>
            {name?.toLowerCase() === "monnify" && (
              <>
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    {...register("contract_code")}
                    label={
                      <FormattedMessage
                        id="contractCode"
                        defaultMessage="Contract Code"
                      />
                    }
                    error={errors.contract_code}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    {...register("wallet_account_number")}
                    label={
                      <FormattedMessage
                        id="walletAccountNumber"
                        defaultMessage="Wallet Account Number"
                      />
                    }
                    error={errors.wallet_account_number}
                  />
                </Grid>
              </>
            )}

            <Grid item>
              <Typography variant="h5">Test Credentials</Typography>
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("test_url")}
                label={
                  <FormattedMessage id="testUrl" defaultMessage="Test URL" />
                }
                error={errors.test_url}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("test_api_key")}
                label={
                  <FormattedMessage
                    id="testApiKey"
                    defaultMessage="Test API Key"
                  />
                }
                error={errors.test_api_key}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("test_api_secret")}
                label={
                  <FormattedMessage
                    id="testApiSecret"
                    defaultMessage="Test API Secret"
                  />
                }
                error={errors.test_api_secret}
              />
            </Grid>
            {name?.toLowerCase() === "monnify" && (
              <>
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    {...register("test_contract_code")}
                    label={
                      <FormattedMessage
                        id="testContractCode"
                        defaultMessage="Test Contract Code"
                      />
                    }
                    error={errors.test_contract_code}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    {...register("test_wallet_account_number")}
                    label={
                      <FormattedMessage
                        id="testWalletAccountNumber"
                        defaultMessage="Test Wallet Account Number"
                      />
                    }
                    error={errors.test_wallet_account_number}
                  />
                </Grid>
              </>
            )}

            <Grid item sm={12}>
              <SelectField
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                name="status"
                control={control}
                disableClearable
                options={[
                  { label: "Active", value: "active" },
                  { label: "Inactive", value: "inactive" },
                ]}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
