import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { TextField, SelectField, DeleteActionIcon, Checkbox } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import { format } from "date-fns";

const schema = yup.object({
  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  doctor_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  user_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .typeError(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  history: yup
    .string()
    .max(
      500,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} character"
        values={{ num: 500 }}
      />
    ),
  advice: yup
    .string()
    .max(
      500,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} character"
        values={{ num: 500 }}
      />
    ),
  date: yup
    .string()
    .required()
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      <FormattedMessage
        id="validationDateFormat"
        defaultMessage="The expected format of the date is {format}"
        values={{ format: "YYYY-MM-DD" }}
      />
    )
    .test({
      name: "date",
      message: (
        <FormattedMessage
          id="validationDate"
          defaultMessage="Enter a valid date"
          values={{ num: 250 }}
        />
      ),
      test: (v) => !isNaN(new Date(v).getTime()),
    })
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  drugs: yup
    .array()
    .of(
      yup.object({
        generic_name: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        brand_name: yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          ),
        description: yup
          .string()
          .max(
            250,
            <FormattedMessage
              id="validationMaxChar"
              defaultMessage="Can't be more than {num} character"
              values={{ num: 250 }}
            />
          ),
        dosage_form: yup.string(),
        api_strength: yup.number(),
        dose: yup.string(),
      })
    )
    .typeError("drugs must be an array")
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});
const drugSchema = yup.object({
  drug: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  generic_name: yup.string().required(),
  brand_name: yup.string(),
  description: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} character"
        values={{ num: 250 }}
      />
    ),
  dosage_form: yup.string(),
  api_strength: yup.number(),
  dose: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  chips: {
    padding: "1rem",
  },
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, authUser, onSuccess }) => {
  const [addDrug, setAddDrug] = useState(false);
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(schema) });
  const {
    post: addPrescription,
    put: updatePrescription,
    loading,
  } = useFetch(endpoints.prescriptions + `/${edit?.id || ""}`);

  const drugs = watch("drugs");
  const hospital_id = watch("hospital_id");
  const user_id = watch("user_id");

  useEffect(() => {
    reset({
      ...edit,
      user_id: edit?.user?.id || "",
      doctor_id: edit?.doctor?.id || "",
      hospital_id:
        authUser.user_type === "hospital"
          ? authUser.id
          : edit?.hospital?.id || "",
      drugs: edit?.drugs || [],
      obfuscate_from_users:
        typeof edit?.obfuscate_from_users === "boolean"
          ? edit?.obfuscate_from_users
          : true,
    });
  }, [edit]);

  useEffect(() => {
    setValue("user_id", "");
    setValue("doctor_id", "");
  }, [hospital_id]);
  useEffect(() => {
    setValue("casefile_id", "");
  }, [user_id]);
  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage
            id="editPrescription"
            defaultMessage="Edit Prescription"
          />
        ) : (
          <FormattedMessage
            id="addPrescription"
            defaultMessage="Add Prescription"
          />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            (edit?.id ? updatePrescription : addPrescription)({
              ...values,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            {authUser.user_type === "admin" && (
              <>
                <Grid item sm={6}>
                  <SelectField
                    label={
                      <FormattedMessage
                        id="hospital"
                        defaultMessage="Hospital"
                      />
                    }
                    name="hospital_id"
                    control={control}
                    url={endpoints.hospitals}
                    getQuery={(inputValue, selected) => ({
                      ...(inputValue && { name: inputValue }),
                      ...(selected && { id: selected }),
                    })}
                    handleData={(data) => ({
                      label: data.name,
                      value: data.id,
                    })}
                  />
                </Grid>

                <Grid item sm={authUser.user_type === "admin" ? 6 : 12}>
                  <SelectField
                    label={
                      <FormattedMessage id="doctor" defaultMessage="Doctor" />
                    }
                    name="doctor_id"
                    control={control}
                    url={endpoints.doctors}
                    getQuery={(inputValue, selected) => ({
                      ...(inputValue && { name: inputValue }),
                      ...(selected && { id: selected }),
                      hospital_id,
                    })}
                    handleData={(data) => ({
                      label: data.name,
                      value: data.id,
                    })}
                  />
                </Grid>
              </>
            )}

            {hospital_id && (
              <>
                <Grid item sm={6}>
                  <SelectField
                    label={
                      <FormattedMessage id="patient" defaultMessage="Patient" />
                    }
                    name="user_id"
                    control={control}
                    url={endpoints.users}
                    getQuery={(inputValue, selected) => ({
                      ...(inputValue && { name: inputValue }),
                      ...(selected && { id: selected }),
                      hospital_id,
                    })}
                    handleData={(data) => ({
                      label: data.name,
                      value: data.id,
                    })}
                  />
                </Grid>

                <Grid item sm={6}>
                  <SelectField
                    label={
                      <FormattedMessage
                        id="casefile"
                        defaultMessage="Casefile"
                      />
                    }
                    name="casefile_id"
                    control={control}
                    url={endpoints.casefiles}
                    getQuery={(inputValue, selected) => ({
                      // ...(inputValue && { title: inputValue }),
                      ...(selected && { id: selected }),
                      hospital_id,
                      user_id,
                    })}
                    handleData={(data) => ({
                      label:
                        data.title ||
                        `${data.user?.name} (${format(
                          new Date(data.createdAt),
                          "dd MMM yyyy"
                        )})`,
                      value: data.id,
                    })}
                  />
                </Grid>
              </>
            )}

            <Grid item sm={12}>
              <TextField
                fullWidth
                type="date"
                {...register("date")}
                label={<FormattedMessage id="date" defaultMessage="Date" />}
                error={errors.date}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("history")}
                label={
                  <FormattedMessage id="history" defaultMessage="History" />
                }
                error={errors.history}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("advice")}
                label={<FormattedMessage id="advice" defaultMessage="Advice" />}
                error={errors.advice}
              />
            </Grid>

            {hospital_id && (
              <>
                <Grid item sm={12}>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography variant="h6">
                        <FormattedMessage id="drugs" defaultMessage="Drugs" />
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={() => setAddDrug(true)}
                      >
                        <FormattedMessage
                          id="addDrug"
                          defaultMessage="Add Drug"
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <FormattedMessage
                              id="dosageForm"
                              defaultMessage="Dosage Form"
                            />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id="drug" defaultMessage="Drug" />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage
                              id="administration"
                              defaultMessage="Administration"
                            />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage
                              id="doseInterval"
                              defaultMessage="DoseInterval"
                            />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage
                              id="duration"
                              defaultMessage="Duration"
                            />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id="note" defaultMessage="Note" />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage
                              id="action"
                              defaultMessage="Action"
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(drugs || []).map((drug) => (
                          <TableRow key={drug.id}>
                            <TableCell>{drug.dosage_form}</TableCell>
                            <TableCell>
                              {drug.brand_name || drug.generic_name}{" "}
                              {drug.api_strength && <>({drug.api_strength})</>}
                            </TableCell>
                            <TableCell>{drug.administration}</TableCell>
                            <TableCell>{drug.dose_interval}</TableCell>
                            <TableCell>{drug.duration}</TableCell>
                            <TableCell>{drug.note}</TableCell>
                            <TableCell>
                              <IconButton
                                aria-label={
                                  <FormattedMessage
                                    id="editPrescription"
                                    defaultMessage="Edit Prescription"
                                  />
                                }
                                onClick={() => {
                                  setAddDrug(drug);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <DeleteActionIcon
                                message={
                                  <FormattedMessage
                                    id="deleteConfirmation_drug"
                                    defaultMessage="Are you sure want to remove this drug?"
                                  />
                                }
                                deleteRecord={() => {
                                  setValue(
                                    "drugs",
                                    drugs.filter(
                                      (dr) => dr.brand_name !== drug.brand_name
                                    )
                                  );
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                        {(drugs || []).length === 0 && (
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Typography variant="subtitle1" align="center">
                                <FormattedMessage
                                  id="noDrugs"
                                  defaultMessage="No Drugs!"
                                />
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}

            <Grid item sm={12}>
              <Checkbox
                control={control}
                name="obfuscate_from_users"
                label={
                  <FormattedMessage
                    id="obfuscateDrugsFromUser"
                    defaultMessage="Obfuscate drugs from users"
                  />
                }
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DrugForm
        open={!!addDrug}
        edit={addDrug}
        setEdit={setAddDrug}
        hospitalId={hospital_id}
        onSuccess={(newDrug) => {
          if (addDrug?.brand_name) {
            setValue(
              "drugs",
              drugs.map((drug) =>
                drug.brand_name === newDrug.brand_name ? newDrug : drug
              )
            );
          } else {
            setValue("drugs", [...drugs, newDrug]);
          }
          setAddDrug(null);
        }}
      />
    </Dialog>
  );
};

const DrugForm = ({ open, edit, setEdit, hospitalId, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(drugSchema) });
  useEffect(() => {
    reset({
      ...edit,
    });
  }, [edit]);
  return (
    <Dialog open={open}>
      <DialogTitle>{edit?.brand_name ? "Update" : "Add"} Drug</DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            onSuccess({ ...values });
          })}
        >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("custom_name")}
                label="Custom Name"
                error={errors.custom_name}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label="Drug"
                readOnly={edit?.brand_name}
                name="drug"
                control={control}
                url={endpoints.drugs}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                  hospital_id: hospitalId,
                })}
                handleData={(data) => ({
                  label: data.brand_name || data.generic_name,
                  value: data.id,
                  data,
                })}
                onChange={(data) => {
                  const drug = data?.data || {};
                  setValue("generic_name", drug.generic_name || "");
                  setValue("brand_name", drug.brand_name || "");
                  setValue("api_strength", drug.api_strength || "");
                  setValue("description", drug.description || "");
                  setValue("dosage_form", drug.dosage_form || "");
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label="Administration"
                name="administration"
                control={control}
                options={[
                  { label: "Before meal", value: "Before meal" },
                  { label: "After meal", value: "After meal" },
                ]}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("dose")}
                label="Dose"
                placeholder="1pc / 5ml / 2 drops"
                error={errors.dose}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("dose_interval")}
                label="Dose Interval"
                placeholder="0-1-0"
                error={errors.dose_interval}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("duration")}
                type="number"
                label="Duration"
                error={errors.duration}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Days</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("note")}
                label="Note"
                error={errors.note}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setEdit(null)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
