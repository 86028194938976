import { Button, Grid, makeStyles } from "@material-ui/core";
import { TextField, SelectField } from "components";
import { useForm } from "react-hook-form";
import { endpoints } from "appConfigs";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginRight: "0",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ filters, authUser, setFilters }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm();
  return (
    <Grid item className="mb-1">
      <form
        autoComplete={"off"}
        onSubmit={handleSubmit(async (values) => {
          const query = {};
          Object.entries(values).forEach(([key, value]) => {
            if (value) {
              query[key] = value;
            }
          });
          setFilters(query);
        })}
      >
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <TextField
              fullWidth
              {...register("name")}
              label={<FormattedMessage id="name" defaultMessage="Name" />}
              error={errors.name}
            />
          </Grid>

          {authUser.user_type === "admin" && (
            <Grid item sm={3}>
              <SelectField
                label={
                  <FormattedMessage id="hospital" defaultMessage="Hospital" />
                }
                name="hospital_id"
                control={control}
                multiple
                url={endpoints.hospitals}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
              />
            </Grid>
          )}

          <Grid item sm={3}>
            <SelectField
              label={<FormattedMessage id="doctors" defaultMessage="Doctors" />}
              name="doctors"
              control={control}
              multiple
              url={endpoints.doctors}
              getQuery={(inputValue, selected) => ({
                ...(inputValue && { name: inputValue }),
                ...(selected && { id: selected }),
              })}
              handleData={(data) => ({
                label: data.name,
                value: data.id,
              })}
            />
          </Grid>

          <Grid item sm={3}>
            <SelectField
              label={<FormattedMessage id="nurses" defaultMessage="Nurses" />}
              name="nurses"
              control={control}
              multiple
              url={endpoints.nurses}
              getQuery={(inputValue, selected) => ({
                ...(inputValue && { name: inputValue }),
                ...(selected && { id: selected }),
              })}
              handleData={(data) => ({
                label: data.name,
                value: data.id,
              })}
            />
          </Grid>

          <Grid item sm={3}>
            <SelectField
              label={
                <FormattedMessage
                  id="pharmacists"
                  defaultMessage="Pharamcists"
                />
              }
              name="pharmacists"
              control={control}
              multiple
              url={endpoints.pharmacists}
              getQuery={(inputValue, selected) => ({
                ...(inputValue && { name: inputValue }),
                ...(selected && { id: selected }),
              })}
              handleData={(data) => ({
                label: data.name,
                value: data.id,
              })}
            />
          </Grid>

          <Grid item sm={3}>
            <SelectField
              label={
                <FormattedMessage
                  id="departments"
                  defaultMessage="Departments"
                />
              }
              name="departments"
              control={control}
              multiple
              url={endpoints.departments}
              getQuery={(inputValue, selected) => ({
                ...(inputValue && { name: inputValue }),
                ...(selected && { id: selected }),
              })}
              handleData={(data) => ({
                label: data.name,
                value: data.id,
              })}
            />
          </Grid>

          <Grid item sm={3}>
            <SelectField
              label={<FormattedMessage id="status" defaultMessage="Status" />}
              name="status"
              control={control}
              options={[
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
                { label: "Expired", value: "expired" },
              ]}
            />
          </Grid>

          <Grid item sm={3} className={s.btns}>
            <Button type="submit" variant="contained" color="primary">
              <FormattedMessage id="search" defaultMessage="Search" />
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                setFilters({});
                reset();
              }}
            >
              <FormattedMessage id="clear" defaultMessage="Clear" />
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default Form;
