import { useEffect } from "react";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { TextField, PasswordField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const addSchema = yup.object({
  // phone: yup
  //   .string()
  //   .required(
  //     <FormattedMessage
  //       id="fieldIsRequired"
  //       defaultMessage="Field is required"
  //     />
  //   ),
  email: yup
    .string()
    .email(
      <FormattedMessage
        id="validationEmail"
        defaultMessage="Enter a valid email"
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),

  password: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ userType, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: useYup(addSchema) });
  const { remove: deleteAccount, loading } = useFetch(
    endpoints.deleteAccount.replace(":userType", userType)
  );

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(async (values) => {
        const { position, error } = await getUserLocation();
        if (error) {
          return NotificationManager.error(error);
        }
        deleteAccount({
          ...values,
          latitude: position?.latitude,
          longitude: position?.longitude,
        })
          .then(({ data }) => {
            if (data.success) {
              // NotificationManager.success(data.message);
              onSuccess(data.data);
            } else {
              NotificationManager.error(data.message);
            }
          })
          .catch((err) => {
            NotificationManager.error(err.message);
          });
      })}
    >
      <Grid container spacing={3}>
        {/* <Grid item sm={12}>
          <TextField
            fullWidth
            {...register("phone")}
            label={<FormattedMessage id="phone" defaultMessage="Phone" />}
            error={errors.phone}
          />
        </Grid> */}

        <Grid item sm={12}>
          <Typography variant="body1">
            <FormattedMessage
              id="account_delete_notice"
              defaultMessage="Once deleted, you will no longer be able to access your account. Are you sure you want to continue?"
            />
          </Typography>
        </Grid>

        <Grid item sm={12}>
          <TextField
            fullWidth
            {...register("email")}
            label={<FormattedMessage id="email" defaultMessage="Email" />}
            error={errors.email}
          />
        </Grid>

        <Grid item sm={12}>
          <PasswordField
            fullWidth
            {...register("password")}
            label={<FormattedMessage id="password" defaultMessage="Password" />}
            error={errors.password}
          />
        </Grid>

        <Grid item sm={12} className={s.btns}>
          <Button
            type="button"
            variant="contained"
            disabled={loading}
            onClick={() => setEdit(null)}
          >
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            type="submit"
            disabled={loading}
            variant="contained"
            color="primary"
          >
            <FormattedMessage id="submit" defaultMessage="Submit" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
