import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { TextField, PasswordField, SelectField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const addSchema = yup.object({
  name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  phone: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  email: yup
    .string()
    .email(
      <FormattedMessage
        id="validationEmail"
        defaultMessage="Enter a valid email"
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),

  pin_number: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  confirm_pin_number: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .oneOf(
      [yup.ref("pin_number")],
      <FormattedMessage
        id="validationPinConfirm"
        defaultMessage="Pin number did not match"
      />
    ),

  hospitals: yup
    .array()
    .of(
      yup
        .string()
        .required(
          <FormattedMessage
            id="fieldIsRequired"
            defaultMessage="Field is required"
          />
        )
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),

  password: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  confirm_password: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    )
    .oneOf(
      [yup.ref("password")],
      <FormattedMessage
        id="validationConfirmPassword"
        defaultMessage="Password did not match"
      />
    ),

  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});
const updateSchema = yup.object({
  name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  phone: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  email: yup
    .string()
    .email(
      <FormattedMessage
        id="validationEmail"
        defaultMessage="Enter a valid email"
      />
    )
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),

  pin_number: yup.string(),
  confirm_pin_number: yup
    .string()
    .oneOf(
      [yup.ref("pin_number")],
      <FormattedMessage
        id="validationPinConfirm"
        defaultMessage="Pin number did not match"
      />
    ),

  password: yup.string(),
  confirm_password: yup
    .string()
    .oneOf(
      [yup.ref("password")],
      <FormattedMessage
        id="validationConfirmPassword"
        defaultMessage="Password did not match"
      />
    ),

  status: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  about: yup
    .string()
    .max(
      500,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} character"
        values={{ num: 500 }}
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(edit?.id ? updateSchema : addSchema) });
  const {
    post: addDoc,
    put: updateDoc,
    loading,
  } = useFetch(endpoints.refHospitals + `/${edit?.id || ""}`);

  useEffect(() => {
    const newPass = Math.random().toString(36).substr(2, 8);
    const newPin = Math.random().toString().substr(2, 4);
    reset({
      ...edit,
      ...(!edit?.id && {
        password: newPass,
        confirm_password: newPass,
        pin_number: newPin,
        confirm_pin_number: newPin,
      }),
      hospitals: edit?.hospitals?.map((i) => i.id) || [],
    });
  }, [edit]);
  return (
    <Dialog open={open}>
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage
            id="editRefHospital"
            defaultMessage="Edit Referral Hospital"
          />
        ) : (
          <FormattedMessage
            id="addRefHospital"
            defaultMessage="Add Referral Hospital"
          />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            // return console.log(values);
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            (edit?.id ? updateDoc : addDoc)({
              ...values,
              password: values.password || undefined,
              pin_number: values.pin_number || undefined,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("name")}
                label={<FormattedMessage id="name" defaultMessage="Name" />}
                error={errors.name}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                fullWidth
                {...register("phone")}
                label={<FormattedMessage id="phone" defaultMessage="Phone" />}
                error={errors.phone}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                fullWidth
                {...register("email")}
                label={<FormattedMessage id="email" defaultMessage="Email" />}
                error={errors.email}
              />
            </Grid>

            {
              // !edit?.id && (
              <>
                <Grid item sm={6}>
                  <PasswordField
                    fullWidth
                    {...register("password")}
                    label={
                      <FormattedMessage
                        id="password"
                        defaultMessage="Password"
                      />
                    }
                    error={errors.password}
                  />
                </Grid>

                <Grid item sm={6}>
                  <PasswordField
                    fullWidth
                    {...register("confirm_password")}
                    label={
                      <FormattedMessage
                        id="confirmPassword"
                        defaultMessage="Confirm Password"
                      />
                    }
                    error={errors.confirm_password}
                  />
                </Grid>
              </>
              // )
            }

            <Grid item sm={6}>
              <PasswordField
                fullWidth
                {...register("pin_number")}
                label={
                  <FormattedMessage
                    id="pinNumber"
                    defaultMessage="Pin Number"
                  />
                }
                error={errors.pin_number}
              />
            </Grid>

            <Grid item sm={6}>
              <PasswordField
                fullWidth
                {...register("confirm_pin_number")}
                label={
                  <FormattedMessage
                    id="confirmPinNumber"
                    defaultMessage="Confirm Pin Number"
                  />
                }
                error={errors.confirm_pin_number}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage id="hospitals" defaultMessage="Hospitals" />
                }
                name="hospitals"
                control={control}
                multiple
                url={endpoints.hospitals}
                getQuery={(inputValue, selected) => ({
                  ...(inputValue && { name: inputValue }),
                  ...(selected && { id: selected }),
                })}
                handleData={(data) => ({
                  label: data.name,
                  value: data.id,
                })}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
