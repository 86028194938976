import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
  DialogActions,
} from "@material-ui/core";
import format from "date-fns/format";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "25rem",
    height: "100%",
  },
  innerWrapper: {
    padding: "1rem",
    display: "grid",
    height: "100%",
    // gridAutoRows: "min-content",
    gridTemplateRows: "min-content min-content min-content 1fr min-content",
  },
  footer: {
    marginTop: "-12px",
    // borderTop: "1px solid grey",
    paddingTop: "1rem",
  },
}));

const BankTransfer = ({ data, setData }) => {
  const s = useStyles();
  if (!data) return null;
  return (
    <Dialog open={!!data} maxWidth="xl">
      <DialogContent>
        <Paper className={s.wrapper}>
          <Grid container spacing={3} className={s.innerWrapper}>
            <Grid item sm={12}>
              <Grid container spacing={2} align="center">
                <Grid item sm={12}>
                  <Typography variant="h4">{data.hospital?.name}</Typography>
                  {/* <Typography variant="body1">
                    {data.hospital?.email}
                  </Typography> */}
                  <Typography variant="body1">Bank Transfer</Typography>
                </Grid>
              </Grid>
            </Grid>

            <hr style={{ width: "100%" }} />

            <Grid item container spacing={2}>
              <Row
                label="Date"
                value={format(new Date(data.createdAt), "dd MMM yyyy hh:mma")}
              />
              <Row label="Name" value={data.hospital.name} />
              <Row
                label="Amount"
                value={"₦" + (data.amount || 0)?.toLocaleString()}
              />
              <Row label="Status" value={data.status} />
            </Grid>

            <hr style={{ width: "100%", opacity: 0.25 }} />

            <Grid item container spacing={2}>
              <Row label="Account Name" value={data.destination_account_name} />
              <Row label="Bank" value={data.destination_bank_name} />
              <Row
                label="Account Number"
                value={data.destination_account_number}
              />
            </Grid>

            <hr style={{ width: "100%" }} />

            <Grid item sm={12}>
              <Grid container justify="center" className={s.footer}>
                <Grid item>
                  <Typography variant="body1" align="center">
                    Powered by VHMS 247
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="contained" onClick={() => setData(null)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Row = ({ label, value }) => {
  return (
    <Grid item container sm={12}>
      <Grid item sm={6}>
        <Typography variant="body2">{label}:</Typography>
      </Grid>
      <Grid item sm={6}>
        <Typography variant="body2" align="right">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BankTransfer;
