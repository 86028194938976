const appConfig = {
  appName: "VHMS", // Brand Name

  maxIdleTime: 1000 * 60 * 10, // 10 minute

  //custon variables
  siteTitle: "VHSM",

  tokenKey: "access_token",
  pageSize: 10,
  dateFormat: "DD MMM YYYY",
  dateTimeFormat: "DD MMM YYYY hh:mma",
  platform: "Unknown OS",
};

if (navigator.appVersion.indexOf("Win") != -1) appConfig.platform = "Windows";
if (navigator.appVersion.indexOf("Mac") != -1) appConfig.platform = "MacOS";
if (navigator.appVersion.indexOf("X11") != -1) appConfig.platform = "UNIX";
if (navigator.appVersion.indexOf("Linux") != -1) appConfig.platform = "Linux";

Array.prototype.findUniqueObj = function () {
  return [...new Set(this.map((obj) => JSON.stringify(obj)))].map((obj) =>
    JSON.parse(obj)
  );
};

export default appConfig;
