import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Datagrid } from "components";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Detail = ({ label, value }) => {
  return (
    <Grid item sm={6}>
      <Typography variant="body2">{label}</Typography>
      <Typography variant="body1">{value}</Typography>
    </Grid>
  );
};

const View = ({ hospital, close }) => {
  const s = useStyles();

  if (!hospital) return null;

  return (
    <Dialog open={!!hospital}>
      <DialogTitle>
        <FormattedMessage id="viewHospital" defaultMessage="View Hospital" />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Detail
            label={<FormattedMessage id="name" defaultMessage="Name" />}
            value={hospital.name}
          />

          <Detail
            label={<FormattedMessage id="phone" defaultMessage="Phone" />}
            value={hospital.phone}
          />

          <Detail
            label={<FormattedMessage id="email" defaultMessage="Email" />}
            value={hospital.email}
          />

          <Detail
            label={<FormattedMessage id="about" defaultMessage="About" />}
            value={hospital.about}
          />

          <Detail
            label={<FormattedMessage id="status" defaultMessage="Status" />}
            value={hospital.status}
          />

          <Grid item sm={12}>
            <Typography variant="h5">Stakeholders</Typography>
            <Datagrid
              columns={[
                {
                  field: "name",
                  headerName: "Hospital",
                },
                {
                  field: "commission",
                  headerName: "Commission",
                  renderCell: ({ row }) => (row.commission || 0) + "%",
                },
              ]}
              actions={false}
              rows={hospital.stakeholders || []}
              hideFooterPagination={true}
            />
          </Grid>

          <Grid item sm={12} className={s.btns}>
            <Button type="button" variant="contained" onClick={() => close()}>
              <FormattedMessage id="close" defaultMessage="Close" />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default View;
