import React, { useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Confirm } from "../Modal";
import Permission from "components/Permission/Permission";

function ActionMenu({ icon, tpTitle = "", disabled, menuItems, children }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disabled={disabled}
        onClick={handleClick}
      >
        {icon || <MoreVertIcon />}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems?.length
          ? menuItems.map((item, i) => {
              const menuItem = item.confirmation ? (
                <ConfirmMenuItem item={item} closeMenu={handleClose} key={i} />
              ) : (
                <MenuItem
                  key={i}
                  onClick={() => {
                    item.onClick();
                    handleClose();
                  }}
                >
                  {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                  {item.label && <ListItemText>{item.label}</ListItemText>}
                </MenuItem>
              );

              if (item.permissiom) {
                return (
                  <Permission permission={item.permissiom}>
                    {menuItem}
                  </Permission>
                );
              }
              return menuItem;
            })
          : children}
      </Menu>
    </div>
  );
}

const ConfirmMenuItem = ({ item, closeMenu }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen(true);
        }}
      >
        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
        {item.label && <ListItemText>{item.label}</ListItemText>}
      </MenuItem>
      <Confirm
        open={open}
        close={() => {
          closeMenu();
          setOpen(false);
        }}
        title={item.confirmationTitle || item.label}
        message={item.confirmation}
        onConfirm={() => {
          item.onClick();
          closeMenu();
        }}
      />
    </>
  );
};

export default ActionMenu;
