import { useState } from "react";
import { Wrapper, Datagrid, DeleteActionIcon, Permission } from "components";

import { Typography, IconButton, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { NotificationManager } from "react-notifications";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import { useFetch } from "hooks";
import format from "date-fns/format";
import Form from "./Form";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
}));

const Users = () => {
  const classes = useStyles();
  const [edit, setEdit] = useState(null);
  const [filters, setFilters] = useState({});
  const { remove: deleteItem, loading: deleting } = useFetch(
    endpoints.emailTemplates
  );
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="emailTemplates"
                    defaultMessage="Email Templates"
                  />
                </Typography>
                <Permission permission="add_email_template">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setEdit(true)}
                  >
                    <FormattedMessage
                      id="addEmailTemplate"
                      defaultMessage="Add Email Template"
                    />
                  </Button>
                </Permission>
              </Grid>
              <Datagrid
                url={endpoints.emailTemplates}
                columns={[
                  {
                    headerName: (
                      <FormattedMessage
                        id="createdAt"
                        defaultMessage="Created At"
                      />
                    ),
                    field: "createdAt",
                    renderCell: ({ row }) =>
                      format(new Date(row.createdAt), "dd MMM yyyy hh:mma"),
                  },
                  {
                    headerName: (
                      <FormattedMessage id="subject" defaultMessage="Subject" />
                    ),
                    field: "subject",
                  },
                  {
                    headerName: (
                      <FormattedMessage id="body" defaultMessage="Body" />
                    ),
                    field: "body",
                  },
                  {
                    headerName: (
                      <FormattedMessage id="action" defaultMessage="Action" />
                    ),
                    field: "action",
                    renderCell: ({ row }) => (
                      <>
                        <Permission permission="update_email_templates">
                          <IconButton
                            aria-label={
                              <FormattedMessage
                                id="editEmailTemplate"
                                defaultMessage="Edit Email Template"
                              />
                            }
                            onClick={() => setEdit(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Permission>
                        <Permission permission="remove_email_templates">
                          <DeleteActionIcon
                            disabled={deleting}
                            title={
                              <FormattedMessage
                                id="deleteEmailTemplate"
                                defaultMessage="Delete Email Template"
                              />
                            }
                            deleteRecord={() => {
                              deleteItem({ ids: [row.id] })
                                .then(({ data }) => {
                                  if (data?.success) {
                                    setFilters((prev) => ({ ...prev }));
                                    return NotificationManager.success(
                                      data.message
                                    );
                                  }
                                  NotificationManager.error(data.message);
                                })
                                .catch((err) =>
                                  NotificationManager.error(err.message)
                                );
                            }}
                          />
                        </Permission>
                      </>
                    ),
                  },
                ]}
                filters={filters}
              />
            </Grid>
          </Grid>
        </Grid>
        <Form
          open={!!edit}
          edit={edit}
          setEdit={setEdit}
          onSuccess={(newItem) => {
            setFilters((prev) => ({ ...prev }));
            setEdit(null);
          }}
        />
      </Wrapper>
    </>
  );
};

export default Users;
