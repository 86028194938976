import React, { useState, useEffect } from "react";
import { Wrapper, Datagrid, Permission } from "../../components";

import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import Filters from "./Filters";
import { FormattedMessage } from "react-intl";
import { useFetch } from "hooks";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const Hospitals = () => {
  const [filters, setFilters] = useState({});
  const [data, setData] = useState({});
  const { get } = useFetch(endpoints.reports);
  const classes = useStyles();

  useEffect(() => {
    get({ query: filters })
      .then(({ data }) => {
        if (data?.success) {
          setData(data.data);
        }
      })
      .catch((err) => console.log(err));
  }, [filters]);
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage id="reports" defaultMessage="Reports" />
                </Typography>
              </Grid>

              <Grid item>
                <Card>
                  <CardContent>
                    <Filters filters={filters} setFilters={setFilters} />
                  </CardContent>
                </Card>
              </Grid>

              <Grid item container sm={12} spacing={1}>
                <Grid item sm={6}>
                  <Datagrid
                    rows={data?.activity || []}
                    pagination={false}
                    columns={[
                      {
                        headerName: (
                          <FormattedMessage id="label" defaultMessage="Label" />
                        ),
                        field: "label",
                      },
                      {
                        headerName: (
                          <FormattedMessage id="value" defaultMessage="Value" />
                        ),
                        field: "value",
                        width: 130,
                        type: "number",
                      },
                    ]}
                  />
                </Grid>

                <Grid item sm={6}>
                  <Datagrid
                    rows={data?.earnings || []}
                    pagination={false}
                    columns={[
                      {
                        headerName: (
                          <FormattedMessage id="label" defaultMessage="Label" />
                        ),
                        field: "label",
                      },
                      {
                        headerName: (
                          <span style={{ marginLeft: "auto", marginRight: 0 }}>
                            <FormattedMessage
                              id="value"
                              defaultMessage="Value"
                            />
                          </span>
                        ),
                        field: "value",
                        width: 130,
                        // type: "number",
                        renderCell: ({ row }) => (
                          <span style={{ marginLeft: "auto", marginRight: 0 }}>
                            ₦{(row.value || 0).toLocaleString()}
                          </span>
                        ),
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default Hospitals;
