import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import { TextField, SelectField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const schema = yup.object({
  bank_code: yup
    .mixed()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  account_number: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  amount: yup
    .number()
    .min(1, "Can't transfer less than 1")
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  narration: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  chips: {
    padding: "1rem",
  },
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, close, onSuccess }) => {
  const s = useStyles();
  const [banks, setBanks] = useState([]);
  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({ resolver: useYup(schema) });
  const { post, loading } = useFetch(endpoints.initBankTransfer);
  const { get: getBanks, loading: loadingBanks } = useFetch(endpoints.banks);

  useEffect(() => {
    reset({});
  }, [open]);
  useEffect(() => {
    getBanks()
      .then(({ data }) => {
        if (data.success) {
          setBanks(data.data);
        }
      })
      .catch((err) => {
        NotificationManager.error(err.message);
      });
  }, [open]);
  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedMessage
          id="initBankTransfer"
          defaultMessage="Initiate Bank Transfer"
        />
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            post({
              ...values,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <SelectField
                label={<FormattedMessage id="bank" defaultMessage="Bank" />}
                name="bank_code"
                control={control}
                options={banks.map((bank) => ({
                  label: bank.name,
                  value: bank.code,
                }))}
                onChange={(e) => {
                  if (e) {
                    setValue("bank_name", e.label);
                  } else {
                    setValue("bank_name", "");
                  }
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("account_number")}
                label={
                  <FormattedMessage
                    id="account_number"
                    defaultMessage="Account Number"
                  />
                }
                error={errors.account_number}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("amount")}
                label={<FormattedMessage id="amount" defaultMessage="Amount" />}
                error={errors.amount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₦</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("narration")}
                label={
                  <FormattedMessage id="narration" defaultMessage="Narration" />
                }
                error={errors.narration}
              />
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => close(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
