import { useState } from "react";
import { Wrapper, Datagrid, Permission, ActionMenu } from "components";

import { Typography, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import PrintIcon from "@material-ui/icons/Print";
import DeleteIcon from "@material-ui/icons/Delete";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import format from "date-fns/format";
import Form from "./Form";
import Prescription from "./Prescription";
import Filters from "./Filters";
import { useAppState } from "components/AppProvider/AppProvider";
import { useFetch } from "hooks";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import DispensarySaleForm from "../DispensarySales/Form";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
  status: {
    textTransform: "capitalize",
  },
}));

const calculateQty = (drug) => {
  // dosage_forms: Tablet, Capsule, Syrup, Rectal, Nesal Drop, Ear Drop, Ointment, Cream,
  if (["Capsule", "Tablet"].includes(drug.dosage_form)) {
    return (
      drug.dose_interval.split("-").reduce((p, c) => p + +c, 0) *
        (parseFloat(drug.dose) || 1) *
        drug.duration || 0
    );
  } else {
    return 1;
  }
};

const Prescriptions = () => {
  const { authUser } = useAppState();
  const classes = useStyles();
  const [edit, setEdit] = useState(null);
  const [view, setView] = useState(null);
  const [generateSale, setGenerateSale] = useState(null);
  const [filters, setFilters] = useState({});
  const { remove, loading } = useFetch(endpoints.prescriptions);
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="prescriptions"
                    defaultMessage="Prescriptions"
                  />
                </Typography>
                <Permission permission="add_prescription">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setEdit(true)}
                  >
                    <FormattedMessage
                      id="addPrescription"
                      defaultMessage="Add Prescription"
                    />
                  </Button>
                </Permission>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters
                      filters={filters}
                      setFilters={setFilters}
                      authUser={authUser}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Datagrid
                  url={endpoints.prescriptions}
                  filters={filters}
                  columns={[
                    {
                      headerName: (
                        <FormattedMessage id="date" defaultMessage="Date" />
                      ),
                      field: "date",
                      renderCell: ({ row }) =>
                        format(new Date(row.date), "dd MMM yyyy"),
                    },
                    ...(authUser.user_type === "admin"
                      ? [
                          {
                            headerName: (
                              <FormattedMessage
                                id="hospital"
                                defaultMessage="Hospital"
                              />
                            ),
                            field: "hospital.name",
                            renderCell: ({ row }) => row.hospital?.name,
                          },
                        ]
                      : []),
                    {
                      headerName: (
                        <FormattedMessage
                          id="patient"
                          defaultMessage="Patient"
                        />
                      ),
                      field: "user.name",
                      renderCell: ({ row }) => row.user?.name,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="doctor" defaultMessage="Doctor" />
                      ),
                      field: "doctor.name",
                      renderCell: ({ row }) => row.doctor?.name,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="action" defaultMessage="Action" />
                      ),
                      field: "action",
                      width: 100,
                      renderCell: ({ row }) => (
                        <ActionMenu
                          menuItems={[
                            {
                              icon: <PrintIcon />,
                              label: (
                                <FormattedMessage
                                  id="printPrescription"
                                  defaultMessage="Print Prescription"
                                />
                              ),
                              onClick: () => setView(row),
                              permission: "print_prescriptions",
                            },
                            {
                              icon: <EditIcon />,
                              label: (
                                <FormattedMessage
                                  id="editPrescription"
                                  defaultMessage="Edit Prescription"
                                />
                              ),
                              onClick: () => setEdit(row),
                              permission: "update_prescriptions",
                            },
                            {
                              icon: <DeleteIcon />,
                              label: (
                                <FormattedMessage
                                  id="deletePrescription"
                                  defaultMessage="Delete Prescription"
                                />
                              ),
                              onClick: () => {
                                remove({ ids: row.id })
                                  .then(({ data }) => {
                                    if (data.success) {
                                      setFilters((prev) => ({ ...prev }));
                                    }
                                  })
                                  .catch((err) => {
                                    NotificationManager.error(err.message);
                                  });
                              },
                              permission: "remove_prescriptions",
                              confirmation: (
                                <FormattedMessage
                                  id="deleteConfirmation_prescription"
                                  defaultMessage="Are you sure want to remove this prescription?"
                                />
                              ),
                            },
                            {
                              icon: <ReceiptIcon />,
                              label: (
                                <FormattedMessage
                                  id="generateSale"
                                  defaultMessage="Generate Sale"
                                />
                              ),
                              onClick: () =>
                                setGenerateSale({
                                  user_id: row.user?.id || row.user_id,
                                  products: row.drugs.map((item) => ({
                                    drug_id: item.drug,
                                    name: item.brand_name,
                                    qty: calculateQty(item),
                                    cost: item.price || 0,
                                    discount: 0,
                                  })),
                                  hospital: row.hospital,
                                  generate_sale: true,
                                }),
                              permission: "add_dispensary_sale",
                            },
                          ]}
                        />
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Form
          authUser={authUser}
          open={!!edit}
          edit={edit}
          setEdit={setEdit}
          onSuccess={(newSubPackage) => {
            setFilters((prev) => ({ ...prev }));
            setEdit(null);
          }}
        />
        <DispensarySaleForm
          authUser={authUser}
          open={!!generateSale}
          edit={generateSale}
          setEdit={setGenerateSale}
          onSuccess={(newSubPackage) => {
            NotificationManager.success(
              <FormattedMessage
                id="saleGenerated"
                defaultMessage="Sale generated successfully."
              />
            );
            setGenerateSale(null);
          }}
        />

        <Prescription prescription={view} setPrescription={setView} />
      </Wrapper>
    </>
  );
};

export default Prescriptions;
