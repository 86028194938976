import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  InputLabel,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { TextField, SelectField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import { useState } from "react";

const roleSchema = yup.object({
  hospital_id: yup.string().nullable(),
  label: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  permissions: yup
    .array()
    .of(yup.string())
    .min(1)
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
  groups: {
    listStyle: "none",
    padding: 0,
    display: "grid",
    gridGap: "1.5rem",
    gridTemplateColumns: "1fr 1fr",
  },
  permissions: {
    listStyle: "none",
    paddingLeft: "0rem",
  },
}));

const Form = ({ open, edit, setEdit, authUser, groups, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(roleSchema) });
  const {
    post: addDoc,
    put: updateDoc,
    loading,
  } = useFetch(endpoints.roles + `/${edit?.id || ""}`);

  const permissions = watch("permissions");

  useEffect(() => {
    reset({
      label: edit?.label || "",
      hospital_id:
        authUser.user_type === "hospital"
          ? authUser.id
          : edit?.hospital?.id || null,
      permissions: edit?.permissions || [],
    });
  }, [edit]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage id="editRole" defaultMessage="Edit Role" />
        ) : (
          <FormattedMessage id="addRole" defaultMessage="Add Role" />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          key={edit ? "edit" : "add"}
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            (edit?.id ? updateDoc : addDoc)({
              ...values,
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            {authUser.user_type === "admin" && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage id="hospital" defaultMessage="Hospital" />
                  }
                  name="hospital_id"
                  control={control}
                  url={endpoints.hospitals}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                  })}
                  disabled={!!edit?.id}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("label")}
                label={<FormattedMessage id="name" defaultMessage="Name" />}
                error={errors.label}
              />
            </Grid>

            <Grid item sm={12}>
              <InputLabel>Permissions</InputLabel>
              <ul className={s.groups}>
                {groups.map((group) => (
                  <li key={group.label} className={s.group}>
                    <Typography variant="h5">{group.label}</Typography>
                    <ul className={s.permissions}>
                      {group.permissions.map((p) => (
                        <li key={p.label}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permissions.includes(p.id)}
                                onChange={(v) => {
                                  if (permissions.includes(p.id)) {
                                    setValue(
                                      "permissions",
                                      (permissions || []).filter(
                                        (item) => item !== p.id
                                      )
                                    );
                                  } else {
                                    setValue("permissions", [
                                      ...(permissions || []),
                                      p.id,
                                    ]);
                                  }
                                }}
                              />
                            }
                            label={p.label}
                          />
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </Grid>

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
