import React, { useState } from "react";
import { Wrapper, Datagrid, ActionIcon, Permission } from "components";

import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import Form from "./Form";
import Filters from "./Filters";
import { FormattedMessage } from "react-intl";
import { format } from "date-fns";
import { useAppState } from "components/AppProvider/AppProvider";
import { useFetch } from "hooks";
import { getUserLocation } from "helpers";
import { NotificationManager } from "react-notifications";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
}));

const Hospitals = () => {
  const { authUser } = useAppState();
  const [filters, setFilters] = useState({});
  const classes = useStyles();
  const [chekcoutUrl, setCheckoutUrl] = useState(null);
  const [edit, setEdit] = useState(null);
  const { post: makePayment, loading: makingPayment } = useFetch(
    endpoints.refHospital.makeReferralPayment
  );
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="hospitalRefs"
                    defaultMessage="Hospital Referrals"
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters filters={filters} setFilters={setFilters} />
                  </CardContent>
                </Card>
              </Grid>
              <Datagrid
                url={
                  authUser.user_type === "ref_user"
                    ? endpoints.refHospital.hospitalRefs
                    : endpoints.hospitalRefs
                }
                filters={filters}
                columns={[
                  {
                    headerName: (
                      <FormattedMessage
                        id="createdAt"
                        defaultMessage="Created At"
                      />
                    ),
                    field: "createdAt",
                    renderCell: ({ row }) =>
                      format(new Date(row.createdAt), "dd MMM yyyy hh:mma"),
                  },
                  ...(authUser.user_type === "admin"
                    ? [
                        {
                          headerName: (
                            <FormattedMessage
                              id="hospital"
                              defaultMessage="Hospital"
                            />
                          ),
                          field: "referral_hospital.name",
                          renderCell: ({ row }) => row.referral_hospital?.name,
                        },
                      ]
                    : []),
                  {
                    headerName: (
                      <FormattedMessage id="patient" defaultMessage="Patient" />
                    ),
                    field: "user.name",
                    renderCell: ({ row }) => row.user?.name,
                  },
                  {
                    headerName: (
                      <FormattedMessage
                        id="referrer"
                        defaultMessage="Referrer"
                      />
                    ),
                    field: "doctor.name",
                    renderCell: ({ row }) => row.doctor?.name,
                  },
                  {
                    headerName: (
                      <FormattedMessage id="status" defaultMessage="Status" />
                    ),
                    field: "status",
                  },
                  {
                    headerName: (
                      <FormattedMessage
                        id="paymentStatus"
                        defaultMessage="paymentStatus"
                      />
                    ),
                    field: "payment_status",
                    renderCell: ({ row }) =>
                      row.payment_status === "paid"
                        ? `${row.payment_status} (₦${row.paid_amount || 0})`
                        : row.payment_status,
                  },
                  {
                    headerName: (
                      <FormattedMessage id="action" defaultMessage="Action" />
                    ),
                    field: "action",
                    renderCell: ({ row }) => (
                      <>
                        <Permission permission="update_hospital_refs">
                          <IconButton
                            aria-label={
                              <FormattedMessage
                                id="editHospitalRef"
                                defaultMessage="Edit Hospital Referral"
                              />
                            }
                            onClick={() => setEdit(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Permission>
                        <Permission permission="make_hospital_refs_payment">
                          {authUser.user_type === "ref_hospital" &&
                            row.payment_status === "pending" && (
                              <ActionIcon
                                icon={<MonetizationOnIcon />}
                                disabled={makingPayment}
                                confirmation={{
                                  title: (
                                    <FormattedMessage
                                      id="makePayment"
                                      defaultMessage="Make Payment"
                                    />
                                  ),
                                  message: (
                                    <FormattedMessage
                                      id="confirmationMessage_makePayment"
                                      defaultMessage="Are you sure you want to make this payment?"
                                    />
                                  ),
                                  action: async () => {
                                    const { position, error } =
                                      await getUserLocation();

                                    if (error) {
                                      return NotificationManager.error(
                                        error.message
                                      );
                                    }
                                    makePayment({
                                      referral_id: row.id,
                                      latitude: position?.latitude,
                                      longitude: position?.longitude,
                                      redirect_url: window.location.href,
                                    })
                                      .then(({ data }) => {
                                        if (data.success) {
                                          setCheckoutUrl(
                                            data.data.checkout_url
                                          );
                                        } else {
                                          NotificationManager.error(
                                            data.message
                                          );
                                        }
                                        console.log(data);
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  },
                                }}
                              />
                            )}
                        </Permission>
                      </>
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Form
          open={!!edit}
          edit={edit}
          setEdit={setEdit}
          onSuccess={(newHospital) => {
            setFilters((prev) => ({ ...prev }));
            setEdit(null);
          }}
        />
      </Wrapper>

      <Dialog open={!!chekcoutUrl}>
        <DialogTitle>Checkout</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Complete this transaction within next 30 minutes
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setCheckoutUrl(null)}>
            Cancel
          </Button>
          <a href={chekcoutUrl}>
            <Button variant="contained" color="primary">
              Proceed to Chekcout
            </Button>
          </a>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Hospitals;
