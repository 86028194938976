import { useRef } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
  DialogActions,
} from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import format from "date-fns/format";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "25rem",
    height: "100%",
  },
  innerWrapper: {
    padding: "1rem",
    display: "grid",
    height: "100%",
    // gridAutoRows: "min-content",
    gridTemplateRows: "min-content min-content min-content 1fr min-content",
  },
  footer: {
    marginTop: "-12px",
    // borderTop: "1px solid grey",
    paddingTop: "1rem",
  },
}));

const Prescription = ({ prescription, setPrescription }) => {
  const s = useStyles();

  const printRef = useRef();
  const handlePrint = useReactToPrint({ content: () => printRef.current });
  if (!prescription) {
    return null;
  }
  return (
    <Dialog open={!!prescription} maxWidth="xl">
      <DialogContent>
        <Paper className={s.wrapper}>
          <Grid container spacing={3} className={s.innerWrapper} ref={printRef}>
            <Grid item sm={12}>
              <Grid container spacing={2} align="center">
                <Grid item sm={12}>
                  <Typography variant="h4">
                    {prescription.owner_hospital?.name ||
                      prescription.hospital?.name}
                  </Typography>
                  <Typography variant="body1">
                    {prescription.owner_hospital?.email ||
                      prescription.hospital?.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <hr style={{ width: "100%" }} />

            <Grid item container spacing={2}>
              <Row
                label="Date"
                value={format(
                  new Date(prescription.createdAt),
                  "dd MMM yyyy hh:mma"
                )}
              />
              <Row label="Type" value={prescription.type} />
              <Row label="Status" value={prescription.status} />
              <Row
                label="Name"
                value={prescription[prescription.user_type].name}
              />
              <Row
                label="Amount"
                value={"₦" + (prescription.amount || 0)?.toLocaleString()}
              />
            </Grid>

            <hr style={{ width: "100%" }} />

            <Grid item sm={12}>
              <Grid container justify="center" className={s.footer}>
                <Grid item>
                  <Typography variant="body1" align="center">
                    Powered by VHMS 247
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="contained"
          onClick={() => setPrescription(null)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Row = ({ label, value }) => {
  return (
    <Grid item container sm={12}>
      <Grid item sm={6}>
        <Typography variant="body2">{label}:</Typography>
      </Grid>
      <Grid item sm={6}>
        <Typography variant="body2" align="right">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Prescription;
