import { Button, Grid, makeStyles } from "@material-ui/core";
import { TextField, SelectField } from "components";
import { useForm } from "react-hook-form";
import { useYup } from "hooks";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import { endpoints } from "appConfigs";

const schema = yup.object({
  name: yup.string(),
  phone: yup.string(),
  email: yup
    .string()
    .email(
      <FormattedMessage
        id="validationEmail"
        defaultMessage="Enter a valid email"
        values={{ num: 1 }}
      />
    ),
  status: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    // margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-start",
  },
}));

const Form = ({ filters, setFilters }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(schema) });
  return (
    <Grid item className="mb-1">
      <form
        autoComplete={"off"}
        onSubmit={handleSubmit(async (values) => {
          const query = {};
          Object.entries(values).forEach(([key, value]) => {
            if (value) {
              query[key] = value;
            }
          });
          setFilters(query);
        })}
      >
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <SelectField
              label={<FormattedMessage id="doctors" defaultMessage="Doctors" />}
              name="doctor_id"
              control={control}
              multiple
              url={endpoints.doctors}
              getQuery={(inputValue, selected) => ({
                ...(inputValue && { name: inputValue }),
                ...(selected && { id: selected }),
              })}
              handleData={(data) => ({
                label: data.name,
                value: data.id,
              })}
            />
          </Grid>

          <Grid item sm={4}>
            <SelectField
              label={
                <FormattedMessage id="patients" defaultMessage="Patients" />
              }
              name="user_id"
              control={control}
              multiple
              url={endpoints.users}
              getQuery={(inputValue, selected) => ({
                ...(inputValue && { name: inputValue }),
                ...(selected && { id: selected }),
              })}
              handleData={(data) => ({
                label: data.name,
                value: data.id,
              })}
            />
          </Grid>

          <Grid item sm={3}>
            <SelectField
              label={<FormattedMessage id="status" defaultMessage="Status" />}
              name="status"
              control={control}
              options={[
                { label: "Pending", value: "pending" },
                { label: "Booked", value: "booked" },
                { label: "Canceled", value: "canceled" },
                { label: "Complete", value: "complete" },
              ]}
            />
          </Grid>

          <Grid item sm={3} className={s.btns}>
            <Button type="submit" variant="contained" color="primary">
              <FormattedMessage id="search" defaultMessage="Search" />
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                setFilters({});
                reset();
              }}
            >
              <FormattedMessage id="clear" defaultMessage="Clear" />
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default Form;
