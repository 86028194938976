import { paths } from "appConfigs";

// Pages
import {
  Doctors,
  Pharmacists,
  Users,
  Home,
  Appointments,
  SubscriptionPackages,
  Subscriptions,
  HealthGroups,
  HealthGroupSubs,
  HealthGroupSessions,
  EmailTemplates,
  Departments,
  GeneralSettings,
  Stakeholders,
  Nurses,
  ApiKeys,
  Hospitals,
  RefHospitals,
  Profile,
  Drugs,
  Prescriptions,
  Casefiles,
  HospitalRefs,
  Salaries,
  // Payouts,
  Roles,
  Reports,
  ServiceProviders,
  Dispensaries,
  DispensarySuppliers,
  DispensaryPurchases,
  DispensarySales,
  Transactions,
  BankTransfers,
  FundWallets,
} from "./pages";

import SettingsIcon from "@material-ui/icons/Settings";
// Icons
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalConvenienceStoreIcon from "@material-ui/icons/LocalConvenienceStore";
import TodayIcon from "@material-ui/icons/Today";
import EventNoteIcon from "@material-ui/icons/EventNote";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ReceiptIcon from "@material-ui/icons/Receipt";
import FolderIcon from "@material-ui/icons/Folder";
import LinkIcon from "@material-ui/icons/Link";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AssessmentIcon from "@material-ui/icons/Assessment";
import MoneyIcon from "@material-ui/icons/Money";

export default {
  items: [
    {
      path: "/",
      name: { id: "dashboard", defaultMessage: "Dashboard" },
      type: "link",
      icon: DashboardIcon,
      component: Home,
      permission: "view_dashboard",
    },
    {
      path: paths.clients.basePath,
      name: { id: "clients", defaultMessage: "Clients" },
      type: "submenu",
      icon: PeopleAltIcon,
      // permission: 'view_clients',
      children: [
        {
          path: paths.clients.hospitals,
          name: { id: "hospitals", defaultMessage: "Hospitals" },
          component: Hospitals,
          permission: "view_hospitals",
        },
        {
          path: paths.clients.doctors,
          name: { id: "doctors", defaultMessage: "Doctors" },
          component: Doctors,
          permission: "view_doctors",
        },
        {
          path: paths.clients.pharmacists,
          name: { id: "pharmacists", defaultMessage: "Pharmacists" },
          component: Pharmacists,
          permission: "view_pharmacists",
        },
        {
          path: paths.clients.users,
          name: { id: "users", defaultMessage: "Users" },
          component: Users,
          permission: "view_users",
        },
        {
          path: paths.clients.nurses,
          name: { id: "nurses", defaultMessage: "Nurses" },
          component: Nurses,
          permission: "view_nurses",
        },
        {
          path: paths.clients.stakeholders,
          name: { id: "stakeholders", defaultMessage: "Stakeholders" },
          component: Stakeholders,
          permission: "view_stakeholders",
        },
        {
          path: paths.clients.refHospitals,
          name: { id: "refHospitals", defaultMessage: "Referral Hospitals" },
          component: RefHospitals,
          permission: "view_ref_hospitals",
        },
      ],
    },
    {
      path: paths.appointments,
      name: { id: "appointments", defaultMessage: "Appointments" },
      type: "link",
      icon: TodayIcon,
      component: Appointments,
      permission: "view_appointments",
    },
    {
      path: paths.subscriptionPackages,
      name: { id: "subPackages", defaultMessage: "Subscription Packages" },
      type: "link",
      icon: EventNoteIcon,
      component: SubscriptionPackages,
      permission: "view_sub_packages",
    },
    {
      path: paths.subscriptions,
      name: { id: "subscriptions", defaultMessage: "Subscriptions" },
      type: "link",
      icon: DateRangeIcon,
      component: Subscriptions,
      permission: "view_subs",
    },
    {
      path: paths.healthGroups,
      name: { id: "healthGroups", defaultMessage: "Health Groups" },
      type: "link",
      icon: LocalHospitalIcon,
      component: HealthGroups,
      permission: "view_health_groups",
    },
    {
      path: paths.healthGroupSubs,
      name: {
        id: "healthGroupSubs",
        defaultMessage: "Health Group Subscriptions",
      },
      type: "link",
      icon: DateRangeIcon,
      component: HealthGroupSubs,
      permission: "view_health_group_subs",
    },
    {
      path: paths.healthGroupSessions,
      name: {
        id: "healthGroupSessions",
        defaultMessage: "Health Group Sessions",
      },
      type: "link",
      icon: EventAvailableIcon,
      component: HealthGroupSessions,
      permission: "view_health_group_sessions",
    },
    // {
    //   path: paths.drugs,
    //   name: { id: "drugs", defaultMessage: "Drugs" },
    //   type: "link",
    //   icon: ExploreIcon,
    //   component: Drugs,
    //   permission: "view_drugs",
    // },
    {
      path: paths.prescriptions,
      name: { id: "prescriptions", defaultMessage: "Prescriptions" },
      type: "link",
      icon: InsertDriveFileIcon,
      component: Prescriptions,
      permission: "view_prescriptions",
    },
    {
      path: paths.transactions,
      name: { id: "transactions", defaultMessage: "Transactions" },
      type: "link",
      icon: ReceiptIcon,
      component: Transactions,
      // permission: "view_transactions",
    },
    {
      path: paths.bankTransfers,
      name: { id: "bankTransfers", defaultMessage: "Bank Transfers" },
      type: "link",
      icon: AccountBalanceIcon,
      component: BankTransfers,
      permission: "view_bank_transfers",
    },
    {
      path: paths.fundWallets,
      name: { id: "fundWallets", defaultMessage: "Fund Wallets" },
      type: "link",
      icon: MoneyIcon,
      component: FundWallets,
      permission: "view_fund_wallets",
    },
    {
      path: paths.casefiles,
      name: { id: "casefiles", defaultMessage: "Case Files" },
      type: "link",
      icon: FolderIcon,
      component: Casefiles,
      permission: "view_casefiles",
    },
    {
      path: paths.dispensary.basePath,
      name: { id: "dispensary", defaultMessage: "Dispensary" },
      type: "submenu",
      icon: LocalConvenienceStoreIcon,
      children: [
        {
          path: paths.dispensary.drugs,
          name: { id: "drugs", defaultMessage: "Drugs" },
          component: Drugs,
          permission: "view_drugs",
        },
        {
          path: paths.dispensary.dispensaries,
          name: { id: "dispensaries", defaultMessage: "Dispensaries" },
          component: Dispensaries,
          permission: "view_dispensaries",
        },
        {
          path: paths.dispensary.suppliers,
          name: {
            id: "suppliers",
            defaultMessage: "Suppliers",
          },
          component: DispensarySuppliers,
          permission: "view_dispensary_suppliers",
        },
        {
          path: paths.dispensary.purchases,
          name: { id: "purchases", defaultMessage: "Purchases" },
          component: DispensaryPurchases,
          permission: "view_dispensary_purchases",
        },
        {
          path: paths.dispensary.sales,
          name: { id: "sales", defaultMessage: "Sales" },
          component: DispensarySales,
          permission: "view_dispensary_sales",
        },
      ],
    },
    {
      path: paths.hospitalRefs,
      name: { id: "hospitalRefs", defaultMessage: "Hospital Referrals" },
      type: "link",
      icon: LinkIcon,
      component: HospitalRefs,
      permission: "view_hospital_refs",
    },
    {
      path: paths.salaries,
      name: { id: "salaries", defaultMessage: "Salaries" },
      type: "link",
      icon: MonetizationOnIcon,
      component: Salaries,
      permission: "view_salaries",
    },
    // {
    //   path: paths.payouts,
    //   name: { id: "payouts", defaultMessage: "Payouts" },
    //   type: "link",
    //   icon: LocalAtmIcon,
    //   component: Payouts,
    //   permission: "view_payouts",
    // },
    {
      path: paths.reports,
      name: { id: "reports", defaultMessage: "Reports" },
      type: "link",
      icon: AssessmentIcon,
      component: Reports,
      permission: "view_reports",
    },
    {
      path: paths.settings.basePath,
      name: { id: "settings", defaultMessage: "Settings" },
      type: "submenu",
      icon: SettingsIcon,
      // permission: ["admin", "hospital"],
      children: [
        {
          path: paths.settings.emailTemplates,
          name: { id: "emailTemplates", defaultMessage: "Email Templates" },
          component: EmailTemplates,
          permission: "view_email_templates",
        },
        {
          path: paths.settings.departments,
          name: { id: "departments", defaultMessage: "Departments" },
          component: Departments,
          permission: "view_departments",
        },
        {
          path: paths.settings.general,
          name: { id: "generalSettings", defaultMessage: "General Settings" },
          component: GeneralSettings,
          permission: "view_general_settings",
        },
        {
          path: paths.settings.serviceProviders,
          name: { id: "serviceProviders", defaultMessage: "Service Providers" },
          component: ServiceProviders,
          permission: "view_service_providers",
        },
        {
          path: paths.settings.apiKeys,
          name: { id: "apiKeys", defaultMessage: "Api Keys" },
          component: ApiKeys,
          permission: "view_api_keys",
        },
        {
          path: paths.settings.roles,
          name: { id: "roles", defaultMessage: "Roles" },
          component: Roles,
          permission: "view_roles",
        },
        {
          path: paths.settings.profile,
          name: { id: "profile", defaultMessage: "Profile" },
          component: Profile,
          permission: "view_profile",
        },
      ],
    },
  ],
};
