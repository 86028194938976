import React, { useState } from "react";
import { Wrapper, Datagrid, Permission } from "../../components";

import { Typography, IconButton, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { useAppState } from "components/AppProvider/AppProvider";
import { endpoints } from "appConfigs";
import Form from "./Form";
import Filters from "./Filters";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
}));

const Users = () => {
  const { authUser } = useAppState();
  const classes = useStyles();
  const [edit, setEdit] = useState(null);
  const [filters, setFilters] = useState(null);
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage id="users" defaultMessage="Users" />
                </Typography>
                <Permission permission="add_user">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setEdit(true)}
                  >
                    <FormattedMessage id="addUser" defaultMessage="Add User" />
                  </Button>
                </Permission>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters
                      filters={filters}
                      setFilters={setFilters}
                      authUser={authUser}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Datagrid
                url={endpoints.users}
                filters={filters}
                columns={[
                  {
                    headerName: (
                      <FormattedMessage id="name" defaultMessage="Name" />
                    ),
                    field: "name",
                  },
                  {
                    headerName: (
                      <FormattedMessage id="age" defaultMessage="Age" />
                    ),
                    field: "age",
                  },
                  {
                    headerName: (
                      <FormattedMessage id="email" defaultMessage="Email" />
                    ),
                    field: "email",
                  },
                  {
                    headerName: (
                      <FormattedMessage id="phone" defaultMessage="Phone" />
                    ),
                    field: "phone",
                  },
                  ...(authUser.user_type === "admin"
                    ? [
                        {
                          headerName: (
                            <FormattedMessage
                              id="hospital"
                              defaultMessage="Hospital"
                            />
                          ),
                          field: "hospital.name",
                          renderCell: ({ row }) => row.hospital?.name,
                        },
                      ]
                    : []),
                  {
                    headerName: (
                      <FormattedMessage id="status" defaultMessage="Status" />
                    ),
                    field: "status",
                  },
                  {
                    headerName: (
                      <FormattedMessage id="action" defaultMessage="Action" />
                    ),
                    field: "action",
                    renderCell: ({ row }) => (
                      <>
                        <Permission permission="update_users">
                          <IconButton
                            aria-label={
                              <FormattedMessage
                                id="editUser"
                                defaultMessage="Edit User"
                              />
                            }
                            onClick={() => setEdit(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Permission>
                        {/* <DeleteActionIcon to={`${props.pageUrl}/add`} /> */}
                      </>
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Form
          authUser={authUser}
          open={!!edit}
          edit={edit}
          setEdit={setEdit}
          onSuccess={(user) => {
            setFilters((prev) => ({ ...prev }));
            setEdit(null);
          }}
        />
      </Wrapper>
    </>
  );
};

export default Users;
