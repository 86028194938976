import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import { TextField, SelectField } from "components";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { useYup, useFetch } from "hooks";
import { endpoints } from "appConfigs";
import { getUserLocation } from "helpers";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const schema = yup.object({
  hospital_id: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  generic_name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  brand_name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  description: yup
    .string()
    .max(
      250,
      <FormattedMessage
        id="validationMaxChar"
        defaultMessage="Can't be more than {num} characters"
        values={{ num: 250 }}
      />
    ),
  dosage_form: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  api_strength: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  price: yup
    .number()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  // api_strength: yup
  //   .array()
  //   .of(yup.number().required("api_strength must be an array of numbers"))
  //   .required("Field is required"),
});

const useStyles = makeStyles((theme) => ({
  chips: {
    padding: "1rem",
  },
  btns: {
    margin: "0 auto",
    marginBottom: "1rem",
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
  },
}));

const Form = ({ open, edit, setEdit, authUser, onSuccess }) => {
  const s = useStyles();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: useYup(schema) });
  const {
    post: addDrug,
    put: updateDrug,
    loading,
  } = useFetch(endpoints.drugs + `/${edit?.id || ""}`);

  useEffect(() => {
    reset({
      ...edit,
      hospital_id:
        authUser.user_type === "hospital"
          ? authUser.id
          : edit?.hospital?.id || "",
      api_strength: (edit?.api_strength || []).join(","),
    });
  }, [edit]);
  return (
    <Dialog open={open}>
      <DialogTitle>
        {edit?.id ? (
          <FormattedMessage id="editDrug" defaultMessage="Edit Drug" />
        ) : (
          <FormattedMessage id="addDrug" defaultMessage="Add Drug" />
        )}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(async (values) => {
            const { position, error } = await getUserLocation();
            if (error) {
              return NotificationManager.error(error);
            }
            (edit?.id ? updateDrug : addDrug)({
              ...values,
              api_strength: values.api_strength
                .split(",")
                .map((i) => +i.trim())
                .filter((i) => i),
              latitude: position?.latitude,
              longitude: position?.longitude,
            })
              .then(({ data }) => {
                if (data.success) {
                  NotificationManager.success(data.message);
                  onSuccess(data.data);
                } else {
                  NotificationManager.error(data.message);
                }
              })
              .catch((err) => {
                NotificationManager.error(err.message);
              });
          })}
        >
          <Grid container spacing={3}>
            {authUser.user_type === "admin" && (
              <Grid item sm={12}>
                <SelectField
                  label={
                    <FormattedMessage id="hospital" defaultMessage="Hospital" />
                  }
                  name="hospital_id"
                  control={control}
                  url={endpoints.hospitals}
                  getQuery={(inputValue, selected) => ({
                    ...(inputValue && { name: inputValue }),
                    ...(selected && { id: selected }),
                  })}
                  handleData={(data) => ({
                    label: data.name,
                    value: data.id,
                  })}
                />
              </Grid>
            )}

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("generic_name")}
                label={
                  <FormattedMessage
                    id="genericName"
                    defaultMessage="Generic Name"
                  />
                }
                error={errors.generic_name}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                {...register("brand_name")}
                label={
                  <FormattedMessage
                    id="brandName"
                    defaultMessage="Brand Name"
                  />
                }
                error={errors.brand_name}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("description")}
                label={
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                }
                error={errors.description}
              />
            </Grid>

            <Grid item sm={12}>
              <SelectField
                label={
                  <FormattedMessage
                    id="dosageForm"
                    defaultMessage="Dosage Form"
                  />
                }
                name="dosage_form"
                control={control}
                options={[
                  { label: "Tablet", value: "Tablet" },
                  { label: "Capsule", value: "Capsule" },
                  { label: "Syrup", value: "Syrup" },
                  { label: "Rectal", value: "Rectal" },
                  { label: "Nesal Drop", value: "Nesal Drop" },
                  { label: "Ear Drop", value: "Ear Drop" },
                  { label: "Ointment", value: "Ointment" },
                  { label: "Cream", value: "Cream" },
                ]}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("api_strength")}
                label={
                  <FormattedMessage
                    id="apiStrength"
                    defaultMessage="API strength"
                  />
                }
                error={errors.api_strength}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mg</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                {...register("price")}
                label={<FormattedMessage id="price" defaultMessage="Price" />}
                error={errors.price}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₦</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                disabled
                {...register("stock")}
                label={<FormattedMessage id="stock" defaultMessage="Stock" />}
                helperText="Stock is only updated through sales and purchases"
              />
            </Grid>

            {
              // <Grid item sm={12}>
              //   <FormLabel>Doses</FormLabel>
              //   <Paper className={s.chips}>
              //     {(api_strength || []).map((dose) => (
              //       <li key={dose}>
              //         <Chip
              //           icon={icon}
              //           label={dose}
              //           onDelete={() => {
              //             setValue(
              //               "api_strength",
              //               api_strength.filter((d) => d !== dose)
              //             );
              //           }}
              //           // className={s.chip}
              //         />
              //       </li>
              //     ))}
              //     <TextField
              //       onChange={(e) => {
              //         console.log(e);
              //       }}
              //       type="number"
              //       variant="outlined"
              //       InputProps={{
              //         endAdornment: (
              //           <InputAdornment position="end">mg</InputAdornment>
              //         ),
              //       }}
              //     />
              //   </Paper>
              // </Grid>
            }

            <Grid item sm={12} className={s.btns}>
              <Button
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => setEdit(null)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
