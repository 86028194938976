import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minHeight: "50px",
  },
  title: {
    flex: "1 1 100%",
    display: "inline-block",
    justifyContent: "space-between",

    "& h2": {
      width: "fit-content",
      float: "left",
      marginBottom: "0px",
    },
  },
  action: {
    float: "right",
  },
}));

const GridHeader = ({ title, getTableHeader }) => {
  const classes = useStyles();

  if (!title && !getTableHeader) return null;

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} color="inherit" component="div">
        {title && <h2>{title}</h2>}
        {getTableHeader && (
          <div className={classes.action}>{getTableHeader}</div>
        )}
      </Typography>
    </Toolbar>
  );
};

export default GridHeader;
