import React, { useState, useEffect, useMemo, forwardRef, useRef } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Typography } from "@material-ui/core";
import { NotificationManager } from "react-notifications";

import { appConfig } from "appConfigs";
import { useFetch } from "hooks";
import GridHeader from "./GridHeader";

const Grid = (
  {
    url,
    columns: gridColumns,
    title,
    getTableHeader,
    filters,
    summary,
    ...rest
  },
  ref
) => {
  const { loading, get, error } = useFetch(url);
  const [state, setState] = useState({
    data: [],
    metaData: {
      ...(rest.pagination === false
        ? {
            pageSize: 100,
            rowCount: rest.rows?.length || 100,
          }
        : {
            pageSize: appConfig.pageSize,
          }),
      currentPage: 0,
    },
  });
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (url) {
      getRecords();
    }
  }, [filters]);

  // useImperativeHandle(ref, () => ({
  //   refresh() {
  //     getRecords();
  //   },
  // }));

  const getRecords = async (pageNumber, pageSize) => {
    const query = {
      ...filters,
      page: pageNumber || 0,
      pageSize: pageSize || state.metaData?.pageSize || appConfig.pageSize,
    };
    const {
      data = [],
      metaData = {},
      summary = {},
    } = await get({
      query,
    })
      .then(({ data }) => data || {})
      .catch((err) => {
        NotificationManager.error(err.message);
        return {};
      });

    setState({
      data,
      metaData,
      summary,
    });
  };

  const columns = useMemo(
    () =>
      gridColumns?.map((column) => {
        if (column.field === "action") {
          column.minWidth = column.minWidth || 120;
        } else if (!column.minWidth && !column.width) {
          column.flex = column.flex || 1;
        }
        column.sortable = column.sortable || false;

        return column;
      }),
    [gridColumns]
  );

  const pageSizeRef = useRef(null);

  const dataGridProps = {
    loading,
    columns,
    rowCount: state.metaData?.totalRecords || 0,
    page: state.metaData?.currentPage || 0,
    pageSize: state.metaData?.pageSize || appConfig.pageSize,
    rows: state.data || [],
  };

  return (
    <>
      <GridHeader getTableHeader={getTableHeader} title={title} />
      {summary && state.summary && summary(state.summary)}
      <DataGrid
        rowsPerPageOptions={
          rest.pagination === false ? [100] : [10, 20, 30, 50, 100]
        }
        autoHeight
        disableSelectionOnClick
        disableColumnMenu
        paginationMode="server"
        {...dataGridProps}
        onPageChange={(page) => {
          getRecords(page, pageSizeRef.current || state.metaData?.pageSize);
        }}
        onPageSizeChange={(pageSize) => {
          if (state.metaData?.currentPage === 0) {
            getRecords(0, pageSize);
          } else {
            pageSizeRef.current = pageSize;
            setState((prev) => ({
              ...prev,
              metaData: { ...prev.metaData, pageSize, page: 0 },
            }));
          }
        }}
        ref={ref}
        {...(error && {
          NoRowsOverlay: () => (
            <Typography
              variant="subtitle1"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              {error}
            </Typography>
          ),
        })}
        {...(rest.checkboxSelection && {
          onSelectionModelChange: (newSelected) => {
            setSelected(newSelected);
            if (rest.selectedRef) {
              rest.selectedRef.current = newSelected;
            }
          },
          selectionModel: selected,
          keepNonExistentRowsSelected: true,
        })}
        {...rest}
      />
    </>
  );
};

export default forwardRef(Grid);
