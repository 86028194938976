import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

export default function Confirm({ open, close, title, message, onConfirm }) {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          Yes
        </Button>
        <Button variant="contained" onClick={() => close()}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
