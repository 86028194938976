import React, { useState } from "react";
import { Wrapper, Datagrid, Permission, ActionMenu } from "../../components";

import { Typography, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import Form from "./Form";
import View from "./View";
import Filters from "./Filters";
import { FormattedMessage } from "react-intl";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
}));

const Hospitals = () => {
  const [view, setView] = useState(null);
  const [filters, setFilters] = useState({});
  const classes = useStyles();
  const [edit, setEdit] = useState(null);
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage id="hospitals" defaultMessage="Hospitals" />
                </Typography>
                <Permission permission="add_hospital">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setEdit(true)}
                  >
                    <FormattedMessage
                      id="addHospital"
                      defaultMessage="Add Hospital"
                    />
                  </Button>
                </Permission>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters filters={filters} setFilters={setFilters} />
                  </CardContent>
                </Card>
              </Grid>
              <Datagrid
                url={endpoints.hospitals}
                filters={filters}
                columns={[
                  {
                    headerName: (
                      <FormattedMessage id="name" defaultMessage="Name" />
                    ),
                    field: "name",
                  },
                  {
                    headerName: (
                      <FormattedMessage id="code" defaultMessage="Code" />
                    ),
                    field: "code",
                  },
                  {
                    headerName: (
                      <FormattedMessage id="email" defaultMessage="Email" />
                    ),
                    field: "email",
                  },
                  {
                    headerName: (
                      <FormattedMessage id="phone" defaultMessage="Phone" />
                    ),
                    field: "phone",
                  },
                  {
                    headerName: (
                      <FormattedMessage id="status" defaultMessage="Status" />
                    ),
                    field: "status",
                  },
                  {
                    headerName: (
                      <FormattedMessage id="action" defaultMessage="Action" />
                    ),
                    field: "action",
                    renderCell: ({ row }) => (
                      <ActionMenu
                        menuItems={[
                          {
                            icon: <VisibilityIcon />,
                            label: (
                              <FormattedMessage
                                id="viewHospital"
                                defaultMessage="View Hospital"
                              />
                            ),
                            onClick: () => setView(row),
                          },
                          {
                            icon: <EditIcon />,
                            label: (
                              <FormattedMessage
                                id="editHospital"
                                defaultMessage="Edit Hospital"
                              />
                            ),
                            onClick: () => setEdit(row),
                            permission: "update_hospitals",
                          },
                        ]}
                      />
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Form
          open={!!edit}
          edit={edit}
          setEdit={setEdit}
          onSuccess={(newHospital) => {
            setFilters((prev) => ({ ...prev }));
            setEdit(null);
          }}
        />
        <View
          hospital={view}
          close={() => {
            setView(null);
          }}
        />
      </Wrapper>
    </>
  );
};

export default Hospitals;
