import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components";

import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import { useFetch } from "hooks";
import Form from "./Form";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
}));

const Users = () => {
  const classes = useStyles();
  const [rawSettings, setRawSettings] = useState();
  const [settings, setSettings] = useState({});
  const { get: getSettings, loading: loadingSettings } = useFetch(
    endpoints.allSettings
  );
  const getRecords = async (hospital_id) => {
    getSettings({ query: { hospital_id: hospital_id || null } }).then(
      ({ data }) => {
        if (data.success) {
          setRawSettings(data.data);
          setSettings(
            data.data.reduce((p, c) => {
              if ("value" in c) {
                if (c.key === "combobox") {
                  p.savings_withdrawal_payment_method = options.find(
                    (item) => item.value === c.value
                  );
                } else {
                  p[c.key] = c.value;
                }
              }
              return p;
            }, {})
          );
        }
      }
    );
  };

  useEffect(() => {
    getRecords();
  }, []);
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={5}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="generalSettings"
                    defaultValue="General Settings"
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Form
                  settings={settings}
                  rawSettings={rawSettings}
                  onSuccess={(hospital_id) => {
                    getRecords(hospital_id);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default Users;
