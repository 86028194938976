import { useState } from "react";
import { Wrapper, Datagrid, Permission } from "../../components";

import { Typography, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import format from "date-fns/format";
import Form from "./Form";
import Filters from "./Filters";
import { useAppState } from "components/AppProvider/AppProvider";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
}));

const payment = {
  payNow: "Bank Transfer",
  subscription: "Subscription",
};

const Users = () => {
  const { authUser } = useAppState();
  const classes = useStyles();
  const [edit, setEdit] = useState(null);
  const [filters, setFilters] = useState({});
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={11}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="appointments"
                    defaultMessage="Appointments"
                  />
                </Typography>
                {
                  // <Permission permission="add_appointment">
                  //   <Button
                  //     variant="contained"
                  //     color="primary"
                  //     startIcon={<AddIcon />}
                  //     onClick={() => setEdit(true)}
                  //   >
                  //     Add User
                  //   </Button>
                  // </Permission>
                }
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters
                      filters={filters}
                      setFilters={setFilters}
                      authUser={authUser}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Datagrid
                  url={endpoints.appointments}
                  filters={filters}
                  columns={[
                    {
                      headerName: (
                        <FormattedMessage
                          id="createdAt"
                          defaultMessage="Created At"
                        />
                      ),
                      field: "createdAt",
                      renderCell: ({ row }) =>
                        format(new Date(row.createdAt), "dd MMM yyyy hh:mma"),
                    },
                    {
                      headerName: (
                        <FormattedMessage id="client" defaultMessage="Client" />
                      ),
                      field: "client.name",
                      renderCell: ({ row }) => row.user?.name,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="doctor" defaultMessage="Doctor" />
                      ),
                      field: "doctor.name",
                      renderCell: ({ row }) => row.doctor?.name,
                    },
                    ...(authUser.user_type === "admin"
                      ? [
                          {
                            headerName: (
                              <FormattedMessage
                                id="hospital"
                                defaultMessage="Hospital"
                              />
                            ),
                            field: "hospital.name",
                            renderCell: ({ row }) => row.hospital?.name,
                          },
                        ]
                      : []),
                    {
                      headerName: (
                        <FormattedMessage
                          id="dateTime"
                          defaultMessage="Date & Time"
                        />
                      ),
                      field: "date_time",
                      renderCell: ({ row }) =>
                        format(new Date(row.date_time), "dd MMM yyyy hh:mma"),
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="interaction"
                          defaultMessage="Interaction"
                        />
                      ),
                      field: "interaction",
                      renderCell: ({ row }) => row.interaction.join(", "),
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="paymentMethod"
                          defaultMessage="Payment Method"
                        />
                      ),
                      field: "payment_method",
                      renderCell: ({ row }) => payment[row.payment_method],
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="paymentStatus"
                          defaultMessage="Payment Status"
                        />
                      ),
                      field: "payment_status",
                      renderCell: ({ row }) => row.payment_status || "NA",
                    },
                    {
                      headerName: (
                        <FormattedMessage id="status" defaultMessage="Status" />
                      ),
                      field: "status",
                    },
                    {
                      headerName: (
                        <FormattedMessage id="action" defaultMessage="Action" />
                      ),
                      field: "action",
                      renderCell: ({ row }) => (
                        <>
                          <Permission permission="update_appointments">
                            <IconButton
                              aria-label="Edit User"
                              onClick={() => setEdit(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Permission>
                          {/* <DeleteActionIcon to={`${props.pageUrl}/add`} /> */}
                        </>
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Form
          authUser={authUser}
          open={!!edit}
          edit={edit}
          setEdit={setEdit}
          onSuccess={() => {
            setFilters((prev) => ({ ...prev }));
            setEdit(false);
          }}
        />
      </Wrapper>
    </>
  );
};

export default Users;
