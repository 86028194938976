import { useRef, useState } from "react";

import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import PropTypes from "prop-types";
import SettingsIcon from "@material-ui/icons/Settings";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { useAppState } from "components/AppProvider/AppProvider";
import { Link } from "react-router-dom";
import { endpoints, paths } from "appConfigs";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import AddIcon from "@material-ui/icons/Add";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFetch, useYup } from "hooks";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "0 1px 8px rgba(0,0,0,.3)",
    position: "relative",
    zIndex: theme.zIndex.drawer + 100,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
  },
  toolBar: {
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
  },
  branding: {
    display: "flex",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    margin: "auto 0",
    lineHeight: "50px",
    padding: `0 64px 0 0`,
  },
  logo: {
    margin: "auto",
    height: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80px",
    },
  },
  langPicker: {
    color: "white",
  },
  currentBalance: {
    display: "flex",
    color: "white",
    borderRadius: "2rem",
    "& > span": {
      gridGap: ".25rem !important",
    },
  },
  link: {
    color: "#017ce0",
  },
}));

const LanguagePicker = () => {
  const classes = useStyles();
  const { state, setState } = useAppState();
  const [langs, setLangs] = useState([
    { label: "English", value: "en-US" },
    { label: "Bangla", value: "bn-BD" },
  ]);
  return (
    <FormControl variant="outlined">
      <Select
        className={classes.langPicker}
        value={state.locale || "en-US"}
        onChange={(op) => {
          setState((prev) => ({ ...prev, locale: op.target.value }));
        }}
      >
        {langs.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            className={classes.langOpt}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const Header = ({
  logo,
  logoAltText,
  toggleFullscreen,
  toggleDrawer,
  toogleNotifications,
}) => {
  const classes = useStyles();
  const { authUser, logoutUser } = useAppState();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSettingdToggle = (event) => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const handleDrawerToggle = () => {
    toggleDrawer();
  };

  const handleNotificationToggle = () => {
    toogleNotifications();
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>

        <div className={classes.branding}>
          <img src={logo} alt={logoAltText} className={classes.logo} />
        </div>

        <Hidden xsDown>
          <span className="flexSpacer" />
        </Hidden>

        {["hospital"].includes(authUser?.user_type) && <Balance />}

        <Hidden xsDown>
          <LanguagePicker />
        </Hidden>

        <Hidden xsDown>
          <IconButton color="inherit" onClick={toggleFullscreen}>
            <FullscreenIcon />
          </IconButton>
        </Hidden>

        {
          // <IconButton color="inherit" onClick={handleNotificationToggle}>
          //   <Badge badgeContent={5} color="secondary">
          //     <NotificationsIcon />
          //   </Badge>
          // </IconButton>
        }

        <IconButton
          aria-label="User Settings"
          aria-owns={anchorEl ? "user-menu" : null}
          aria-haspopup="true"
          color="inherit"
          onClick={handleSettingdToggle}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {
            // <MenuItem onClick={handleCloseMenu}>
            //   <ListItemIcon>
            //     <SettingsIcon />
            //   </ListItemIcon>
            //   <ListItemText primary="Settings" />
            // </MenuItem>
            // <MenuItem onClick={handleCloseMenu}>
            //   <ListItemIcon>
            //     <NotificationsOffIcon />
            //   </ListItemIcon>
            //   <ListItemText primary="Disable notifications" />
            // </MenuItem>
          }
          {
            //   <Link to={paths.profile}>
            //   <MenuItem onClick={handleCloseMenu}>
            //     <ListItemIcon>
            //       <AccountBoxIcon />
            //     </ListItemIcon>
            //     <ListItemText primary="Profile" />
            //   </MenuItem>
            // </Link>
          }
          <MenuItem onClick={logoutUser}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

const fundWalletSchema = yup.object({
  amount: yup
    .number()
    .min(1, "Can't be less than 1")
    .required("Field is required")
    .typeError("Please enter a valid number"),
});
const Balance = ({}) => {
  const s = useStyles();
  const timer = useRef();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: useYup(fundWalletSchema),
  });
  const [balance, setBalance] = useState(null);
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [fundWallet, setFundWallet] = useState(false);
  const { get: getBalance, loading } = useFetch(endpoints.walletBalance);
  const { post: fund, loading: fundingWallet } = useFetch(endpoints.fundWallet);
  return (
    <>
      <IconButton
        className={s.currentBalance}
        title={
          balance === null ? "Show current balance" : "Hide current balance"
        }
        onClick={() => {
          if (balance === null) {
            getBalance()
              .then(({ data }) => {
                if (!data.success) {
                  return NotificationManager.error(data.message);
                }
                setBalance(data.data.balance);
                timer.current = setTimeout(() => setBalance(null), 3000);
              })
              .catch((err) => NotificationManager.error(err.message));
          } else {
            clearTimeout(timer.current);
            setBalance(null);
          }
        }}
      >
        ₦
        {balance !== null && (
          <span className="balance">{balance.toLocaleString()}</span>
        )}
        {balance === null ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setFundWallet(true)}
      >
        Fund Wallet
      </Button>

      <Dialog open={fundWallet} fullWidth maxWidth="xs">
        <DialogTitle>Fund Wallet</DialogTitle>
        {checkoutUrl ? (
          <>
            <DialogContent>
              <Typography variant="body1">
                Please click{" "}
                <a className={s.link} href={checkoutUrl}>
                  Here
                </a>{" "}
                to proceed. Please complete the transaction within next 45
                minutes.
              </Typography>
            </DialogContent>
            <DialogActions></DialogActions>
          </>
        ) : (
          <form
            onSubmit={handleSubmit((values) => {
              fund({
                amount: values.amount,
                redirect_url: window.location.href,
              })
                .then(({ data }) => {
                  if (!data.success) {
                    return NotificationManager.error(data.message);
                  }
                  setCheckoutUrl(data.data.checkout_url);
                })
                .catch((err) => NotificationManager.error(err.message));
            })}
          >
            <DialogContent>
              <TextField
                type="number"
                fullWidth
                label="Amount"
                {...register("amount")}
                error={!!errors.amount}
                helperText={errors.amount?.message}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                type="button"
                disabled={fundingWallet || loading}
                onClick={() => setFundWallet(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={fundingWallet || loading}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

Header.prototypes = {
  logo: PropTypes.string,
  logoAltText: PropTypes.string,
};

export default Header;
