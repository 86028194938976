import { useEffect } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  FileInput,
  Wrapper,
  TextField,
  SelectField,
  Permission,
  PasswordField,
} from "components";
import { useAppState } from "components/AppProvider/AppProvider";
import { useForm } from "react-hook-form";
import { getUserLocation } from "helpers";
import { useFetch, useYup } from "hooks";
import { endpoints } from "appConfigs";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

const schema = yup.object({
  name: yup
    .string()
    .required(
      <FormattedMessage
        id="fieldIsRequired"
        defaultMessage="Field is required"
      />
    ),
  old_password: yup
    .string()
    .when("new_password", (newPassword) => {
      if (newPassword) {
        return yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          );
      }
    })
    .test("oldPassLen", "Password must be at least 8 characters", (v) => {
      return !v || v?.length >= 8;
    }),
  new_password: yup
    .string()
    .test("newPassLen", "Password must be at least 8 characters", (v) => {
      return !v || v?.length >= 8;
    }),
  old_pin: yup
    .string()
    .when("new_pin", (newPassword) => {
      if (newPassword) {
        return yup
          .string()
          .required(
            <FormattedMessage
              id="fieldIsRequired"
              defaultMessage="Field is required"
            />
          );
      }
    })
    .test("oldPinLen", "Pin must be 4 digits", (v) => {
      return !v || v?.match(/^\d{4}$/);
    }),
  new_pin: yup.string().test("newPinLen", "Pin must be 4 digits", (v) => {
    return !v || v?.match(/^\d{4}$/);
  }),
  two_factor_auth: yup
    .boolean()
    .required()
    .typeError("Please select an option"),
});

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: "45rem",
  },
}));

const Profile = () => {
  const classes = useStyles();
  const { authUser, setAuthUser } = useAppState();
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: useYup(schema),
  });
  const { put: updateProfile, loading } = useFetch(
    endpoints[
      authUser.user_type === "ref_hospital" ? "refHospital" : authUser.user_type
    ]?.profile
  );
  useEffect(() => {
    reset({
      photo: authUser.photo ? [authUser.photo] : [],
      name: authUser.name,
      email: authUser.email,
      phone: authUser.phone,
      two_factor_auth: (authUser.two_factor_auth || false).toString(),
    });
  }, []);
  return (
    <Wrapper>
      <Grid container spacing={0} justify="center">
        <Card className={classes.wrapper}>
          <CardContent>
            <form
              onSubmit={handleSubmit(async (values) => {
                if (!values.new_pin) {
                  delete values.old_pin;
                  delete values.new_pin;
                }
                if (!values.new_password) {
                  delete values.old_password;
                  delete values.new_password;
                }
                const { position, error } = await getUserLocation();
                if (error) {
                  return NotificationManager.error(error);
                }
                const formData = new FormData();
                Object.entries({
                  ...values,
                  latitude: position?.latitude,
                  longitude: position?.longitude,
                }).forEach(([key, value]) => {
                  if (key === "photo") {
                    if (value?.length) {
                      formData.append(key, value[0]);
                    } else {
                      formData.append(key, "");
                    }
                  } else if (Array.isArray(value)) {
                    formData.append(key, JSON.stringify(value));
                  } else {
                    formData.append(key, value);
                  }
                });

                updateProfile(formData)
                  .then(({ data }) => {
                    if (data.success) {
                      NotificationManager.success(data.message);
                      setAuthUser((prev) => ({ ...prev, ...data.data }));
                      reset({
                        old_password: "",
                        new_password: "",
                        old_pin: "",
                        new_pin: "",
                        two_factor_auth: (
                          values.two_factor_auth || false
                        ).toString(),
                      });
                    } else {
                      NotificationManager.error(data.message);
                    }
                  })
                  .catch((err) => {
                    NotificationManager.error(err.message);
                  });
              })}
            >
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <Typography variant="h5">
                    <FormattedMessage id="profile" defaultMessage="Profile" />
                  </Typography>
                </Grid>
                <Grid item sm={12}>
                  <FileInput
                    label={
                      <FormattedMessage id="photo" defaultMessage="Photo" />
                    }
                    control={control}
                    name="photo"
                    thumbnail
                  />
                </Grid>

                <Grid item sm={12}>
                  <TextField
                    label={<FormattedMessage id="name" defaultMessage="Name" />}
                    fullWidth
                    {...register("name")}
                    error={errors.name}
                  />
                </Grid>

                <Grid item sm={12}>
                  <TextField
                    label={
                      <FormattedMessage id="phone" defaultMessage="Phone" />
                    }
                    fullWidth
                    {...register("phone")}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item sm={12}>
                  <TextField
                    label={
                      <FormattedMessage id="email" defaultMessage="Email" />
                    }
                    fullWidth
                    {...register("email")}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Typography variant="h5">Security</Typography>
                </Grid>

                <Grid item sm={12}>
                  <PasswordField
                    label={
                      <FormattedMessage
                        id="oldPassword"
                        defaultMessage="Old Password"
                      />
                    }
                    fullWidth
                    {...register("old_password")}
                    error={errors.old_password}
                  />
                </Grid>

                <Grid item sm={12}>
                  <PasswordField
                    label={
                      <FormattedMessage
                        id="newPassword"
                        defaultMessage="New Password"
                      />
                    }
                    fullWidth
                    {...register("new_password")}
                    error={errors.new_password}
                  />
                </Grid>

                <Grid item sm={12}>
                  <PasswordField
                    label={
                      <FormattedMessage id="oldPin" defaultMessage="Old Pin" />
                    }
                    fullWidth
                    {...register("old_pin")}
                    error={errors.old_pin}
                  />
                </Grid>

                <Grid item sm={12}>
                  <PasswordField
                    label={
                      <FormattedMessage id="newPin" defaultMessage="New Pin" />
                    }
                    fullWidth
                    {...register("new_pin")}
                    error={errors.new_pin}
                  />
                </Grid>

                <Grid item sm={12}>
                  <SelectField
                    label={
                      <FormattedMessage
                        id="twoFactorAuth"
                        defaultMessage="Two Factor Authentication"
                      />
                    }
                    name="two_factor_auth"
                    control={control}
                    options={[
                      { label: "Enabled", value: "true" },
                      { label: "Disabled", value: "false" },
                    ]}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Permission permission="update_profile">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      <FormattedMessage
                        id="saveChanges"
                        defaultMessage="Save Changes"
                      />
                    </Button>
                  </Permission>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Wrapper>
  );
};

export default Profile;
