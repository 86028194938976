import { forwardRef, useState } from "react";

import {
  TextField as MTextField,
  InputAdornment,
  IconButton,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { VisibilityOff, Visibility } from "@material-ui/icons";

export const TextField = forwardRef(({ error, label, ...rest }, ref) => {
  return (
    <FormControl component="fieldset" fullWidth>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <MTextField
        ref={ref}
        error={!!error}
        helperText={error?.message}
        {...(["date", "datetime-local"].includes(rest.type) && {
          InputLabelProps: { shrink: true },
        })}
        {...rest}
      />
    </FormControl>
  );
});

export const PasswordField = forwardRef(({ error, label, ...rest }, ref) => {
  const [type, setType] = useState("password");
  return (
    <FormControl fullWidth>
      {label && <FormLabel>{label}</FormLabel>}
      <MTextField
        type={type}
        ref={ref}
        error={!!error}
        helperText={error?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() =>
                  setType(type === "password" ? "text" : "password")
                }
              >
                {type === "password" ? (
                  <Visibility style={{ color: "rgb(125, 125, 125)" }} />
                ) : (
                  <VisibilityOff style={{ color: "rgb(125, 125, 125)" }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </FormControl>
  );
});

export { default as SelectField } from "./SelectField";
export { default as FileInput } from "./FileInput";
export { default as Checkbox } from "./Checkbox";
export { default as Radio } from "./Radio";
export { default as Combobox } from "./Combobox";
