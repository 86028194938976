import { useState } from "react";
import { Wrapper, Datagrid } from "components";

import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { endpoints } from "appConfigs";
import format from "date-fns/format";
import Filters from "./Filters";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "1rem 0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    margin: "0 auto",
  },
  label: {
    color: "#ffffff",
  },
  status: {
    textTransform: "capitalize",
  },
}));

const Subscriptions = () => {
  const classes = useStyles();
  const [edit, setEdit] = useState(null);
  const [filters, setFilters] = useState({});
  return (
    <>
      <Wrapper padding={false}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={10}>
            <Grid container spacing={1} direction="column" justify="center">
              <Grid item className={classes.titleBar}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="subscriptions"
                    defaultMessage="Subscriptions"
                  />
                </Typography>
                {
                  //   <Button
                  //   variant="contained"
                  //   color="primary"
                  //   startIcon={<AddIcon />}
                  //   onClick={() => setEdit(true)}
                  // >
                  //   Add Subscription
                  // </Button>
                }
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Filters filters={filters} setFilters={setFilters} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Datagrid
                  url={endpoints.subscriptions}
                  filters={filters}
                  columns={[
                    {
                      headerName: (
                        <FormattedMessage
                          id="package"
                          defaultMessage="Package"
                        />
                      ),
                      field: "sub_package.name",
                      renderCell: ({ row }) => row.sub_package?.name,
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="billingAccount"
                          defaultMessage="Billing Account"
                        />
                      ),
                      field: "billing_account.name",
                      renderCell: ({ row }) => row.billing_account?.name,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="price" defaultMessage="Price" />
                      ),
                      field: "sub_package.price",
                      renderCell: ({ row }) => row.sub_package?.price,
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="members"
                          defaultMessage="Members"
                        />
                      ),
                      field: "users",
                      renderCell: ({ row }) => row.users?.length,
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="remainingAppointments"
                          defaultMessage="Remaining Appointments"
                        />
                      ),
                      field: "remaining_appointments",
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="lastPaid"
                          defaultMessage="Last Paid"
                        />
                      ),
                      field: "last_paid",
                      renderCell: ({ row }) =>
                        format(new Date(row.last_paid), "dd MMM yyyy"),
                    },
                    {
                      headerName: (
                        <FormattedMessage
                          id="dueDate"
                          defaultMessage="Due Date"
                        />
                      ),
                      field: "due_date",
                      renderCell: ({ row }) =>
                        format(new Date(row.due_date), "dd MMM yyyy"),
                    },
                    // {
                    //   headerName: "Departments",
                    //   field: "departments",
                    //   renderCell: ({ row }) =>
                    //     row.departments?.map((dept) => dept.name).join(", ") ||
                    //     "NA",
                    // },
                    {
                      headerName: (
                        <FormattedMessage
                          id="paymentStatus"
                          defaultMessage="Payment Status"
                        />
                      ),
                      field: "payment_status",
                      cellClassName: classes.status,
                    },
                    {
                      headerName: (
                        <FormattedMessage id="status" defaultMessage="Status" />
                      ),
                      field: "status",
                      cellClassName: classes.status,
                    },
                    // {
                    //   headerName: "Action",
                    //   field: "action",
                    //   renderCell: ({ row }) => (
                    //     <>
                    //       <IconButton
                    //         aria-label="Edit Subscription"
                    //         onClick={() => setEdit(row)}
                    //       >
                    //         <EditIcon />
                    //       </IconButton>
                    //       {/* <DeleteActionIcon to={`${props.pageUrl}/add`} /> */}
                    //     </>
                    //   ),
                    // },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default Subscriptions;
