import React from "react";

import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Controller } from "react-hook-form";

const SelectField = ({ label, control, name }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <FormControlLabel
          control={<Checkbox checked={value} onChange={onChange} />}
          label={label}
        />
      )}
    />
  );
};

export default SelectField;
