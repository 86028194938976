import React from "react";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Controller } from "react-hook-form";

const SelectField = ({
  label,
  multiple,
  options,
  control,
  name,
  rules,
  readOnly,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, value = multiple ? [] : "", ref },
        fieldState: { error },
      }) => {
        return (
          <FormControl fullWidth>
            <InputLabel id={name}>{label}</InputLabel>
            <Select
              fullWidth
              labelId={name}
              id={name}
              value={value}
              onChange={onChange}
            >
              {options.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

export default SelectField;
